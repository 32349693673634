import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Container, Text, Button } from "../../../../../styled";
import Timer from "./Timer";
import { useDispatch, useSelector } from "react-redux";
import { ExamContextConsumer } from "../../context";
import Logo from "../../../../../assets/images/logo.jpeg";
import { getExam } from "../../../../../redux/actions/exam";

const styles = {
  paper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "70px",
  },
};

const Header = () => {
  const { currentExam } = useSelector((state) => state.exam);
  const { exitExam } = ExamContextConsumer();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const exam_id = queryParams.get("exam_id");

    const fetchExamData = async () => {
      if (exam_id) {
        await dispatch(getExam(exam_id)); // Update the Redux store with the latest data
      }
    };

    // Set up the interval to fetch the data every 20 seconds
    const intervalId = setInterval(() => {
      fetchExamData();
    }, 3 * 60 * 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <Paper elevation={0} style={styles.paper}>
      <Container
        align="center"
        justify="center"
        flex={0.1}
        style={{ gap: "16px", paddingLeft: "16px" }}
      >
        <img height="30px" src={Logo} alt="YorkIE" />
      </Container>
      <Container align="center" flex={0.3} style={{ marginLeft: "5px" }}>
      <Text size="20px" lineHeight="0px" weight="bold" color="#47926E">
          {currentExam?.examtitle}
        </Text>
      </Container>
      <Container align="center" flex={0.4} style={{ marginLeft: "16px" }}>
        <Text lineHeight="15px">{currentExam?.examinfo}</Text>
      </Container>
      <Container align="center" justify="center" flex={0.1} padding="20px 0px">
        <Timer
          start={currentExam?.startingtime}
          end={currentExam?.endingtime}
        />
      </Container>
      <Container align="center" flex={0.2} justify="center">
        <Text lineHeight="0px">
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "200px",
            }}
          >
            <Button
              background="#47926E"
              height="40px"
              size="15px"
              weight="bold"
              onClick={() => {
                exitExam("Submitted");
              }}
            >
              Submit & Exit
            </Button>
          </div>
        </Text>
      </Container>
    </Paper>
  );
};

export default Header;