import React from "react";
// import { primaryColor } from "theme";

function Background() {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          opacity: 0.3,
          left: 0,
          zIndex: -10,
          transform: "scale(1.5)",
        }}
      >
        {/* <div className="fixed top-0 left-0 z-0 scale-150 opacity-25"> */}
        <svg
          width="1920"
          height="1080"
          viewBox="0 0 1920 1080"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            {" "}
            <path
              d="M381.921 862.762V817.09L362.541 805.898V782.403L403.061 759.014L442.609 781.85L461.989 770.659L482.334 782.41V829.203L442.779 852.039V874.421L422.434 886.165L381.921 862.762ZM402.621 874.307L422.434 885.747L442.247 874.307L422.434 862.868L402.621 874.307ZM382.453 862.662L402.266 874.102L422.079 862.662L402.266 851.223L382.453 862.662ZM422.618 862.556L442.431 873.995V852.024L422.618 840.585V862.556ZM382.276 817.09V862.357L402.089 850.918V805.65L382.276 817.09ZM402.444 850.911L422.257 862.35V817.09L402.444 805.65V850.911ZM422.796 840.28L442.609 851.719L481.802 829.089L461.989 817.65L422.796 840.28ZM422.618 817.09V839.968L461.812 817.338V794.459L422.618 817.09ZM462.166 817.338L481.98 828.777V805.898L462.166 794.459V817.338ZM383.241 794.147L422.434 816.777L442.247 805.338L403.054 782.708L383.241 794.147ZM363.073 805.792L382.099 816.777L401.912 805.338L382.886 794.353L363.073 805.792ZM362.896 782.609V805.487L382.709 794.048V771.169L362.896 782.609ZM462.166 794.048L481.98 805.487V782.609L462.166 771.169V794.048ZM442.786 782.148V805.026L461.812 794.041V771.162L442.786 782.148ZM403.231 782.396L442.431 805.026V782.148L403.231 759.517V782.396ZM383.063 770.957V793.835L402.876 782.396V759.517L383.063 770.957Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M166.679 941.81V896.131L147.299 884.939V861.444L187.818 838.055L227.366 860.891L246.747 849.7L267.092 861.451V908.244L227.537 931.08V953.462L207.192 965.206L166.679 941.81ZM187.379 953.355L207.192 964.795L227.005 953.355L207.192 941.916L187.379 953.355ZM167.211 941.71L187.024 953.15L206.837 941.71L187.024 930.271L167.211 941.71ZM207.369 941.604L227.182 953.043V931.072L207.369 919.633V941.604ZM167.034 896.138V941.405L186.847 929.966V884.705L167.034 896.138ZM187.201 929.959L207.014 941.398V896.138L187.201 884.698V929.959ZM207.546 919.321L227.359 930.76L266.553 908.13L246.74 896.691L207.546 919.321ZM207.369 896.138V919.016L246.563 896.386V873.507L207.369 896.138ZM246.924 896.386L266.737 907.825V884.947L246.924 873.507V896.386ZM167.998 873.195L207.192 895.826L227.005 884.386L187.811 861.756L167.998 873.195ZM147.831 884.84L166.857 895.826L186.67 884.386L167.644 873.401L147.831 884.84ZM147.646 861.65V884.528L167.459 873.089V850.21L147.646 861.65ZM246.924 873.096L266.737 884.535V861.657L246.924 850.217V873.096ZM227.537 861.196V884.074L246.563 873.089V850.21L227.537 861.196ZM187.989 861.444L227.189 884.074V861.196L187.989 838.565V861.444ZM167.814 850.005V872.883L187.627 861.444V838.565L167.814 850.005Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M254.08 1014.74V969.056L234.699 957.865V934.37L275.219 910.98L314.767 933.817L334.147 922.625L354.492 934.37V981.162L314.937 1004V1026.38L294.592 1038.12L254.08 1014.74ZM274.786 1026.28L294.599 1037.72L314.412 1026.28L294.599 1014.84L274.786 1026.28ZM254.619 1014.64L274.432 1026.08L294.245 1014.64L274.432 1003.2L254.619 1014.64ZM294.777 1014.53L314.59 1025.97V1004L294.777 992.559V1014.53ZM254.434 969.063V1014.32L274.247 1002.88V957.617L254.434 969.063ZM274.609 1002.88L294.422 1014.32V969.063L274.609 957.624V1002.88ZM294.954 992.254L314.767 1003.69L353.96 981.063L334.147 969.624L294.954 992.254ZM294.777 969.063V991.942L333.97 969.312V946.433L294.777 969.063ZM334.332 969.312L354.145 980.751V957.872L334.332 946.433V969.312ZM255.406 946.121L294.599 968.751L314.412 957.312L275.219 934.682L255.406 946.121ZM235.231 957.766L254.257 968.751L274.07 957.312L255.044 946.327L235.231 957.766ZM235.054 934.575V957.454L254.867 946.015V923.136L235.054 934.575ZM334.332 946.022L354.145 957.461V934.582L334.332 923.143V946.022ZM314.944 934.121V957L333.97 946.015V923.136L314.944 934.121ZM275.396 934.37L314.597 957V934.121L275.396 911.491V934.37ZM255.221 922.93V945.809L275.034 934.37V911.491L255.221 922.93Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M127.069 1165.87V1120.19L107.688 1109V1085.5L148.208 1062.11L187.756 1084.95L207.137 1073.76L227.482 1085.5V1132.29L187.926 1155.13V1177.51L167.581 1189.26L127.069 1165.87ZM147.775 1177.41L167.589 1188.85L187.402 1177.41L167.589 1165.97L147.775 1177.41ZM127.608 1165.77L147.421 1177.21L167.234 1165.77L147.421 1154.33L127.608 1165.77ZM167.766 1165.66L187.579 1177.1V1155.13L167.766 1143.69V1165.66ZM127.423 1120.2V1165.46L147.237 1154.02V1108.75L127.423 1120.2ZM147.598 1154.02L167.411 1165.46V1120.2L147.598 1108.76V1154.02ZM167.943 1143.39L187.756 1154.83L226.95 1132.19L207.137 1120.76L167.943 1143.39ZM167.766 1120.2V1143.07L206.959 1120.44V1097.56L167.766 1120.2ZM207.321 1120.44L227.134 1131.88V1109L207.321 1097.56V1120.44ZM128.395 1097.25L167.589 1119.88L187.402 1108.44L148.208 1085.81L128.395 1097.25ZM108.22 1108.9L127.246 1119.88L147.059 1108.44L128.033 1097.46L108.22 1108.9ZM108.043 1085.71V1108.59L127.856 1097.15V1074.27L108.043 1085.71ZM207.321 1097.15L227.134 1108.59V1085.71L207.321 1074.27V1097.15ZM187.933 1085.25V1108.13L206.959 1097.15V1074.27L187.933 1085.25ZM148.385 1085.5L187.586 1108.13V1085.25L148.385 1062.62V1085.5ZM128.211 1074.06V1096.94L148.024 1085.5V1062.62L128.211 1074.06Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M106.703 1281.87V1236.19L87.3223 1225V1201.5L127.842 1178.11L167.39 1200.95L186.77 1189.76L207.115 1201.5V1248.29L167.56 1271.13V1293.51L147.215 1305.26L106.703 1281.87ZM127.409 1293.41L147.222 1304.85L167.035 1293.41L147.222 1281.97L127.409 1293.41ZM107.242 1281.77L127.055 1293.21L146.868 1281.77L127.055 1270.33L107.242 1281.77ZM147.4 1281.66L167.213 1293.1V1271.13L147.4 1259.69V1281.66ZM107.057 1236.2V1281.46L126.87 1270.02V1224.75L107.057 1236.2ZM127.232 1270.02L147.045 1281.46V1236.2L127.232 1224.76V1270.02ZM147.577 1259.39L167.39 1270.83L206.583 1248.19L186.77 1236.76L147.577 1259.39ZM147.4 1236.2V1259.07L186.593 1236.44V1213.56L147.4 1236.2ZM186.955 1236.44L206.768 1247.88V1225L186.955 1213.56V1236.44ZM108.029 1213.25L147.222 1235.88L167.035 1224.44L127.842 1201.81L108.029 1213.25ZM87.8541 1224.9L106.88 1235.88L126.693 1224.44L107.667 1213.46L87.8541 1224.9ZM87.6768 1201.71V1224.59L107.49 1213.15V1190.27L87.6768 1201.71ZM186.955 1213.15L206.768 1224.59V1201.71L186.955 1190.27V1213.15ZM167.567 1201.25V1224.13L186.593 1213.15V1190.27L167.567 1201.25ZM128.019 1201.5L167.22 1224.13V1201.25L128.019 1178.62V1201.5ZM107.844 1190.06V1212.94L127.657 1201.5V1178.62L107.844 1190.06Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M429.703 1165.75V1120.08L410.322 1108.88V1085.39L450.842 1062L490.39 1084.84L509.77 1073.64L530.115 1085.39V1132.18L490.56 1155.02V1177.4L470.215 1189.14L429.703 1165.75ZM450.409 1177.3L470.222 1188.74L490.035 1177.3L470.222 1165.86L450.409 1177.3ZM430.242 1165.66L450.055 1177.09L469.868 1165.66L450.055 1154.22L430.242 1165.66ZM470.4 1165.55L490.213 1176.99V1155.02L470.4 1143.58V1165.55ZM430.057 1120.08V1165.34L449.87 1153.9V1108.64L430.057 1120.08ZM450.232 1153.9L470.045 1165.34V1120.08L450.232 1108.64V1153.9ZM470.577 1143.27L490.39 1154.71L529.583 1132.08L509.77 1120.64L470.577 1143.27ZM470.4 1120.08V1142.96L509.593 1120.33V1097.45L470.4 1120.08ZM509.955 1120.33L529.768 1131.77V1108.89L509.955 1097.45V1120.33ZM431.029 1097.14L470.222 1119.77L490.035 1108.33L450.842 1085.7L431.029 1097.14ZM410.854 1108.79L429.88 1119.77L449.693 1108.33L430.667 1097.35L410.854 1108.79ZM410.677 1085.59V1108.47L430.49 1097.03V1074.16L410.677 1085.59ZM509.955 1097.04L529.768 1108.48V1085.6L509.955 1074.16V1097.04ZM490.567 1085.14V1108.02L509.593 1097.03V1074.16L490.567 1085.14ZM451.019 1085.39L490.22 1108.02V1085.14L451.019 1062.51V1085.39ZM430.844 1073.95V1096.83L450.657 1085.39V1062.51L430.844 1073.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M409.337 1281.75V1236.08L389.956 1224.88V1201.39L430.476 1178L470.024 1200.84L489.404 1189.64L509.749 1201.39V1248.18L470.194 1271.02V1293.4L449.849 1305.14L409.337 1281.75ZM430.043 1293.3L449.856 1304.74L469.669 1293.3L449.856 1281.86L430.043 1293.3ZM409.875 1281.66L429.688 1293.09L449.502 1281.66L429.688 1270.22L409.875 1281.66ZM450.033 1281.55L469.846 1292.99V1271.02L450.033 1259.58V1281.55ZM409.691 1236.08V1281.34L429.504 1269.9V1224.64L409.691 1236.08ZM429.866 1269.9L449.679 1281.34V1236.08L429.866 1224.64V1269.9ZM450.211 1259.27L470.024 1270.71L509.217 1248.08L489.404 1236.64L450.211 1259.27ZM450.033 1236.08V1258.96L489.227 1236.33V1213.45L450.033 1236.08ZM489.589 1236.33L509.402 1247.77V1224.89L489.589 1213.45V1236.33ZM410.663 1213.14L449.856 1235.77L469.669 1224.33L430.476 1201.7L410.663 1213.14ZM390.488 1224.79L409.514 1235.77L429.327 1224.33L410.301 1213.35L390.488 1224.79ZM390.311 1201.59V1224.47L410.124 1213.03V1190.16L390.311 1201.59ZM489.589 1213.04L509.402 1224.48V1201.6L489.589 1190.16V1213.04ZM470.201 1201.14V1224.02L489.227 1213.03V1190.16L470.201 1201.14ZM430.653 1201.39L469.854 1224.02V1201.14L430.653 1178.51V1201.39ZM410.478 1189.95V1212.83L430.291 1201.39V1178.51L410.478 1189.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M729.703 1156.75V1111.08L710.322 1099.88V1076.39L750.842 1053L790.39 1075.84L809.77 1064.64L830.115 1076.39V1123.18L790.56 1146.02V1168.4L770.215 1180.14L729.703 1156.75ZM750.409 1168.3L770.222 1179.74L790.035 1168.3L770.222 1156.86L750.409 1168.3ZM730.242 1156.66L750.055 1168.09L769.868 1156.66L750.055 1145.22L730.242 1156.66ZM770.4 1156.55L790.213 1167.99V1146.02L770.4 1134.58V1156.55ZM730.057 1111.08V1156.34L749.87 1144.9V1099.64L730.057 1111.08ZM750.232 1144.9L770.045 1156.34V1111.08L750.232 1099.64V1144.9ZM770.577 1134.27L790.39 1145.71L829.583 1123.08L809.77 1111.64L770.577 1134.27ZM770.4 1111.08V1133.96L809.593 1111.33V1088.45L770.4 1111.08ZM809.955 1111.33L829.768 1122.77V1099.89L809.955 1088.45V1111.33ZM731.029 1088.14L770.222 1110.77L790.035 1099.33L750.842 1076.7L731.029 1088.14ZM710.854 1099.79L729.88 1110.77L749.693 1099.33L730.667 1088.35L710.854 1099.79ZM710.677 1076.59V1099.47L730.49 1088.03V1065.16L710.677 1076.59ZM809.955 1088.04L829.768 1099.48V1076.6L809.955 1065.16V1088.04ZM790.567 1076.14V1099.02L809.593 1088.03V1065.16L790.567 1076.14ZM751.019 1076.39L790.22 1099.02V1076.14L751.019 1053.51V1076.39ZM730.844 1064.95V1087.83L750.657 1076.39V1053.51L730.844 1064.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M709.337 1272.75V1227.08L689.956 1215.88V1192.39L730.476 1169L770.024 1191.84L789.404 1180.64L809.749 1192.39V1239.18L770.194 1262.02V1284.4L749.849 1296.14L709.337 1272.75ZM730.043 1284.3L749.856 1295.74L769.669 1284.3L749.856 1272.86L730.043 1284.3ZM709.875 1272.66L729.688 1284.09L749.502 1272.66L729.688 1261.22L709.875 1272.66ZM750.033 1272.55L769.846 1283.99V1262.02L750.033 1250.58V1272.55ZM709.691 1227.08V1272.34L729.504 1260.9V1215.64L709.691 1227.08ZM729.866 1260.9L749.679 1272.34V1227.08L729.866 1215.64V1260.9ZM750.211 1250.27L770.024 1261.71L809.217 1239.08L789.404 1227.64L750.211 1250.27ZM750.033 1227.08V1249.96L789.227 1227.33V1204.45L750.033 1227.08ZM789.589 1227.33L809.402 1238.77V1215.89L789.589 1204.45V1227.33ZM710.663 1204.14L749.856 1226.77L769.669 1215.33L730.476 1192.7L710.663 1204.14ZM690.488 1215.79L709.514 1226.77L729.327 1215.33L710.301 1204.35L690.488 1215.79ZM690.311 1192.59V1215.47L710.124 1204.03V1181.16L690.311 1192.59ZM789.589 1204.04L809.402 1215.48V1192.6L789.589 1181.16V1204.04ZM770.201 1192.14V1215.02L789.227 1204.03V1181.16L770.201 1192.14ZM730.653 1192.39L769.854 1215.02V1192.14L730.653 1169.51V1192.39ZM710.478 1180.95V1203.83L730.291 1192.39V1169.51L710.478 1180.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M907.014 1303.75V1258.08L887.634 1246.88V1223.39L928.153 1200L967.701 1222.84L987.082 1211.64L1007.43 1223.39V1270.18L967.872 1293.02V1315.4L947.527 1327.14L907.014 1303.75ZM927.721 1315.3L947.534 1326.74L967.347 1315.3L947.534 1303.86L927.721 1315.3ZM907.553 1303.66L927.366 1315.09L947.179 1303.66L927.366 1292.22L907.553 1303.66ZM947.711 1303.55L967.524 1314.99V1293.02L947.711 1281.58V1303.55ZM907.369 1258.08V1303.34L927.182 1291.9V1246.64L907.369 1258.08ZM927.544 1291.9L947.357 1303.34V1258.08L927.544 1246.64V1291.9ZM947.888 1281.27L967.701 1292.71L1006.89 1270.08L987.082 1258.64L947.888 1281.27ZM947.711 1258.08V1280.96L986.905 1258.33V1235.45L947.711 1258.08ZM987.266 1258.33L1007.08 1269.77V1246.89L987.266 1235.45V1258.33ZM908.34 1235.14L947.534 1257.77L967.347 1246.33L928.153 1223.7L908.34 1235.14ZM888.166 1246.79L907.192 1257.77L927.005 1246.33L907.979 1235.35L888.166 1246.79ZM887.988 1223.59V1246.47L907.801 1235.03V1212.16L887.988 1223.59ZM987.266 1235.04L1007.08 1246.48V1223.6L987.266 1212.16V1235.04ZM967.879 1223.14V1246.02L986.905 1235.03V1212.16L967.879 1223.14ZM928.331 1223.39L967.531 1246.02V1223.14L928.331 1200.51V1223.39ZM908.156 1211.95V1234.83L927.969 1223.39V1200.51L908.156 1211.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1105.01 1339.75V1294.08L1085.63 1282.88V1259.39L1126.15 1236L1165.7 1258.84L1185.08 1247.64L1205.43 1259.39V1306.18L1165.87 1329.02V1351.4L1145.53 1363.14L1105.01 1339.75ZM1125.72 1351.3L1145.53 1362.74L1165.35 1351.3L1145.53 1339.86L1125.72 1351.3ZM1105.55 1339.66L1125.37 1351.09L1145.18 1339.66L1125.37 1328.22L1105.55 1339.66ZM1145.71 1339.55L1165.52 1350.99V1329.02L1145.71 1317.58V1339.55ZM1105.37 1294.08V1339.34L1125.18 1327.9V1282.64L1105.37 1294.08ZM1125.54 1327.9L1145.36 1339.34V1294.08L1125.54 1282.64V1327.9ZM1145.89 1317.27L1165.7 1328.71L1204.89 1306.08L1185.08 1294.64L1145.89 1317.27ZM1145.71 1294.08V1316.96L1184.9 1294.33V1271.45L1145.71 1294.08ZM1185.27 1294.33L1205.08 1305.77V1282.89L1185.27 1271.45V1294.33ZM1106.34 1271.14L1145.53 1293.77L1165.35 1282.33L1126.15 1259.7L1106.34 1271.14ZM1086.17 1282.79L1105.19 1293.77L1125 1282.33L1105.98 1271.35L1086.17 1282.79ZM1085.99 1259.59V1282.47L1105.8 1271.03V1248.16L1085.99 1259.59ZM1185.27 1271.04L1205.08 1282.48V1259.6L1185.27 1248.16V1271.04ZM1165.88 1259.14V1282.02L1184.9 1271.03V1248.16L1165.88 1259.14ZM1126.33 1259.39L1165.53 1282.02V1259.14L1126.33 1236.51V1259.39ZM1106.16 1247.95V1270.83L1125.97 1259.39V1236.51L1106.16 1247.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M361.845 975.623V929.944L342.465 918.753V895.257L382.984 871.868L422.54 894.704L441.92 883.513L462.265 895.264V942.057L422.71 964.893V987.275L402.365 999.019L361.845 975.623ZM382.545 987.162L402.358 998.601L422.171 987.162L402.358 975.722L382.545 987.162ZM362.377 975.517L382.19 986.956L402.003 975.517L382.19 964.078L362.377 975.517ZM402.535 975.418L422.348 986.857V964.886L402.535 953.447V975.418ZM362.2 929.944V975.212L382.013 963.773V918.505L362.2 929.944ZM382.367 963.765L402.181 975.205V929.944L382.367 918.505V963.765ZM402.712 953.135L422.525 964.574L461.719 941.944L441.906 930.504L402.712 953.135ZM402.535 929.944V952.823L441.729 930.192V907.314L402.535 929.944ZM442.09 930.192L461.903 941.632V918.753L442.09 907.314V930.192ZM342.997 918.654L362.023 929.639L381.836 918.2L362.81 907.214L342.997 918.654ZM363.164 907.009L402.358 929.639L422.171 918.2L382.977 895.569L363.164 907.009ZM342.819 895.463V918.342L362.632 906.902V884.024L342.819 895.463ZM442.09 906.902L461.903 918.342V895.463L442.09 884.024V906.902ZM422.71 895.009V917.888L441.736 906.902V884.024L422.71 895.009ZM383.155 895.257L422.355 917.888V895.009L383.155 872.379V895.257ZM362.987 883.818V906.697L382.8 895.257V872.379L362.987 883.818Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M234.835 1126.75V1081.08L215.454 1069.88V1046.39L255.974 1023L295.529 1045.84L314.909 1034.64L335.254 1046.4V1093.19L295.699 1116.02V1138.41L275.354 1150.15L234.835 1126.75ZM255.534 1138.29L275.347 1149.73L295.16 1138.29L275.347 1126.85L255.534 1138.29ZM235.366 1126.65L255.179 1138.09L274.993 1126.65L255.179 1115.21L235.366 1126.65ZM275.524 1126.55L295.337 1137.99V1116.02L275.524 1104.58V1126.55ZM235.189 1081.08V1126.34L255.002 1114.9V1069.64L235.189 1081.08ZM255.357 1114.9L275.17 1126.34V1081.08L255.357 1069.64V1114.9ZM275.702 1104.27L295.515 1115.71L334.708 1093.08L314.895 1081.64L275.702 1104.27ZM275.524 1081.08V1103.95L314.718 1081.32V1058.45L275.524 1081.08ZM315.08 1081.32L334.893 1092.76V1069.88L315.08 1058.45V1081.32ZM215.986 1069.79L235.012 1080.77L254.825 1069.33L235.799 1058.35L215.986 1069.79ZM236.154 1058.14L275.347 1080.77L295.16 1069.33L255.967 1046.7L236.154 1058.14ZM215.809 1046.59V1069.47L235.622 1058.03V1035.16L215.809 1046.59ZM315.08 1058.03L334.893 1069.47V1046.59L315.08 1035.16V1058.03ZM295.699 1046.14V1069.02L314.725 1058.03V1035.16L295.699 1046.14ZM256.144 1046.39L295.344 1069.02V1046.14L256.144 1023.51V1046.39ZM235.976 1034.95V1057.83L255.789 1046.39V1023.51L235.976 1034.95Z"
              // fill={primaryColor}
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M274.396 902.577V856.897L255.016 845.706V822.204L295.535 798.814L335.083 821.65L354.471 810.459L374.816 822.211V869.003L335.261 891.839V914.222L314.916 925.966L274.396 902.577ZM295.096 914.122L314.909 925.561L334.722 914.122L314.909 902.683L295.096 914.122ZM274.928 902.477L294.741 913.917L314.554 902.477L294.741 891.038L274.928 902.477ZM315.086 902.371L334.899 913.81V891.839L315.086 880.4V902.371ZM274.751 856.904V902.172L294.564 890.733V845.458L274.751 856.904ZM294.918 890.726L314.731 902.165V856.904L294.918 845.465V890.726ZM315.263 880.088L335.076 891.527L374.27 868.897L354.457 857.458L315.263 880.088ZM315.086 856.904V879.783L354.279 857.153V834.274L315.086 856.904ZM354.641 857.153L374.454 868.592V845.713L354.641 834.274V857.153ZM275.715 833.962L314.909 856.592L334.722 845.153L295.528 822.523L275.715 833.962ZM255.547 845.607L274.573 856.592L294.386 845.153L275.361 834.168L255.547 845.607ZM255.37 822.416V845.295L275.183 833.856V810.977L255.37 822.416ZM354.641 833.856L374.454 845.295V822.416L354.641 810.977V833.856ZM335.261 821.963V844.841L354.286 833.856V810.977L335.261 821.963ZM295.705 822.211L334.906 844.841V821.963L295.705 799.332V822.211ZM275.538 810.771V833.65L295.351 822.211V799.332L275.538 810.771Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M449.114 1049.25V1003.58L429.733 992.385V968.889L470.253 945.5L509.801 968.336L529.182 957.145L549.526 968.889V1015.68L509.971 1038.52V1060.9L489.626 1072.64L449.114 1049.25ZM469.82 1060.8L489.633 1072.24L509.446 1060.8L489.633 1049.36L469.82 1060.8ZM449.653 1049.16L469.466 1060.59L489.279 1049.16L469.466 1037.72L449.653 1049.16ZM489.811 1049.05L509.624 1060.49V1038.52L489.811 1027.08V1049.05ZM449.468 1003.58V1048.84L469.281 1037.4V992.137L449.468 1003.58ZM469.643 1037.4L489.456 1048.84V1003.58L469.643 992.144V1037.4ZM489.988 1026.77L509.801 1038.21L548.995 1015.58L529.182 1004.14L489.988 1026.77ZM489.811 1003.58V1026.46L529.004 1003.83V980.953L489.811 1003.58ZM529.366 1003.83L549.179 1015.27V992.392L529.366 980.953V1003.83ZM450.44 980.641L489.633 1003.27L509.446 991.832L470.253 969.201L450.44 980.641ZM430.265 992.285L449.291 1003.27L469.104 991.832L450.078 980.846L430.265 992.285ZM430.088 969.095V991.973L449.901 980.534V957.656L430.088 969.095ZM529.366 980.541L549.179 991.981V969.102L529.366 957.663V980.541ZM509.978 968.641V991.52L529.004 980.534V957.656L509.978 968.641ZM470.43 968.889L509.631 991.52V968.641L470.43 946.011V968.889ZM450.256 957.45V980.328L470.069 968.889V946.011L450.256 957.45Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M214.139 1241.37V1195.69L194.759 1184.5V1161L235.278 1137.61L274.826 1160.45L294.207 1149.26L314.552 1161V1207.79L274.997 1230.63V1253.01L254.652 1264.75L214.139 1241.37ZM234.846 1252.91L254.659 1264.35L274.472 1252.91L254.659 1241.47L234.846 1252.91ZM214.678 1241.27L234.491 1252.71L254.304 1241.27L234.491 1229.83L214.678 1241.27ZM254.836 1241.16L274.649 1252.6V1230.63L254.836 1219.19V1241.16ZM214.494 1195.69V1240.95L234.307 1229.51V1184.25L214.494 1195.69ZM234.669 1229.51L254.482 1240.95V1195.69L234.669 1184.25V1229.51ZM255.013 1218.88L274.826 1230.32L314.02 1207.69L294.207 1196.25L255.013 1218.88ZM254.836 1195.69V1218.57L294.03 1195.94V1173.06L254.836 1195.69ZM294.391 1195.94L314.204 1207.38V1184.5L294.391 1173.06V1195.94ZM215.465 1172.75L254.659 1195.38L274.472 1183.94L235.278 1161.31L215.465 1172.75ZM195.291 1184.4L214.317 1195.38L234.13 1183.94L215.104 1172.96L195.291 1184.4ZM195.113 1161.21V1184.08L214.926 1172.64V1149.77L195.113 1161.21ZM294.391 1172.65L314.204 1184.09V1161.21L294.391 1149.77V1172.65ZM275.004 1160.75V1183.63L294.03 1172.64V1149.77L275.004 1160.75ZM235.456 1161L274.656 1183.63V1160.75L235.456 1138.12V1161ZM215.281 1149.56V1172.44L235.094 1161V1138.12L215.281 1149.56Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M193.773 1357.37V1311.69L174.393 1300.5V1277L214.912 1253.61L254.46 1276.45L273.841 1265.26L294.186 1277V1323.79L254.63 1346.63V1369.01L234.286 1380.75L193.773 1357.37ZM214.48 1368.91L234.293 1380.35L254.106 1368.91L234.293 1357.47L214.48 1368.91ZM194.312 1357.27L214.125 1368.71L233.938 1357.27L214.125 1345.83L194.312 1357.27ZM234.47 1357.16L254.283 1368.6V1346.63L234.47 1335.19V1357.16ZM194.128 1311.69V1356.95L213.941 1345.51V1300.25L194.128 1311.69ZM214.302 1345.51L234.115 1356.95V1311.69L214.302 1300.25V1345.51ZM234.647 1334.88L254.46 1346.32L293.654 1323.69L273.841 1312.25L234.647 1334.88ZM234.47 1311.69V1334.57L273.663 1311.94V1289.06L234.47 1311.69ZM274.025 1311.94L293.838 1323.38V1300.5L274.025 1289.06V1311.94ZM195.099 1288.75L234.293 1311.38L254.106 1299.94L214.912 1277.31L195.099 1288.75ZM174.924 1300.4L193.95 1311.38L213.763 1299.94L194.737 1288.96L174.924 1300.4ZM174.747 1277.21V1300.08L194.56 1288.64V1265.77L174.747 1277.21ZM274.025 1288.65L293.838 1300.09V1277.21L274.025 1265.77V1288.65ZM254.638 1276.75V1299.63L273.663 1288.64V1265.77L254.638 1276.75ZM215.089 1277L254.29 1299.63V1276.75L215.089 1254.12V1277ZM194.915 1265.56V1288.44L214.728 1277V1254.12L194.915 1265.56Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M516.773 1241.25V1195.57L497.393 1184.38V1160.89L537.912 1137.5L577.46 1160.33L596.841 1149.14L617.186 1160.89V1207.68L577.63 1230.52V1252.9L557.286 1264.64L516.773 1241.25ZM537.48 1252.8L557.293 1264.24L577.106 1252.8L557.293 1241.36L537.48 1252.8ZM517.312 1241.15L537.125 1252.59L556.938 1241.15L537.125 1229.71L517.312 1241.15ZM557.47 1241.05L577.283 1252.49V1230.52L557.47 1219.08V1241.05ZM517.128 1195.58V1240.84L536.941 1229.4V1184.13L517.128 1195.58ZM537.302 1229.4L557.115 1240.84V1195.58L537.302 1184.14V1229.4ZM557.647 1218.77L577.46 1230.21L616.654 1207.58L596.841 1196.14L557.647 1218.77ZM557.47 1195.58V1218.46L596.663 1195.83V1172.95L557.47 1195.58ZM597.025 1195.83L616.838 1207.27V1184.39L597.025 1172.95V1195.83ZM518.099 1172.64L557.293 1195.27L577.106 1183.83L537.912 1161.2L518.099 1172.64ZM497.924 1184.28L516.95 1195.27L536.763 1183.83L517.737 1172.84L497.924 1184.28ZM497.747 1161.09V1183.97L517.56 1172.53V1149.65L497.747 1161.09ZM597.025 1172.54L616.838 1183.98V1161.1L597.025 1149.66V1172.54ZM577.638 1160.64V1183.52L596.663 1172.53V1149.65L577.638 1160.64ZM538.089 1160.89L577.29 1183.52V1160.64L538.089 1138.01V1160.89ZM517.915 1149.45V1172.33L537.728 1160.89V1138.01L517.915 1149.45Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M496.408 1357.25V1311.57L477.027 1300.38V1276.89L517.547 1253.5L557.095 1276.33L576.475 1265.14L596.82 1276.89V1323.68L557.265 1346.52V1368.9L536.92 1380.64L496.408 1357.25ZM517.114 1368.8L536.927 1380.24L556.74 1368.8L536.927 1357.36L517.114 1368.8ZM496.947 1357.15L516.76 1368.59L536.573 1357.15L516.76 1345.71L496.947 1357.15ZM537.105 1357.05L556.918 1368.49V1346.52L537.105 1335.08V1357.05ZM496.762 1311.58V1356.84L516.575 1345.4V1300.13L496.762 1311.58ZM516.937 1345.4L536.75 1356.84V1311.58L516.937 1300.14V1345.4ZM537.282 1334.77L557.095 1346.21L596.289 1323.58L576.475 1312.14L537.282 1334.77ZM537.105 1311.58V1334.46L576.298 1311.83V1288.95L537.105 1311.58ZM576.66 1311.83L596.473 1323.27V1300.39L576.66 1288.95V1311.83ZM497.734 1288.64L536.927 1311.27L556.74 1299.83L517.547 1277.2L497.734 1288.64ZM477.559 1300.28L496.585 1311.27L516.398 1299.83L497.372 1288.84L477.559 1300.28ZM477.382 1277.09V1299.97L497.195 1288.53V1265.65L477.382 1277.09ZM576.66 1288.54L596.473 1299.98V1277.1L576.66 1265.66V1288.54ZM557.272 1276.64V1299.52L576.298 1288.53V1265.65L557.272 1276.64ZM517.724 1276.89L556.925 1299.52V1276.64L517.724 1254.01V1276.89ZM497.55 1265.45V1288.33L517.363 1276.89V1254.01L497.55 1265.45Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M341.15 1090.23V1044.55L321.77 1033.36V1009.87L362.289 986.479L401.837 1009.31L421.218 998.123L441.563 1009.87V1056.66L402.007 1079.5V1101.88L381.662 1113.62L341.15 1090.23ZM361.857 1101.78L381.67 1113.22L401.483 1101.78L381.67 1090.34L361.857 1101.78ZM341.689 1090.13L361.502 1101.57L381.315 1090.13L361.502 1078.69L341.689 1090.13ZM381.847 1090.03L401.66 1101.47V1079.5L381.847 1068.06V1090.03ZM341.505 1044.56V1089.82L361.318 1078.38V1033.12L341.505 1044.56ZM361.679 1078.38L381.492 1089.82V1044.56L361.679 1033.12V1078.38ZM382.024 1067.75L401.837 1079.19L441.031 1056.56L421.218 1045.12L382.024 1067.75ZM381.847 1044.56V1067.44L421.04 1044.81V1021.93L381.847 1044.56ZM421.402 1044.81L441.215 1056.25V1033.37L421.402 1021.93V1044.81ZM342.476 1021.62L381.67 1044.25L401.483 1032.81L362.289 1010.18L342.476 1021.62ZM322.301 1033.26L341.327 1044.25L361.14 1032.81L342.114 1021.82L322.301 1033.26ZM322.124 1010.07V1032.95L341.937 1021.51V998.634L322.124 1010.07ZM421.402 1021.52L441.215 1032.96V1010.08L421.402 998.641V1021.52ZM402.014 1009.62V1032.5L421.04 1021.51V998.634L402.014 1009.62ZM362.466 1009.87L401.667 1032.5V1009.62L362.466 986.989V1009.87ZM342.292 998.428V1021.31L362.105 1009.87V986.989L342.292 998.428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M816.773 1232.25V1186.57L797.393 1175.38V1151.89L837.912 1128.5L877.46 1151.33L896.841 1140.14L917.186 1151.89V1198.68L877.63 1221.52V1243.9L857.286 1255.64L816.773 1232.25ZM837.48 1243.8L857.293 1255.24L877.106 1243.8L857.293 1232.36L837.48 1243.8ZM817.312 1232.15L837.125 1243.59L856.938 1232.15L837.125 1220.71L817.312 1232.15ZM857.47 1232.05L877.283 1243.49V1221.52L857.47 1210.08V1232.05ZM817.128 1186.58V1231.84L836.941 1220.4V1175.13L817.128 1186.58ZM837.302 1220.4L857.115 1231.84V1186.58L837.302 1175.14V1220.4ZM857.647 1209.77L877.46 1221.21L916.654 1198.58L896.841 1187.14L857.647 1209.77ZM857.47 1186.58V1209.46L896.663 1186.83V1163.95L857.47 1186.58ZM897.025 1186.83L916.838 1198.27V1175.39L897.025 1163.95V1186.83ZM818.099 1163.64L857.293 1186.27L877.106 1174.83L837.912 1152.2L818.099 1163.64ZM797.924 1175.28L816.95 1186.27L836.763 1174.83L817.737 1163.84L797.924 1175.28ZM797.747 1152.09V1174.97L817.56 1163.53V1140.65L797.747 1152.09ZM897.025 1163.54L916.838 1174.98V1152.1L897.025 1140.66V1163.54ZM877.638 1151.64V1174.52L896.663 1163.53V1140.65L877.638 1151.64ZM838.089 1151.89L877.29 1174.52V1151.64L838.089 1129.01V1151.89ZM817.915 1140.45V1163.33L837.728 1151.89V1129.01L817.915 1140.45Z"
              fill="#192530"
              // fill={primaryColor}
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M796.408 1348.25V1302.57L777.027 1291.38V1267.89L817.547 1244.5L857.095 1267.33L876.475 1256.14L896.82 1267.89V1314.68L857.265 1337.52V1359.9L836.92 1371.64L796.408 1348.25ZM817.114 1359.8L836.927 1371.24L856.74 1359.8L836.927 1348.36L817.114 1359.8ZM796.947 1348.15L816.76 1359.59L836.573 1348.15L816.76 1336.71L796.947 1348.15ZM837.105 1348.05L856.918 1359.49V1337.52L837.105 1326.08V1348.05ZM796.762 1302.58V1347.84L816.575 1336.4V1291.13L796.762 1302.58ZM816.937 1336.4L836.75 1347.84V1302.58L816.937 1291.14V1336.4ZM837.282 1325.77L857.095 1337.21L896.289 1314.58L876.475 1303.14L837.282 1325.77ZM837.105 1302.58V1325.46L876.298 1302.83V1279.95L837.105 1302.58ZM876.66 1302.83L896.473 1314.27V1291.39L876.66 1279.95V1302.83ZM797.734 1279.64L836.927 1302.27L856.74 1290.83L817.547 1268.2L797.734 1279.64ZM777.559 1291.28L796.585 1302.27L816.398 1290.83L797.372 1279.84L777.559 1291.28ZM777.382 1268.09V1290.97L797.195 1279.53V1256.65L777.382 1268.09ZM876.66 1279.54L896.473 1290.98V1268.1L876.66 1256.66V1279.54ZM857.272 1267.64V1290.52L876.298 1279.53V1256.65L857.272 1267.64ZM817.724 1267.89L856.925 1290.52V1267.64L817.724 1245.01V1267.89ZM797.55 1256.45V1279.33L817.363 1267.89V1245.01L797.55 1256.45Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1015.01 1267.25V1221.57L995.634 1210.38V1186.89L1036.15 1163.5L1075.7 1186.33L1095.08 1175.14L1115.43 1186.89V1233.68L1075.87 1256.52V1278.9L1055.53 1290.64L1015.01 1267.25ZM1035.72 1278.8L1055.53 1290.24L1075.35 1278.8L1055.53 1267.36L1035.72 1278.8ZM1015.55 1267.15L1035.37 1278.59L1055.18 1267.15L1035.37 1255.71L1015.55 1267.15ZM1055.71 1267.05L1075.52 1278.49V1256.52L1055.71 1245.08V1267.05ZM1015.37 1221.58V1266.84L1035.18 1255.4V1210.13L1015.37 1221.58ZM1035.54 1255.4L1055.36 1266.84V1221.58L1035.54 1210.14V1255.4ZM1055.89 1244.77L1075.7 1256.21L1114.89 1233.58L1095.08 1222.14L1055.89 1244.77ZM1055.71 1221.58V1244.46L1094.9 1221.83V1198.95L1055.71 1221.58ZM1095.27 1221.83L1115.08 1233.27V1210.39L1095.27 1198.95V1221.83ZM1016.34 1198.64L1055.53 1221.27L1075.35 1209.83L1036.15 1187.2L1016.34 1198.64ZM996.166 1210.28L1015.19 1221.27L1035 1209.83L1015.98 1198.84L996.166 1210.28ZM995.988 1187.09V1209.97L1015.8 1198.53V1175.65L995.988 1187.09ZM1095.27 1198.54L1115.08 1209.98V1187.1L1095.27 1175.66V1198.54ZM1075.88 1186.64V1209.52L1094.9 1198.53V1175.65L1075.88 1186.64ZM1036.33 1186.89L1075.53 1209.52V1186.64L1036.33 1164.01V1186.89ZM1016.16 1175.45V1198.33L1035.97 1186.89V1164.01L1016.16 1175.45Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1404.01 1333.94V1288.26L1384.63 1277.07V1253.58L1425.15 1230.19L1464.7 1253.02L1484.08 1241.83L1504.43 1253.58V1300.37L1464.87 1323.21V1345.59L1444.53 1357.33L1404.01 1333.94ZM1424.72 1345.49L1444.53 1356.93L1464.35 1345.49L1444.53 1334.05L1424.72 1345.49ZM1404.55 1333.84L1424.37 1345.28L1444.18 1333.84L1424.37 1322.4L1404.55 1333.84ZM1444.71 1333.74L1464.52 1345.18V1323.21L1444.71 1311.77V1333.74ZM1404.37 1288.27V1333.53L1424.18 1322.09V1276.82L1404.37 1288.27ZM1424.54 1322.09L1444.36 1333.53V1288.27L1424.54 1276.83V1322.09ZM1444.89 1311.46L1464.7 1322.9L1503.89 1300.27L1484.08 1288.83L1444.89 1311.46ZM1444.71 1288.27V1311.15L1483.9 1288.52V1265.64L1444.71 1288.27ZM1484.27 1288.52L1504.08 1299.96V1277.08L1484.27 1265.64V1288.52ZM1405.34 1265.33L1444.53 1287.96L1464.35 1276.52L1425.15 1253.89L1405.34 1265.33ZM1385.17 1276.97L1404.19 1287.96L1424 1276.52L1404.98 1265.53L1385.17 1276.97ZM1384.99 1253.78V1276.66L1404.8 1265.22V1242.34L1384.99 1253.78ZM1484.27 1265.23L1504.08 1276.67V1253.79L1484.27 1242.35V1265.23ZM1464.88 1253.33V1276.21L1483.9 1265.22V1242.34L1464.88 1253.33ZM1425.33 1253.58L1464.53 1276.21V1253.33L1425.33 1230.7V1253.58ZM1405.16 1242.14V1265.02L1424.97 1253.58V1230.7L1405.16 1242.14Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1598.56 1369.74V1324.06L1579.18 1312.87V1289.38L1619.7 1265.99L1659.24 1288.82L1678.62 1277.63L1698.97 1289.38V1336.17L1659.41 1359.01V1381.39L1639.07 1393.13L1598.56 1369.74ZM1619.26 1381.29L1639.08 1392.73L1658.89 1381.29L1639.08 1369.85L1619.26 1381.29ZM1599.1 1369.64L1618.91 1381.08L1638.72 1369.64L1618.91 1358.2L1599.1 1369.64ZM1639.25 1369.54L1659.07 1380.98V1359.01L1639.25 1347.57V1369.54ZM1598.91 1324.07V1369.33L1618.72 1357.89V1312.62L1598.91 1324.07ZM1619.09 1357.89L1638.9 1369.33V1324.07L1619.09 1312.63V1357.89ZM1639.43 1347.26L1659.24 1358.7L1698.44 1336.07L1678.62 1324.63L1639.43 1347.26ZM1639.25 1324.07V1346.95L1678.45 1324.32V1301.44L1639.25 1324.07ZM1678.81 1324.32L1698.62 1335.76V1312.88L1678.81 1301.44V1324.32ZM1599.88 1301.13L1639.08 1323.76L1658.89 1312.32L1619.7 1289.69L1599.88 1301.13ZM1579.71 1312.77L1598.73 1323.76L1618.55 1312.32L1599.52 1301.33L1579.71 1312.77ZM1579.53 1289.58V1312.46L1599.34 1301.02V1278.14L1579.53 1289.58ZM1678.81 1301.03L1698.62 1312.47V1289.59L1678.81 1278.15V1301.03ZM1659.42 1289.13V1312.01L1678.45 1301.02V1278.14L1659.42 1289.13ZM1619.87 1289.38L1659.07 1312.01V1289.13L1619.87 1266.5V1289.38ZM1599.7 1277.94V1300.82L1619.51 1289.38V1266.5L1599.7 1277.94Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1491.01 1411.96V1366.28L1471.63 1355.09V1331.6L1512.15 1308.21L1551.7 1331.05L1571.08 1319.85L1591.43 1331.6V1378.39L1551.87 1401.23V1423.61L1531.53 1435.35L1491.01 1411.96ZM1511.72 1423.51L1531.53 1434.95L1551.35 1423.51L1531.53 1412.07L1511.72 1423.51ZM1491.55 1411.86L1511.37 1423.3L1531.18 1411.86L1511.37 1400.43L1491.55 1411.86ZM1531.71 1411.76L1551.52 1423.2V1401.23L1531.71 1389.79V1411.76ZM1491.37 1366.29V1411.55L1511.18 1400.11V1354.85L1491.37 1366.29ZM1511.54 1400.11L1531.36 1411.55V1366.29L1511.54 1354.85V1400.11ZM1531.89 1389.48L1551.7 1400.92L1590.89 1378.29L1571.08 1366.85L1531.89 1389.48ZM1531.71 1366.29V1389.17L1570.9 1366.54V1343.66L1531.71 1366.29ZM1571.27 1366.54L1591.08 1377.98V1355.1L1571.27 1343.66V1366.54ZM1492.34 1343.35L1531.53 1365.98L1551.35 1354.54L1512.15 1331.91L1492.34 1343.35ZM1472.17 1354.99L1491.19 1365.98L1511 1354.54L1491.98 1343.56L1472.17 1354.99ZM1471.99 1331.8V1354.68L1491.8 1343.24V1320.36L1471.99 1331.8ZM1571.27 1343.25L1591.08 1354.69V1331.81L1571.27 1320.37V1343.25ZM1551.88 1331.35V1354.23L1570.9 1343.24V1320.36L1551.88 1331.35ZM1512.33 1331.6L1551.53 1354.23V1331.35L1512.33 1308.72V1331.6ZM1492.16 1320.16V1343.04L1511.97 1331.6V1308.72L1492.16 1320.16Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M925.014 1189.75V1144.08L905.634 1132.88V1109.39L946.153 1086L985.701 1108.84L1005.08 1097.64L1025.43 1109.39V1156.18L985.872 1179.02V1201.4L965.527 1213.14L925.014 1189.75ZM945.721 1201.3L965.534 1212.74L985.347 1201.3L965.534 1189.86L945.721 1201.3ZM925.553 1189.66L945.366 1201.09L965.179 1189.66L945.366 1178.22L925.553 1189.66ZM965.711 1189.55L985.524 1200.99V1179.02L965.711 1167.58V1189.55ZM925.369 1144.08V1189.34L945.182 1177.9V1132.64L925.369 1144.08ZM945.544 1177.9L965.357 1189.34V1144.08L945.544 1132.64V1177.9ZM965.888 1167.27L985.701 1178.71L1024.89 1156.08L1005.08 1144.64L965.888 1167.27ZM965.711 1144.08V1166.96L1004.9 1144.33V1121.45L965.711 1144.08ZM1005.27 1144.33L1025.08 1155.77V1132.89L1005.27 1121.45V1144.33ZM926.34 1121.14L965.534 1143.77L985.347 1132.33L946.153 1109.7L926.34 1121.14ZM906.166 1132.79L925.192 1143.77L945.005 1132.33L925.979 1121.35L906.166 1132.79ZM905.988 1109.59V1132.47L925.801 1121.03V1098.16L905.988 1109.59ZM1005.27 1121.04L1025.08 1132.48V1109.6L1005.27 1098.16V1121.04ZM985.879 1109.14V1132.02L1004.9 1121.03V1098.16L985.879 1109.14ZM946.331 1109.39L985.531 1132.02V1109.14L946.331 1086.51V1109.39ZM926.156 1097.95V1120.83L945.969 1109.39V1086.51L926.156 1097.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1123.26 1224.75V1179.08L1103.88 1167.88V1144.39L1144.39 1121L1183.94 1143.84L1203.32 1132.64L1223.67 1144.39V1191.18L1184.11 1214.02V1236.4L1163.77 1248.14L1123.26 1224.75ZM1143.96 1236.3L1163.78 1247.74L1183.59 1236.3L1163.78 1224.86L1143.96 1236.3ZM1123.79 1224.66L1143.61 1236.09L1163.42 1224.66L1143.61 1213.22L1123.79 1224.66ZM1163.95 1224.55L1183.77 1235.99V1214.02L1163.95 1202.58V1224.55ZM1123.61 1179.08V1224.34L1143.42 1212.9V1167.64L1123.61 1179.08ZM1143.78 1212.9L1163.6 1224.34V1179.08L1143.78 1167.64V1212.9ZM1164.13 1202.27L1183.94 1213.71L1223.14 1191.08L1203.32 1179.64L1164.13 1202.27ZM1163.95 1179.08V1201.96L1203.15 1179.33V1156.45L1163.95 1179.08ZM1203.51 1179.33L1223.32 1190.77V1167.89L1203.51 1156.45V1179.33ZM1124.58 1156.14L1163.78 1178.77L1183.59 1167.33L1144.39 1144.7L1124.58 1156.14ZM1104.41 1167.79L1123.43 1178.77L1143.25 1167.33L1124.22 1156.35L1104.41 1167.79ZM1104.23 1144.59V1167.47L1124.04 1156.03V1133.16L1104.23 1144.59ZM1203.51 1156.04L1223.32 1167.48V1144.6L1203.51 1133.16V1156.04ZM1184.12 1144.14V1167.02L1203.15 1156.03V1133.16L1184.12 1144.14ZM1144.57 1144.39L1183.77 1167.02V1144.14L1144.57 1121.51V1144.39ZM1124.4 1132.95V1155.83L1144.21 1144.39V1121.51L1124.4 1132.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1209.01 1298.75V1253.08L1189.63 1241.88V1218.39L1230.15 1195L1269.7 1217.84L1289.08 1206.64L1309.43 1218.39V1265.18L1269.87 1288.02V1310.4L1249.53 1322.14L1209.01 1298.75ZM1229.72 1310.3L1249.53 1321.74L1269.35 1310.3L1249.53 1298.86L1229.72 1310.3ZM1209.55 1298.66L1229.37 1310.09L1249.18 1298.66L1229.37 1287.22L1209.55 1298.66ZM1249.71 1298.55L1269.52 1309.99V1288.02L1249.71 1276.58V1298.55ZM1209.37 1253.08V1298.34L1229.18 1286.9V1241.64L1209.37 1253.08ZM1229.54 1286.9L1249.36 1298.34V1253.08L1229.54 1241.64V1286.9ZM1249.89 1276.27L1269.7 1287.71L1308.89 1265.08L1289.08 1253.64L1249.89 1276.27ZM1249.71 1253.08V1275.96L1288.9 1253.33V1230.45L1249.71 1253.08ZM1289.27 1253.33L1309.08 1264.77V1241.89L1289.27 1230.45V1253.33ZM1210.34 1230.14L1249.53 1252.77L1269.35 1241.33L1230.15 1218.7L1210.34 1230.14ZM1190.17 1241.79L1209.19 1252.77L1229 1241.33L1209.98 1230.35L1190.17 1241.79ZM1189.99 1218.59V1241.47L1209.8 1230.03V1207.16L1189.99 1218.59ZM1289.27 1230.04L1309.08 1241.48V1218.6L1289.27 1207.16V1230.04ZM1269.88 1218.14V1241.02L1288.9 1230.03V1207.16L1269.88 1218.14ZM1230.33 1218.39L1269.53 1241.02V1218.14L1230.33 1195.51V1218.39ZM1210.16 1206.95V1229.83L1229.97 1218.39V1195.51L1210.16 1206.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M315.246 676.496V630.824L295.865 619.633V596.137L336.385 572.748L375.933 595.584L395.313 584.393L415.658 596.144V642.937L376.103 665.773V688.155L355.758 699.899L315.246 676.496ZM335.945 688.042L355.758 699.481L375.571 688.042L355.758 676.602L335.945 688.042ZM315.778 676.397L335.591 687.836L355.404 676.397L335.591 664.957L315.778 676.397ZM355.943 676.29L375.756 687.73V665.759L355.943 654.319V676.29ZM315.6 630.824V676.092L335.413 664.652V619.385L315.6 630.824ZM335.768 664.645L355.581 676.085V630.824L335.768 619.385V664.645ZM356.12 654.015L375.933 665.454L415.126 642.823L395.313 631.384L356.12 654.015ZM355.943 630.824V653.702L395.136 631.072V608.194L355.943 630.824ZM395.491 631.072L415.304 642.511V619.633L395.491 608.194V631.072ZM316.565 607.881L355.758 630.512L375.571 619.073L336.378 596.442L316.565 607.881ZM296.397 619.526L315.423 630.512L335.236 619.073L316.21 608.087L296.397 619.526ZM296.22 596.343V619.222L316.033 607.782V584.904L296.22 596.343ZM395.491 607.782L415.304 619.222V596.343L395.491 584.904V607.782ZM376.11 595.882V618.761L395.136 607.775V584.897L376.11 595.882ZM336.555 596.13L375.756 618.761V595.882L336.555 573.252V596.13ZM316.387 584.691V607.569L336.2 596.13V573.252L316.387 584.691Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M100.004 755.543V709.864L80.624 698.673V675.177L121.144 651.788L160.692 674.624L180.072 663.433L200.417 675.184V721.977L160.862 744.813V767.195L140.517 778.939L100.004 755.543ZM120.704 767.089L140.517 778.528L160.33 767.089L140.517 755.649L120.704 767.089ZM100.536 755.444L120.349 766.883L140.162 755.444L120.349 744.005L100.536 755.444ZM140.694 755.337L160.507 766.777V744.806L140.694 733.367V755.337ZM100.359 709.871V755.139L120.172 743.7V698.439L100.359 709.871ZM120.527 743.692L140.34 755.132V709.871L120.527 698.432V743.692ZM140.872 733.055L160.685 744.494L199.878 721.863L180.065 710.424L140.872 733.055ZM140.694 709.871V732.75L179.888 710.119V687.241L140.694 709.871ZM180.249 710.119L200.063 721.559V698.68L180.249 687.241V710.119ZM101.323 686.929L140.517 709.559L160.33 698.12L121.137 675.489L101.323 686.929ZM81.1559 698.574L100.182 709.559L119.995 698.12L100.969 687.134L81.1559 698.574ZM80.9715 675.383V698.262L100.785 686.822V663.944L80.9715 675.383ZM180.249 686.829L200.063 698.269V675.39L180.249 663.951V686.829ZM160.862 674.929V697.808L179.888 686.822V663.944L160.862 674.929ZM121.314 675.177L160.514 697.808V674.929L121.314 652.299V675.177ZM101.139 663.738V686.617L120.952 675.177V652.299L101.139 663.738Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M187.404 828.47V782.791L168.023 771.6V748.104L208.543 724.715L248.091 747.551L267.472 736.36L287.816 748.104V794.897L248.261 817.733V840.115L227.916 851.859L187.404 828.47ZM208.11 840.015L227.923 851.455L247.737 840.015L227.923 828.576L208.11 840.015ZM187.943 828.371L207.756 839.81L227.569 828.371L207.756 816.931L187.943 828.371ZM228.101 828.264L247.914 839.703V817.733L228.101 806.293V828.264ZM187.758 782.798V828.058L207.571 816.619V771.351L187.758 782.798ZM207.933 816.619L227.746 828.058V782.798L207.933 771.358V816.619ZM228.278 805.988L248.091 817.428L287.285 794.797L267.472 783.358L228.278 805.988ZM228.101 782.798V805.676L267.294 783.046V760.167L228.101 782.798ZM267.656 783.046L287.469 794.485V771.607L267.656 760.167V783.046ZM188.73 759.855L227.923 782.486L247.737 771.046L208.543 748.416L188.73 759.855ZM168.555 771.5L187.581 782.486L207.394 771.046L188.368 760.061L168.555 771.5ZM168.378 748.31V771.188L188.191 759.749V736.87L168.378 748.31ZM267.656 759.756L287.469 771.195V748.317L267.656 736.877V759.756ZM248.268 747.856V770.734L267.294 759.749V736.87L248.268 747.856ZM208.72 748.104L247.921 770.734V747.856L208.72 725.225V748.104ZM188.546 736.665V759.543L208.359 748.104V725.225L188.546 736.665Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M295.17 789.358V743.678L275.789 732.487V708.992L316.309 685.603L355.864 708.439L375.244 697.248L395.589 708.999V755.791L356.034 778.627V801.01L335.689 812.754L295.17 789.358ZM315.869 800.896L335.682 812.335L355.495 800.896L335.682 789.457L315.869 800.896ZM295.701 789.251L315.514 800.69L335.327 789.251L315.514 777.812L295.701 789.251ZM335.859 789.152L355.672 800.591V778.62L335.859 767.181V789.152ZM295.524 743.678V788.946L315.337 777.507V732.239L295.524 743.678ZM315.692 777.5L335.505 788.939V743.678L315.692 732.239V777.5ZM336.037 766.869L355.85 778.308L395.043 755.678L375.23 744.239L336.037 766.869ZM335.859 743.678V766.557L375.053 743.927V721.048L335.859 743.678ZM375.415 743.927L395.228 755.366V732.487L375.415 721.048V743.927ZM276.321 732.388L295.347 743.373L315.16 731.934L296.134 720.949L276.321 732.388ZM296.489 720.743L335.682 743.373L355.495 731.934L316.302 709.304L296.489 720.743ZM276.144 709.197V732.076L295.957 720.637V697.758L276.144 709.197ZM375.415 720.637L395.228 732.076V709.197L375.415 697.758V720.637ZM356.034 708.744V731.622L375.06 720.637V697.758L356.034 708.744ZM316.479 708.992L355.679 731.622V708.744L316.479 686.113V708.992ZM296.311 697.552V720.431L316.124 708.992V686.113L296.311 697.552Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M207.72 716.31V670.631L188.34 659.44V635.937L228.859 612.548L268.408 635.384L287.795 624.193L308.14 635.944V682.737L268.585 705.573V727.955L248.24 739.699L207.72 716.31ZM228.42 727.856L248.233 739.295L268.046 727.856L248.233 716.416L228.42 727.856ZM208.252 716.211L228.065 727.65L247.878 716.211L228.065 704.771L208.252 716.211ZM248.41 716.104L268.223 727.544V705.573L248.41 694.133V716.104ZM208.075 670.638V715.906L227.888 704.466V659.191L208.075 670.638ZM228.243 704.459L248.056 715.899V670.638L228.243 659.199V704.459ZM248.587 693.821L268.4 705.261L307.594 682.63L287.781 671.191L248.587 693.821ZM248.41 670.638V693.516L287.604 670.886V648.008L248.41 670.638ZM287.965 670.886L307.778 682.325V659.447L287.965 648.008V670.886ZM209.039 647.695L248.233 670.326L268.046 658.887L228.852 636.256L209.039 647.695ZM188.872 659.34L207.898 670.326L227.711 658.887L208.685 647.901L188.872 659.34ZM188.694 636.15V659.028L208.507 647.589V624.711L188.694 636.15ZM287.965 647.589L307.778 659.028V636.15L287.965 624.711V647.589ZM268.585 635.696V658.574L287.611 647.589V624.711L268.585 635.696ZM229.03 635.944L268.23 658.574V635.696L229.03 613.066V635.944ZM208.862 624.505V647.383L228.675 635.944V613.066L208.862 624.505Z"
              fill="#192530"
              // fill={primaryColor}
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M7.24084 677.907V632.227L-12.1396 621.036V597.534L28.38 574.145L67.9281 596.981L87.3156 585.789L107.661 597.541V644.333L68.1053 667.169V689.552L47.7604 701.296L7.24084 677.907ZM27.9403 689.452L47.7533 700.892L67.5664 689.452L47.7533 678.013L27.9403 689.452ZM7.77269 677.807L27.5857 689.247L47.3988 677.807L27.5857 666.368L7.77269 677.807ZM47.9306 677.701L67.7437 689.14V667.169L47.9306 655.73V677.701ZM7.5954 632.235V677.502L27.4085 666.063V620.788L7.5954 632.235ZM27.763 666.056L47.5761 677.495V632.235L27.763 620.795V666.056ZM48.1079 655.418L67.921 666.857L107.114 644.227L87.3014 632.788L48.1079 655.418ZM47.9306 632.235V655.113L87.1242 632.483V609.604L47.9306 632.235ZM87.4858 632.483L107.299 643.922V621.043L87.4858 609.604V632.483ZM8.55981 609.292L47.7533 631.923L67.5664 620.483L28.3729 597.853L8.55981 609.292ZM-11.6078 620.937L7.41812 631.923L27.2312 620.483L8.20524 609.498L-11.6078 620.937ZM-11.7851 597.746V620.625L8.02796 609.186V586.307L-11.7851 597.746ZM87.4858 609.186L107.299 620.625V597.746L87.4858 586.307V609.186ZM68.1053 597.293V620.171L87.1312 609.186V586.307L68.1053 597.293ZM28.5501 597.541L67.7508 620.171V597.293L28.5501 574.662V597.541ZM8.38253 586.102V608.98L28.1956 597.541V574.662L8.38253 586.102Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M618.648 670.979V625.307L599.268 614.116V590.621L639.787 567.231L679.335 590.067L698.716 578.876L719.061 590.628V637.42L679.505 660.256V682.638L659.161 694.383L618.648 670.979ZM639.348 682.525L659.161 693.964L678.974 682.525L659.161 671.086L639.348 682.525ZM619.18 670.88L638.993 682.319L658.806 670.88L638.993 659.441L619.18 670.88ZM659.345 670.774L679.158 682.213V660.242L659.345 648.803V670.774ZM619.003 625.307V670.575L638.816 659.136V613.868L619.003 625.307ZM639.17 659.129L658.983 670.568V625.307L639.17 613.868V659.129ZM659.522 648.498L679.335 659.937L718.529 637.307L698.716 625.868L659.522 648.498ZM659.345 625.307V648.186L698.538 625.556V602.677L659.345 625.307ZM698.893 625.556L718.706 636.995V614.116L698.893 602.677V625.556ZM619.967 602.365L659.161 624.995L678.974 613.556L639.78 590.926L619.967 602.365ZM599.799 614.01L618.825 624.995L638.638 613.556L619.613 602.571L599.799 614.01ZM599.622 590.826V613.705L619.435 602.266V579.387L599.622 590.826ZM698.893 602.266L718.706 613.705V590.826L698.893 579.387V602.266ZM679.513 590.365V613.244L698.538 602.259V579.38L679.513 590.365ZM639.957 590.614L679.158 613.244V590.365L639.957 567.735V590.614ZM619.79 579.174V602.053L639.603 590.614V567.735L619.79 579.174Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M403.407 750.026V704.347L384.026 693.156V669.661L424.546 646.271L464.094 669.108L483.475 657.916L503.819 669.668V716.46L464.264 739.296V761.679L443.919 773.423L403.407 750.026ZM424.106 761.572L443.919 773.011L463.732 761.572L443.919 750.133L424.106 761.572ZM403.939 749.927L423.752 761.366L443.565 749.927L423.752 738.488L403.939 749.927ZM444.097 749.821L463.91 761.26V739.289L444.097 727.85V749.821ZM403.761 704.354V749.622L423.574 738.183V692.922L403.761 704.354ZM423.929 738.176L443.742 749.615V704.354L423.929 692.915V738.176ZM444.274 727.538L464.087 738.977L503.28 716.347L483.467 704.908L444.274 727.538ZM444.097 704.354V727.233L483.29 704.603V681.724L444.097 704.354ZM483.652 704.603L503.465 716.042V693.163L483.652 681.724V704.603ZM404.726 681.412L443.919 704.042L463.732 692.603L424.539 669.973L404.726 681.412ZM384.558 693.057L403.584 704.042L423.397 692.603L404.371 681.618L384.558 693.057ZM384.374 669.866V692.745L404.187 681.306V658.427L384.374 669.866ZM483.652 681.313L503.465 692.752V669.873L483.652 658.434V681.313ZM464.264 669.412V692.291L483.29 681.306V658.427L464.264 669.412ZM424.716 669.661L463.917 692.291V669.412L424.716 646.782V669.661ZM404.541 658.221V681.1L424.354 669.661V646.782L404.541 658.221Z"
              fill="#192530"
            ></path>
          </g>
          <g class="active">
            {" "}
            <path
              d="M490.806 822.953V777.274L471.426 766.083V742.587L511.945 719.198L551.493 742.034L570.874 730.843L591.219 742.587V789.38L551.664 812.216V834.598L531.319 846.342L490.806 822.953ZM511.513 834.499L531.326 845.938L551.139 834.499L531.326 823.06L511.513 834.499ZM491.345 822.854L511.158 834.293L530.971 822.854L511.158 811.415L491.345 822.854ZM531.503 822.748L551.316 834.187V812.216L531.503 800.777V822.748ZM491.161 777.281V822.542L510.974 811.103V765.835L491.161 777.281ZM511.336 811.103L531.149 822.542V777.281L511.336 765.842V811.103ZM531.68 800.472L551.493 811.911L590.687 789.281L570.874 777.841L531.68 800.472ZM531.503 777.281V800.16L570.697 777.529V754.651L531.503 777.281ZM571.058 777.529L590.871 788.969V766.09L571.058 754.651V777.529ZM492.132 754.339L531.326 776.969L551.139 765.53L511.945 742.899L492.132 754.339ZM471.958 765.984L490.984 776.969L510.797 765.53L491.771 754.544L471.958 765.984ZM471.78 742.793V765.672L491.593 754.232V731.354L471.78 742.793ZM571.058 754.239L590.871 765.679V742.8L571.058 731.361V754.239ZM551.671 742.339V765.218L570.697 754.232V731.354L551.671 742.339ZM512.123 742.587L551.323 765.218V742.339L512.123 719.709V742.587ZM491.948 731.148V754.027L511.761 742.587V719.709L491.948 731.148Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M598.572 783.841V738.162L579.191 726.971V703.475L619.711 680.086L659.266 702.922L678.647 691.731L698.992 703.482V750.275L659.436 773.111V795.493L639.092 807.237L598.572 783.841ZM619.271 795.38L639.084 806.819L658.897 795.38L639.084 783.94L619.271 795.38ZM599.104 783.735L618.917 795.174L638.73 783.735L618.917 772.295L599.104 783.735ZM639.262 783.635L659.075 795.075V773.104L639.262 761.664V783.635ZM598.926 738.162V783.43L618.739 771.99V726.723L598.926 738.162ZM619.094 771.983L638.907 783.423V738.162L619.094 726.723V771.983ZM639.439 761.352L659.252 772.792L698.446 750.161L678.633 738.722L639.439 761.352ZM639.262 738.162V761.04L678.455 738.41V715.531L639.262 738.162ZM678.817 738.41L698.63 749.849V726.971L678.817 715.531V738.41ZM579.723 726.871L598.749 737.857L618.562 726.418L599.536 715.432L579.723 726.871ZM599.891 715.226L639.084 737.857L658.897 726.418L619.704 703.787L599.891 715.226ZM579.546 703.681V726.559L599.359 715.12V692.242L579.546 703.681ZM678.817 715.12L698.63 726.559V703.681L678.817 692.242V715.12ZM659.436 703.227V726.106L678.462 715.12V692.242L659.436 703.227ZM619.881 703.475L659.082 726.106V703.227L619.881 680.597V703.475ZM599.714 692.036V714.914L619.527 703.475V680.597L599.714 692.036Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M511.123 710.793V665.114L491.742 653.923V630.42L532.262 607.031L571.81 629.867L591.197 618.676L611.542 630.428V677.22L571.987 700.056V722.438L551.642 734.183L511.123 710.793ZM531.822 722.339L551.635 733.778L571.448 722.339L551.635 710.9L531.822 722.339ZM511.655 710.694L531.468 722.133L551.281 710.694L531.468 699.255L511.655 710.694ZM551.812 710.588L571.626 722.027V700.056L551.812 688.617V710.588ZM511.477 665.121V710.389L531.29 698.95V653.675L511.477 665.121ZM531.645 698.943L551.458 710.382V665.121L531.645 653.682V698.943ZM551.99 688.305L571.803 699.744L610.996 677.114L591.183 665.674L551.99 688.305ZM551.812 665.121V688L591.006 665.369V642.491L551.812 665.121ZM591.368 665.369L611.181 676.809V653.93L591.368 642.491V665.369ZM512.442 642.179L551.635 664.809L571.448 653.37L532.255 630.74L512.442 642.179ZM492.274 653.824L511.3 664.809L531.113 653.37L512.087 642.385L492.274 653.824ZM492.097 630.633V653.512L511.91 642.072V619.194L492.097 630.633ZM591.368 642.072L611.181 653.512V630.633L591.368 619.194V642.072ZM571.987 630.179V653.058L591.013 642.072V619.194L571.987 630.179ZM532.432 630.428L571.633 653.058V630.179L532.432 607.549V630.428ZM512.264 618.988V641.867L532.077 630.428V607.549L512.264 618.988Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M684.844 856.961V811.289L665.464 800.098V776.602L705.983 753.213L745.532 776.049L764.912 764.858L785.257 776.609V823.402L745.702 846.238V868.62L725.357 880.364L684.844 856.961ZM705.544 868.506L725.357 879.946L745.17 868.506L725.357 857.067L705.544 868.506ZM685.376 856.862L705.189 868.301L725.002 856.862L705.189 845.422L685.376 856.862ZM725.541 856.755L745.354 868.194V846.224L725.541 834.784V856.755ZM685.199 811.289V856.557L705.012 845.117V799.849L685.199 811.289ZM705.367 845.11L725.18 856.549V811.289L705.367 799.849V845.11ZM725.718 834.479L745.532 845.919L784.725 823.288L764.912 811.849L725.718 834.479ZM725.541 811.289V834.167L764.735 811.537V788.658L725.541 811.289ZM765.089 811.537L784.902 822.976V800.098L765.089 788.658V811.537ZM686.163 788.346L725.357 810.977L745.17 799.537L705.976 776.907L686.163 788.346ZM665.996 799.991L685.022 810.977L704.835 799.537L685.809 788.552L665.996 799.991ZM665.818 776.808V799.686L685.632 788.247V765.368L665.818 776.808ZM765.089 788.247L784.902 799.686V776.808L765.089 765.368V788.247ZM745.709 776.347V799.225L764.735 788.24V765.361L745.709 776.347ZM706.154 776.595L745.354 799.225V776.347L706.154 753.716V776.595ZM685.986 765.156V788.034L705.799 776.595V753.716L685.986 765.156Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M469.603 936.009V890.33L450.223 879.139V855.643L490.742 832.254L530.29 855.09L549.671 843.899L570.016 855.65V902.443L530.46 925.279V947.661L510.116 959.405L469.603 936.009ZM490.303 947.555L510.116 958.994L529.929 947.555L510.116 936.115L490.303 947.555ZM470.135 935.91L489.948 947.349L509.761 935.91L489.948 924.47L470.135 935.91ZM510.293 935.803L530.106 947.243V925.272L510.293 913.832V935.803ZM469.958 890.337V935.605L489.771 924.165V878.905L469.958 890.337ZM490.125 924.158L509.938 935.598V890.337L490.125 878.898V924.158ZM510.47 913.52L530.283 924.96L569.477 902.329L549.664 890.89L510.47 913.52ZM510.293 890.337V913.215L549.486 890.585V867.706L510.293 890.337ZM549.848 890.585L569.661 902.024V879.146L549.848 867.706V890.585ZM470.922 867.394L510.116 890.025L529.929 878.586L490.735 855.955L470.922 867.394ZM450.755 879.039L469.78 890.025L489.593 878.586L470.568 867.6L450.755 879.039ZM450.57 855.849V878.727L470.383 867.288V844.409L450.57 855.849ZM549.848 867.295L569.661 878.734V855.856L549.848 844.417V867.295ZM530.46 855.395V878.273L549.486 867.288V844.409L530.46 855.395ZM490.912 855.643L530.113 878.273V855.395L490.912 832.765V855.643ZM470.738 844.204V867.082L490.551 855.643V832.765L470.738 844.204Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M557.003 1008.93V963.256L537.623 952.064V928.569L578.143 905.18L617.691 928.016L637.071 916.825L657.416 928.569V975.361L617.861 998.197V1020.58L597.516 1032.32L557.003 1008.93ZM577.71 1020.48L597.523 1031.92L617.336 1020.48L597.523 1009.04L577.71 1020.48ZM557.542 1008.84L577.355 1020.27L597.169 1008.84L577.355 997.396L557.542 1008.84ZM597.7 1008.73L617.513 1020.17V998.197L597.7 986.758V1008.73ZM557.358 963.263V1008.52L577.171 997.084V951.816L557.358 963.263ZM577.533 997.084L597.346 1008.52V963.263L577.533 951.823V997.084ZM597.878 986.453L617.691 997.893L656.884 975.262L637.071 963.823L597.878 986.453ZM597.7 963.263V986.141L636.894 963.511V940.632L597.7 963.263ZM637.256 963.511L657.069 974.95V952.072L637.256 940.632V963.511ZM558.33 940.32L597.523 962.951L617.336 951.511L578.143 928.881L558.33 940.32ZM538.155 951.965L557.181 962.951L576.994 951.511L557.968 940.526L538.155 951.965ZM537.978 928.775V951.653L557.791 940.214V917.335L537.978 928.775ZM637.256 940.221L657.069 951.66V928.782L637.256 917.342V940.221ZM617.868 928.321V951.199L636.894 940.214V917.335L617.868 928.321ZM578.32 928.569L617.521 951.199V928.321L578.32 905.69V928.569ZM558.145 917.13V940.008L577.958 928.569V905.69L558.145 917.13Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M664.768 969.822V924.143L645.388 912.952V889.457L685.907 866.067L725.462 888.903L744.843 877.712L765.188 889.464V936.256L725.633 959.092V981.474L705.288 993.219L664.768 969.822ZM685.468 981.361L705.281 992.8L725.094 981.361L705.281 969.922L685.468 981.361ZM665.3 969.716L685.113 981.155L704.926 969.716L685.113 958.277L665.3 969.716ZM705.458 969.617L725.271 981.056V959.085L705.458 947.646V969.617ZM665.123 924.143V969.411L684.936 957.972V912.704L665.123 924.143ZM685.29 957.965L705.103 969.404V924.143L685.29 912.704V957.965ZM705.635 947.334L725.448 958.773L764.642 936.143L744.829 924.703L705.635 947.334ZM705.458 924.143V947.022L744.652 924.391V901.513L705.458 924.143ZM745.013 924.391L764.826 935.831V912.952L745.013 901.513V924.391ZM645.92 912.853L664.945 923.838L684.758 912.399L665.733 901.414L645.92 912.853ZM666.087 901.208L705.281 923.838L725.094 912.399L685.9 889.769L666.087 901.208ZM645.742 889.662V912.541L665.555 901.102V878.223L645.742 889.662ZM745.013 901.102L764.826 912.541V889.662L745.013 878.223V901.102ZM725.633 889.208V912.087L744.659 901.102V878.223L725.633 889.208ZM686.078 889.457L725.278 912.087V889.208L686.078 866.578V889.457ZM665.91 878.017V900.896L685.723 889.457V866.578L665.91 878.017Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M643.764 1082.35V1036.67L624.384 1025.48V1001.99L664.903 978.598L704.459 1001.43L723.839 990.243L744.184 1001.99V1048.79L704.629 1071.62V1094L684.284 1105.75L643.764 1082.35ZM664.464 1093.89L684.277 1105.33L704.09 1093.89L684.277 1082.45L664.464 1093.89ZM644.296 1082.25L664.109 1093.69L683.922 1082.25L664.109 1070.81L644.296 1082.25ZM684.454 1082.15L704.267 1093.59V1071.62L684.454 1060.18V1082.15ZM644.119 1036.67V1081.94L663.932 1070.5V1025.23L644.119 1036.67ZM664.286 1070.49L684.1 1081.93V1036.67L664.286 1025.23V1070.49ZM684.631 1059.86L704.444 1071.3L743.638 1048.67L723.825 1037.23L684.631 1059.86ZM684.454 1036.67V1059.55L723.648 1036.92V1014.04L684.454 1036.67ZM724.009 1036.92L743.822 1048.36V1025.48L724.009 1014.04V1036.92ZM624.916 1025.38L643.942 1036.37L663.755 1024.93L644.729 1013.94L624.916 1025.38ZM645.083 1013.74L684.277 1036.37L704.09 1024.93L664.896 1002.3L645.083 1013.74ZM624.738 1002.19V1025.07L644.551 1013.63V990.753L624.738 1002.19ZM724.009 1013.63L743.822 1025.07V1002.19L724.009 990.753V1013.63ZM704.629 1001.74V1024.62L723.655 1013.63V990.753L704.629 1001.74ZM665.074 1001.99L704.274 1024.62V1001.74L665.074 979.108V1001.99ZM644.906 990.548V1013.43L664.719 1001.99V979.108L644.906 990.548Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M535.8 1122.54V1076.86L516.42 1065.67V1042.18L556.939 1018.79L596.495 1041.63L615.875 1030.43L636.22 1042.19V1088.98L596.665 1111.81V1134.2L576.32 1145.94L535.8 1122.54ZM556.5 1134.08L576.313 1145.52L596.126 1134.08L576.313 1122.64L556.5 1134.08ZM536.332 1122.44L556.145 1133.88L575.958 1122.44L556.145 1111L536.332 1122.44ZM576.49 1122.34L596.303 1133.78V1111.81L576.49 1100.37V1122.34ZM536.155 1076.86V1122.13L555.968 1110.69V1065.43L536.155 1076.86ZM556.323 1110.69L576.136 1122.13V1076.86L556.323 1065.43V1110.69ZM576.667 1100.06L596.48 1111.49L635.674 1088.86L615.861 1077.43L576.667 1100.06ZM576.49 1076.86V1099.74L615.684 1077.11V1054.23L576.49 1076.86ZM616.045 1077.11L635.858 1088.55V1065.67L616.045 1054.23V1077.11ZM516.952 1065.57L535.978 1076.56L555.791 1065.12L536.765 1054.14L516.952 1065.57ZM537.119 1053.93L576.313 1076.56L596.126 1065.12L556.932 1042.49L537.119 1053.93ZM516.774 1042.38V1065.26L536.588 1053.82V1030.94L516.774 1042.38ZM616.045 1053.82L635.858 1065.26V1042.38L616.045 1030.94V1053.82ZM596.665 1041.93V1064.81L615.691 1053.82V1030.94L596.665 1041.93ZM557.11 1042.18L596.31 1064.81V1041.93L557.11 1019.3V1042.18ZM536.942 1030.74V1053.62L556.755 1042.18V1019.3L536.942 1030.74Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M577.32 896.776V851.097L557.939 839.906V816.403L598.459 793.014L638.007 815.85L657.395 804.659L677.74 816.41V863.203L638.184 886.039V908.421L617.84 920.165L577.32 896.776ZM598.019 908.321L617.832 919.761L637.645 908.321L617.832 896.882L598.019 908.321ZM577.852 896.676L597.665 908.116L617.478 896.676L597.665 885.237L577.852 896.676ZM618.01 896.57L637.823 908.009V886.039L618.01 874.599V896.57ZM577.675 851.104V896.372L597.488 884.932V839.657L577.675 851.104ZM597.842 884.925L617.655 896.364V851.104L597.842 839.664V884.925ZM618.187 874.287L638 885.727L677.194 863.096L657.381 851.657L618.187 874.287ZM618.01 851.104V873.982L657.203 851.352V828.473L618.01 851.104ZM657.565 851.352L677.378 862.791V839.913L657.565 828.473V851.352ZM578.639 828.161L617.832 850.792L637.645 839.352L598.452 816.722L578.639 828.161ZM558.471 839.806L577.497 850.792L597.31 839.352L578.284 828.367L558.471 839.806ZM558.294 816.616V839.494L578.107 828.055V805.176L558.294 816.616ZM657.565 828.055L677.378 839.494V816.616L657.565 805.176V828.055ZM638.184 816.162V839.04L657.21 828.055V805.176L638.184 816.162ZM598.629 816.41L637.83 839.04V816.162L598.629 793.531V816.41ZM578.462 804.971V827.849L598.275 816.41V793.531L578.462 804.971Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M356.224 452.687V407.015L336.844 395.824V372.329L377.363 348.939L416.911 371.775L436.292 360.584L456.637 372.336V419.128L417.082 441.964V464.347L396.737 476.091L356.224 452.687ZM376.924 464.233L396.737 475.672L416.55 464.233L396.737 452.794L376.924 464.233ZM356.756 452.588L376.569 464.027L396.382 452.588L376.569 441.149L356.756 452.588ZM396.921 452.482L416.734 463.921V441.95L396.921 430.511V452.482ZM356.579 407.015V452.283L376.392 440.844V395.576L356.579 407.015ZM376.746 440.837L396.559 452.276V407.015L376.746 395.576V440.837ZM397.098 430.206L416.911 441.645L456.105 419.015L436.292 407.576L397.098 430.206ZM396.921 407.015V429.894L436.115 407.264V384.385L396.921 407.015ZM436.469 407.264L456.282 418.703V395.824L436.469 384.385V407.264ZM357.543 384.073L396.737 406.703L416.55 395.264L377.356 372.634L357.543 384.073ZM337.376 395.718L356.401 406.703L376.215 395.264L357.189 384.279L337.376 395.718ZM337.198 372.534V395.413L357.011 383.974V361.095L337.198 372.534ZM436.469 383.974L456.282 395.413V372.534L436.469 361.095V383.974ZM417.089 372.073V394.952L436.115 383.967V361.088L417.089 372.073ZM377.534 372.322L416.734 394.952V372.073L377.534 349.443V372.322ZM357.366 360.882V383.761L377.179 372.322V349.443L357.366 360.882Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M268.649 378.61V332.938L249.269 321.747V298.252L289.788 274.862L329.336 297.698L348.717 286.507L369.062 298.259V345.051L329.506 367.887V390.269L309.162 402.014L268.649 378.61ZM289.349 390.156L309.162 401.595L328.975 390.156L309.162 378.717L289.349 390.156ZM269.181 378.511L288.994 389.95L308.807 378.511L288.994 367.072L269.181 378.511ZM309.346 378.405L329.159 389.844V367.873L309.346 356.434V378.405ZM269.004 332.938V378.206L288.817 366.767V321.499L269.004 332.938ZM289.171 366.76L308.984 378.199V332.938L289.171 321.499V366.76ZM309.523 356.129L329.336 367.568L368.53 344.938L348.717 333.498L309.523 356.129ZM309.346 332.938V355.817L348.539 333.186V310.308L309.346 332.938ZM348.894 333.186L368.707 344.626V321.747L348.894 310.308V333.186ZM269.968 309.996L309.162 332.626L328.975 321.187L289.781 298.556L269.968 309.996ZM249.8 321.641L268.826 332.626L288.639 321.187L269.613 310.201L249.8 321.641ZM249.623 298.457V321.336L269.436 309.896V287.018L249.623 298.457ZM348.894 309.896L368.707 321.336V298.457L348.894 287.018V309.896ZM329.514 297.996V320.875L348.539 309.889V287.011L329.514 297.996ZM289.958 298.244L329.159 320.875V297.996L289.958 275.366V298.244ZM269.791 286.805V309.684L289.604 298.244V275.366L269.791 286.805Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M68.1695 340.207V294.535L48.7891 283.344V259.848L89.3087 236.459L128.857 259.295L148.237 248.104L168.582 259.855V306.648L129.027 329.484V351.866L108.682 363.61L68.1695 340.207ZM88.869 351.753L108.682 363.192L128.495 351.753L108.682 340.313L88.869 351.753ZM68.7014 340.108L88.5144 351.547L108.327 340.108L88.5144 328.668L68.7014 340.108ZM108.866 340.001L128.679 351.441V329.47L108.866 318.03V340.001ZM68.5241 294.535V339.803L88.3372 328.363V283.096L68.5241 294.535ZM88.6917 328.356L108.505 339.796V294.535L88.6917 283.096V328.356ZM109.044 317.725L128.857 329.165L168.05 306.534L148.237 295.095L109.044 317.725ZM108.866 294.535V317.413L148.06 294.783V271.904L108.866 294.535ZM148.415 294.783L168.228 306.222V283.344L148.415 271.904V294.783ZM69.4886 271.592L108.682 294.223L128.495 282.784L89.3016 260.153L69.4886 271.592ZM49.3209 283.237L68.3468 294.223L88.1599 282.784L69.134 271.798L49.3209 283.237ZM49.1436 260.054V282.932L68.9567 271.493V248.615L49.1436 260.054ZM148.415 271.493L168.228 282.932V260.054L148.415 248.615V271.493ZM129.034 259.593V282.471L148.06 271.486V248.607L129.034 259.593ZM89.4789 259.841L128.679 282.471V259.593L89.4789 236.963V259.841ZM69.3113 248.402V271.28L89.1243 259.841V236.963L69.3113 248.402Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M160.685 418.014V372.341L141.305 361.15V337.655L181.824 314.266L221.372 337.102L240.753 325.911L261.098 337.662V384.454L221.543 407.291V429.673L201.198 441.417L160.685 418.014ZM181.385 429.559L201.198 440.998L221.011 429.559L201.198 418.12L181.385 429.559ZM161.217 417.914L181.03 429.354L200.843 417.914L181.03 406.475L161.217 417.914ZM201.382 417.808L221.195 429.247V407.276L201.382 395.837V417.808ZM161.04 372.341V417.609L180.853 406.17V360.902L161.04 372.341ZM181.207 406.163L201.02 417.602V372.341L181.207 360.902V406.163ZM201.559 395.532L221.372 406.971L260.566 384.341L240.753 372.902L201.559 395.532ZM201.382 372.341V395.22L240.576 372.59V349.711L201.382 372.341ZM240.93 372.59L260.743 384.029V361.15L240.93 349.711V372.59ZM162.004 349.399L201.198 372.029L221.011 360.59L181.817 337.96L162.004 349.399ZM141.837 361.044L160.862 372.029L180.676 360.59L161.65 349.605L141.837 361.044ZM141.659 337.861V360.739L161.472 349.3V326.421L141.659 337.861ZM240.93 349.3L260.743 360.739V337.861L240.93 326.421V349.3ZM221.55 337.4V360.278L240.576 349.293V326.414L221.55 337.4ZM181.994 337.648L221.195 360.278V337.4L181.994 314.769V337.648ZM161.827 326.208V349.087L181.64 337.648V314.769L161.827 326.208Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M-39.7944 379.61V333.938L-59.1748 322.747V299.252L-18.6552 275.862L20.8929 298.698L40.2734 287.507L60.6183 299.259V346.051L21.0631 368.887V391.269L0.718151 403.014L-39.7944 379.61ZM-19.0949 391.156L0.718151 402.595L20.5312 391.156L0.718151 379.717L-19.0949 391.156ZM-39.2625 379.511L-19.4494 390.95L0.363586 379.511L-19.4494 368.072L-39.2625 379.511ZM0.902531 379.405L20.7156 390.844V368.873L0.902531 357.434V379.405ZM-39.4398 333.938V379.206L-19.6267 367.767V322.499L-39.4398 333.938ZM-19.2721 367.76L0.540868 379.199V333.938L-19.2721 322.499V367.76ZM1.07981 357.129L20.8929 368.568L60.0864 345.938L40.2734 334.498L1.07981 357.129ZM0.902531 333.938V356.817L40.0961 334.186V311.308L0.902531 333.938ZM40.4506 334.186L60.2637 345.626V322.747L40.4506 311.308V334.186ZM-38.4753 310.996L0.718151 333.626L20.5312 322.187L-18.6623 299.556L-38.4753 310.996ZM-58.643 322.641L-39.6171 333.626L-19.804 322.187L-38.8299 311.201L-58.643 322.641ZM-58.8202 299.457V322.336L-39.0071 310.896V288.018L-58.8202 299.457ZM40.4506 310.896L60.2637 322.336V299.457L40.4506 288.018V310.896ZM21.0702 298.996V321.875L40.0961 310.889V288.011L21.0702 298.996ZM-18.485 299.244L20.7156 321.875V298.996L-18.485 276.366V299.244ZM-38.6526 287.805V310.684L-18.8396 299.244V276.366L-38.6526 287.805Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M572.051 373.094V327.422L552.671 316.23V292.735L593.191 269.346L632.739 292.182L652.119 280.991L672.464 292.742V339.535L632.909 362.371V384.753L612.564 396.497L572.051 373.094ZM592.751 384.639L612.564 396.079L632.377 384.639L612.564 373.2L592.751 384.639ZM572.583 372.994L592.396 384.434L612.209 372.994L592.396 361.555L572.583 372.994ZM612.748 372.888L632.561 384.327V362.356L612.748 350.917V372.888ZM572.406 327.422V372.689L592.219 361.25V315.982L572.406 327.422ZM592.574 361.243L612.387 372.682V327.422L592.574 315.982V361.243ZM612.926 350.612L632.739 362.051L671.932 339.421L652.119 327.982L612.926 350.612ZM612.748 327.422V350.3L651.942 327.67V304.791L612.748 327.422ZM652.296 327.67L672.109 339.109V316.23L652.296 304.791V327.67ZM573.37 304.479L612.564 327.11L632.377 315.67L593.183 293.04L573.37 304.479ZM553.203 316.124L572.229 327.11L592.042 315.67L573.016 304.685L553.203 316.124ZM553.025 292.941V315.819L572.839 304.38V281.501L553.025 292.941ZM652.296 304.38L672.109 315.819V292.941L652.296 281.501V304.38ZM632.916 292.48V315.358L651.942 304.373V281.494L632.916 292.48ZM593.361 292.728L632.561 315.358V292.48L593.361 269.849V292.728ZM573.193 281.289V304.167L593.006 292.728V269.849L573.193 281.289Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M376.613 339.207V293.535L357.232 282.344V258.848L397.752 235.459L437.3 258.295L456.681 247.104L477.026 258.855V305.648L437.47 328.484V350.866L417.125 362.61L376.613 339.207ZM397.312 350.753L417.125 362.192L436.938 350.753L417.125 339.313L397.312 350.753ZM377.145 339.108L396.958 350.547L416.771 339.108L396.958 327.668L377.145 339.108ZM417.31 339.001L437.123 350.441V328.47L417.31 317.03V339.001ZM376.967 293.535V338.803L396.781 327.363V282.096L376.967 293.535ZM397.135 327.356L416.948 338.796V293.535L397.135 282.096V327.356ZM417.487 316.725L437.3 328.165L476.494 305.534L456.681 294.095L417.487 316.725ZM417.31 293.535V316.413L456.503 293.783V270.904L417.31 293.535ZM456.858 293.783L476.671 305.222V282.344L456.858 270.904V293.783ZM377.932 270.592L417.125 293.223L436.938 281.784L397.745 259.153L377.932 270.592ZM357.764 282.237L376.79 293.223L396.603 281.784L377.577 270.798L357.764 282.237ZM357.587 259.054V281.932L377.4 270.493V247.615L357.587 259.054ZM456.858 270.493L476.671 281.932V259.054L456.858 247.615V270.493ZM437.477 258.593V281.471L456.503 270.486V247.607L437.477 258.593ZM397.922 258.841L437.123 281.471V258.593L397.922 235.963V258.841ZM377.755 247.402V270.28L397.568 258.841V235.963L377.755 247.402Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M140.983 531.735V486.056L121.603 474.865V451.37L162.122 427.98L201.67 450.817L221.051 439.625L241.396 451.377V498.169L201.84 521.005V543.387L181.495 555.132L140.983 531.735ZM161.682 543.281L181.495 554.72L201.309 543.281L181.495 531.842L161.682 543.281ZM141.515 531.636L161.328 543.075L181.141 531.636L161.328 520.197L141.515 531.636ZM181.673 531.53L201.486 542.969V520.998L181.673 509.559V531.53ZM141.338 486.063V531.331L161.151 519.892V474.631L141.338 486.063ZM161.505 519.885L181.318 531.324V486.063L161.505 474.624V519.885ZM181.85 509.247L201.663 520.686L240.857 498.056L221.044 486.617L181.85 509.247ZM181.673 486.063V508.942L220.866 486.312V463.433L181.673 486.063ZM221.228 486.312L241.041 497.751V474.872L221.228 463.433V486.312ZM142.302 463.121L181.495 485.751L201.309 474.312L162.115 451.682L142.302 463.121ZM122.134 474.766L141.16 485.751L160.973 474.312L141.947 463.327L122.134 474.766ZM121.95 451.575V474.454L141.763 463.015V440.136L121.95 451.575ZM221.228 463.022L241.041 474.461V451.582L221.228 440.143V463.022ZM201.84 451.121V474L220.866 463.015V440.136L201.84 451.121ZM162.292 451.37L201.493 474V451.121L162.292 428.491V451.37ZM142.118 439.93V462.809L161.931 451.37V428.491L142.118 439.93Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M-59.4965 493.332V447.653L-78.877 436.462V412.966L-38.3574 389.577L1.19071 412.413L20.5712 401.222L40.9161 412.973V459.766L1.36089 482.602V504.984L-18.984 516.728L-59.4965 493.332ZM-38.797 504.878L-18.984 516.317L0.829044 504.878L-18.984 493.439L-38.797 504.878ZM-58.9647 493.233L-39.1516 504.672L-19.3386 493.233L-39.1516 481.794L-58.9647 493.233ZM-18.8067 493.126L1.00633 504.566V482.595L-18.8067 471.156V493.126ZM-59.1419 447.66V492.928L-39.3289 481.489V436.228L-59.1419 447.66ZM-38.9743 481.482L-19.1613 492.921V447.66L-38.9743 436.221V481.482ZM-18.6294 470.844L1.18361 482.283L40.3771 459.653L20.5641 448.213L-18.6294 470.844ZM-18.8067 447.66V470.539L20.3868 447.908V425.03L-18.8067 447.66ZM20.7485 447.908L40.5615 459.348V436.469L20.7485 425.03V447.908ZM-58.1775 424.718L-18.984 447.348L0.829044 435.909L-38.3644 413.278L-58.1775 424.718ZM-78.3451 436.363L-59.3192 447.348L-39.5062 435.909L-58.5321 424.923L-78.3451 436.363ZM-78.5295 413.172V436.051L-58.7164 424.611V401.733L-78.5295 413.172ZM20.7485 424.618L40.5615 436.058V413.179L20.7485 401.74V424.618ZM1.36089 412.718V435.597L20.3868 424.611V401.733L1.36089 412.718ZM-38.1872 412.966L1.01342 435.597V412.718L-38.1872 390.088V412.966ZM-58.3619 401.527V424.406L-38.5488 412.966V390.088L-58.3619 401.527Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M120.494 642.616V596.937L101.113 585.746V562.251L141.633 538.861L181.181 561.697L200.561 550.506L220.906 562.258V609.05L181.351 631.886V654.268L161.006 666.013L120.494 642.616ZM141.193 654.162L161.006 665.601L180.819 654.162L161.006 642.723L141.193 654.162ZM121.026 642.517L140.839 653.956L160.652 642.517L140.839 631.078L121.026 642.517ZM161.184 642.411L180.997 653.85V631.879L161.184 620.44V642.411ZM120.848 596.944V642.212L140.661 630.773V585.512L120.848 596.944ZM141.016 630.766L160.829 642.205V596.944L141.016 585.505V630.766ZM161.361 620.128L181.174 631.567L220.367 608.937L200.554 597.497L161.361 620.128ZM161.184 596.944V619.823L200.377 597.192V574.314L161.184 596.944ZM200.739 597.192L220.552 608.632V585.753L200.739 574.314V597.192ZM121.813 574.002L161.006 596.632L180.819 585.193L141.626 562.563L121.813 574.002ZM101.645 585.647L120.671 596.632L140.484 585.193L121.458 574.208L101.645 585.647ZM101.461 562.456V585.335L121.274 573.895V551.017L101.461 562.456ZM200.739 573.903L220.552 585.342V562.463L200.739 551.024V573.903ZM181.351 562.002V584.881L200.377 573.895V551.017L181.351 562.002ZM141.803 562.251L181.004 584.881V562.002L141.803 539.372V562.251ZM121.628 550.811V573.69L141.441 562.251V539.372L121.628 550.811Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M-79.9858 604.214V558.535L-99.3662 547.344V523.848L-58.8467 500.459L-19.2986 523.295L0.0819378 512.104L20.4268 523.855V570.648L-19.1284 593.484V615.866L-39.4733 627.61L-79.9858 604.214ZM-59.2863 615.76L-39.4733 627.199L-19.6602 615.76L-39.4733 604.32L-59.2863 615.76ZM-79.4539 604.115L-59.6409 615.554L-39.8278 604.115L-59.6409 592.675L-79.4539 604.115ZM-39.296 604.008L-19.4829 615.448V593.477L-39.296 582.038V604.008ZM-79.6312 558.542V603.81L-59.8182 592.37V547.11L-79.6312 558.542ZM-59.4636 592.363L-39.6505 603.803V558.542L-59.4636 547.103V592.363ZM-39.1187 581.725L-19.3056 593.165L19.8879 570.534L0.0748396 559.095L-39.1187 581.725ZM-39.296 558.542V581.42L-0.102443 558.79V535.912L-39.296 558.542ZM0.25922 558.79L20.0723 570.229V547.351L0.25922 535.912V558.79ZM-78.6668 535.6L-39.4733 558.23L-19.6602 546.791L-58.8537 524.16L-78.6668 535.6ZM-98.8344 547.244L-79.8085 558.23L-59.9954 546.791L-79.0213 535.805L-98.8344 547.244ZM-99.0187 524.054V546.932L-79.2057 535.493V512.615L-99.0187 524.054ZM0.25922 535.5L20.0723 546.94V524.061L0.25922 512.622V535.5ZM-19.1284 523.6V546.479L-0.102443 535.493V512.615L-19.1284 523.6ZM-58.6764 523.848L-19.4758 546.479V523.6L-58.6764 500.97V523.848ZM-78.8511 512.409V535.287L-59.0381 523.848V500.97L-78.8511 512.409Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M228.383 604.661V558.982L209.003 547.791V524.295L249.522 500.906L289.071 523.742L308.451 512.551L328.796 524.295V571.088L289.241 593.924V616.306L268.896 628.05L228.383 604.661ZM249.09 616.207L268.903 627.646L288.716 616.207L268.903 604.768L249.09 616.207ZM228.922 604.562L248.735 616.001L268.548 604.562L248.735 593.123L228.922 604.562ZM269.08 604.456L288.893 615.895V593.924L269.08 582.485V604.456ZM228.738 558.989V604.25L248.551 592.811V547.543L228.738 558.989ZM248.913 592.811L268.726 604.25V558.989L248.913 547.55V592.811ZM269.258 582.18L289.071 593.619L328.264 570.989L308.451 559.549L269.258 582.18ZM269.08 558.989V581.868L308.274 559.237V536.359L269.08 558.989ZM308.635 559.237L328.449 570.677V547.798L308.635 536.359V559.237ZM229.709 536.047L268.903 558.677L288.716 547.238L249.522 524.607L229.709 536.047ZM209.535 547.692L228.561 558.677L248.374 547.238L229.348 536.252L209.535 547.692ZM209.357 524.501V547.38L229.171 535.94V513.062L209.357 524.501ZM308.635 535.947L328.449 547.387V524.508L308.635 513.069V535.947ZM289.248 524.047V546.926L308.274 535.94V513.062L289.248 524.047ZM249.7 524.295L288.9 546.926V524.047L249.7 501.417V524.295ZM229.525 512.856V535.735L249.338 524.295V501.417L229.525 512.856Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M27.9039 566.258V520.579L8.52344 509.388V485.892L49.043 462.503L88.5911 485.339L107.972 474.148L128.316 485.892V532.685L88.7613 555.521V577.903L68.4164 589.647L27.9039 566.258ZM48.6104 577.804L68.4235 589.243L88.2365 577.804L68.4235 566.364L48.6104 577.804ZM28.4428 566.159L48.2559 577.598L68.0689 566.159L48.2559 554.719L28.4428 566.159ZM68.6008 566.052L88.4138 577.492V555.521L68.6008 544.081V566.052ZM28.2584 520.586V565.847L48.0715 554.407V509.139L28.2584 520.586ZM48.4332 554.407L68.2462 565.847V520.586L48.4332 509.147V554.407ZM68.7781 543.776L88.5911 555.216L127.785 532.585L107.972 521.146L68.7781 543.776ZM68.6008 520.586V543.464L107.794 520.834V497.956L68.6008 520.586ZM108.156 520.834L127.969 532.273V509.395L108.156 497.956V520.834ZM29.23 497.643L68.4235 520.274L88.2365 508.835L49.043 486.204L29.23 497.643ZM9.05528 509.288L28.0812 520.274L47.8942 508.835L28.8683 497.849L9.05528 509.288ZM8.878 486.098V508.976L28.691 497.537V474.659L8.878 486.098ZM108.156 497.544L127.969 508.983V486.105L108.156 474.666V497.544ZM88.7684 485.644V508.523L107.794 497.537V474.659L88.7684 485.644ZM49.2203 485.892L88.4209 508.523V485.644L49.2203 463.014V485.892ZM29.0456 474.453V497.331L48.8587 485.892V463.014L29.0456 474.453Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M336.148 565.549V519.87L316.768 508.679V485.183L357.287 461.794L396.842 484.63L416.223 473.439L436.568 485.19V531.983L397.013 554.819V577.201L376.668 588.945L336.148 565.549ZM356.847 577.088L376.661 588.527L396.474 577.088L376.661 565.648L356.847 577.088ZM336.68 565.443L356.493 576.882L376.306 565.443L356.493 554.003L336.68 565.443ZM376.838 565.343L396.651 576.783V554.812L376.838 543.372V565.343ZM336.503 519.87V565.138L356.316 553.698V508.431L336.503 519.87ZM356.67 553.691L376.483 565.131V519.87L356.67 508.431V553.691ZM377.015 543.06L396.828 554.5L436.022 531.869L416.209 520.43L377.015 543.06ZM376.838 519.87V542.748L416.031 520.118V497.239L376.838 519.87ZM416.393 520.118L436.206 531.557V508.679L416.393 497.239V520.118ZM317.299 508.579L336.325 519.565L356.138 508.126L337.112 497.14L317.299 508.579ZM337.467 496.934L376.661 519.565L396.474 508.126L357.28 485.495L337.467 496.934ZM317.122 485.389V508.267L336.935 496.828V473.95L317.122 485.389ZM416.393 496.828L436.206 508.267V485.389L416.393 473.95V496.828ZM397.013 484.935V507.814L416.038 496.828V473.95L397.013 484.935ZM357.457 485.183L396.658 507.814V484.935L357.457 462.305V485.183ZM337.29 473.744V496.622L357.103 485.183V462.305L337.29 473.744Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M248.699 492.502V446.823L229.318 435.632V412.129L269.838 388.74L309.386 411.576L328.774 400.385L349.119 412.137V458.929L309.563 481.765V504.147L289.218 515.892L248.699 492.502ZM269.398 504.048L289.211 515.487L309.024 504.048L289.211 492.609L269.398 504.048ZM249.231 492.403L269.044 503.842L288.857 492.403L269.044 480.964L249.231 492.403ZM289.389 492.297L309.202 503.736V481.765L289.389 470.326V492.297ZM249.053 446.83V492.098L268.866 480.659V435.384L249.053 446.83ZM269.221 480.652L289.034 492.091V446.83L269.221 435.391V480.652ZM289.566 470.014L309.379 481.453L348.573 458.823L328.759 447.383L289.566 470.014ZM289.389 446.83V469.709L328.582 447.078V424.2L289.389 446.83ZM328.944 447.078L348.757 458.518V435.639L328.944 424.2V447.078ZM250.018 423.888L289.211 446.518L309.024 435.079L269.831 412.449L250.018 423.888ZM229.85 435.533L248.876 446.518L268.689 435.079L249.663 424.094L229.85 435.533ZM229.673 412.342V435.221L249.486 423.781V400.903L229.673 412.342ZM328.944 423.781L348.757 435.221V412.342L328.944 400.903V423.781ZM309.563 411.888V434.767L328.589 423.781V400.903L309.563 411.888ZM270.008 412.137L309.209 434.767V411.888L270.008 389.258V412.137ZM249.841 400.697V423.576L269.654 412.137V389.258L249.841 400.697Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M48.2203 454.099V408.42L28.8398 397.229V373.726L69.3594 350.337L108.908 373.173L128.295 361.982L148.64 373.733V420.526L109.085 443.362V465.744L88.7399 477.488L48.2203 454.099ZM68.9198 465.645L88.7328 477.084L108.546 465.645L88.7328 454.205L68.9198 465.645ZM48.7522 454L68.5652 465.439L88.3783 454L68.5652 442.56L48.7522 454ZM88.9101 453.893L108.723 465.333V443.362L88.9101 431.923V453.893ZM48.5749 408.427V453.695L68.3879 442.255V396.981L48.5749 408.427ZM68.7425 442.248L88.5556 453.688V408.427L68.7425 396.988V442.248ZM89.0874 431.61L108.9 443.05L148.094 420.419L128.281 408.98L89.0874 431.61ZM88.9101 408.427V431.306L128.104 408.675V385.797L88.9101 408.427ZM128.465 408.675L148.278 420.114V397.236L128.465 385.797V408.675ZM49.5393 385.485L88.7328 408.115L108.546 396.676L69.3523 374.045L49.5393 385.485ZM29.3717 397.129L48.3976 408.115L68.2107 396.676L49.1847 385.69L29.3717 397.129ZM29.1944 373.939V396.817L49.0075 385.378V362.5L29.1944 373.939ZM128.465 385.378L148.278 396.817V373.939L128.465 362.5V385.378ZM109.085 373.485V396.364L128.111 385.378V362.5L109.085 373.485ZM69.5296 373.733L108.73 396.364V373.485L69.5296 350.855V373.733ZM49.362 362.294V385.172L69.1751 373.733V350.855L49.362 362.294Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M80.3023 867.213V821.534L60.9219 810.343V786.847L101.441 763.458L140.99 786.294L160.37 775.103L180.715 786.854V833.647L141.16 856.483V878.865L120.815 890.609L80.3023 867.213ZM101.002 878.759L120.815 890.198L140.628 878.759L120.815 867.319L101.002 878.759ZM80.8342 867.114L100.647 878.553L120.46 867.114L100.647 855.674L80.8342 867.114ZM120.992 867.007L140.805 878.447V856.476L120.992 845.037V867.007ZM80.6569 821.541V866.809L100.47 855.369V810.109L80.6569 821.541ZM100.824 855.362L120.638 866.802V821.541L100.824 810.102V855.362ZM121.169 844.724L140.982 856.164L180.176 833.533L160.363 822.094L121.169 844.724ZM120.992 821.541V844.42L160.186 821.789V798.911L120.992 821.541ZM160.547 821.789L180.36 833.228V810.35L160.547 798.911V821.789ZM81.6213 798.599L120.815 821.229L140.628 809.79L101.434 787.159L81.6213 798.599ZM61.4537 810.243L80.4796 821.229L100.293 809.79L81.2668 798.804L61.4537 810.243ZM61.2693 787.053V809.931L81.0824 798.492V775.614L61.2693 787.053ZM160.547 798.499L180.36 809.939V787.06L160.547 775.621V798.499ZM141.16 786.599V809.478L160.186 798.492V775.614L141.16 786.599ZM101.612 786.847L140.812 809.478V786.599L101.612 763.969V786.847ZM81.437 775.408V798.287L101.25 786.847V763.969L81.437 775.408Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M59.025 979.905V934.226L39.6445 923.035V899.54L80.1641 876.15L119.712 898.986L139.093 887.795L159.438 899.547V946.339L119.882 969.175V991.557L99.5375 1003.3L59.025 979.905ZM79.7244 991.451L99.5375 1002.89L119.351 991.451L99.5375 980.012L79.7244 991.451ZM59.5568 979.806L79.3699 991.245L99.1829 979.806L79.3699 968.367L59.5568 979.806ZM99.7148 979.7L119.528 991.139V969.168L99.7148 957.729V979.7ZM59.3795 934.233V979.501L79.1926 968.062V922.801L59.3795 934.233ZM79.5472 968.055L99.3602 979.494V934.233L79.5472 922.794V968.055ZM99.892 957.417L119.705 968.856L158.899 946.226L139.086 934.786L99.892 957.417ZM99.7148 934.233V957.112L138.908 934.482V911.603L99.7148 934.233ZM139.27 934.482L159.083 945.921V923.042L139.27 911.603V934.482ZM60.344 911.291L99.5375 933.921L119.351 922.482L80.157 899.852L60.344 911.291ZM40.1764 922.936L59.2023 933.921L79.0153 922.482L59.9894 911.497L40.1764 922.936ZM39.992 899.745V922.624L59.805 911.185V888.306L39.992 899.745ZM139.27 911.192L159.083 922.631V899.752L139.27 888.313V911.192ZM119.882 899.291V922.17L138.908 911.185V888.306L119.882 899.291ZM80.3343 899.54L119.535 922.17V899.291L80.3343 876.661V899.54ZM60.1596 888.1V910.979L79.9727 899.54V876.661L60.1596 888.1Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M39.0142 1093.75V1048.08L19.6338 1036.88V1013.39L60.1533 990L99.7014 1012.84L119.082 1001.64L139.427 1013.4V1060.19L99.8716 1083.02V1105.41L79.5267 1117.15L39.0142 1093.75ZM59.7137 1105.3L79.5267 1116.74L99.3398 1105.3L79.5267 1093.86L59.7137 1105.3ZM39.5461 1093.66L59.3591 1105.09L79.1722 1093.66L59.3591 1082.22L39.5461 1093.66ZM79.704 1093.55L99.5171 1104.99V1083.02L79.704 1071.58V1093.55ZM39.3688 1048.08V1093.35L59.1818 1081.91V1036.65L39.3688 1048.08ZM59.5364 1081.9L79.3495 1093.34V1048.08L59.5364 1036.64V1081.9ZM79.8813 1071.27L99.6944 1082.71L138.888 1060.08L119.075 1048.64L79.8813 1071.27ZM79.704 1048.08V1070.96L118.898 1048.33V1025.45L79.704 1048.08ZM119.259 1048.33L139.072 1059.77V1036.89L119.259 1025.45V1048.33ZM40.3332 1025.14L79.5267 1047.77L99.3398 1036.33L60.1463 1013.7L40.3332 1025.14ZM20.1656 1036.79L39.1915 1047.77L59.0046 1036.33L39.9787 1025.35L20.1656 1036.79ZM19.9813 1013.59V1036.47L39.7943 1025.03V1002.16L19.9813 1013.59ZM119.259 1025.04L139.072 1036.48V1013.6L119.259 1002.16V1025.04ZM99.8716 1013.14V1036.02L118.898 1025.03V1002.16L99.8716 1013.14ZM60.3236 1013.39L99.5242 1036.02V1013.14L60.3236 990.511V1013.39ZM40.1489 1001.95V1024.83L59.9619 1013.39V990.511L40.1489 1001.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M659.627 447.172V401.5L640.246 390.309V366.813L680.766 343.424L720.314 366.26L739.694 355.069L760.039 366.82V413.613L720.484 436.449V458.831L700.139 470.575L659.627 447.172ZM680.326 458.717L700.139 470.157L719.952 458.717L700.139 447.278L680.326 458.717ZM660.158 447.072L679.971 458.512L699.784 447.072L679.971 435.633L660.158 447.072ZM700.323 446.966L720.137 458.405V436.435L700.323 424.995V446.966ZM659.981 401.5V446.767L679.794 435.328V390.06L659.981 401.5ZM680.149 435.321L699.962 446.76V401.5L680.149 390.06V435.321ZM700.501 424.69L720.314 436.13L759.507 413.499L739.694 402.06L700.501 424.69ZM700.323 401.5V424.378L739.517 401.748V378.869L700.323 401.5ZM739.872 401.748L759.685 413.187V390.309L739.872 378.869V401.748ZM660.946 378.557L700.139 401.188L719.952 389.748L680.759 367.118L660.946 378.557ZM640.778 390.202L659.804 401.188L679.617 389.748L660.591 378.763L640.778 390.202ZM640.601 367.019V389.897L660.414 378.458V355.579L640.601 367.019ZM739.872 378.458L759.685 389.897V367.019L739.872 355.579V378.458ZM720.491 366.558V389.436L739.517 378.451V355.572L720.491 366.558ZM680.936 366.806L720.137 389.436V366.558L680.936 343.927V366.806ZM660.768 355.367V378.245L680.581 366.806V343.927L660.768 355.367Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M464.087 412.496V366.824L444.707 355.633V332.137L485.227 308.748L524.775 331.584L544.155 320.393L564.5 332.144V378.937L524.945 401.773V424.155L504.6 435.899L464.087 412.496ZM484.787 424.042L504.6 435.481L524.413 424.042L504.6 412.602L484.787 424.042ZM464.619 412.397L484.432 423.836L504.245 412.397L484.432 400.957L464.619 412.397ZM504.784 412.29L524.597 423.73V401.759L504.784 390.319V412.29ZM464.442 366.824V412.092L484.255 400.652V355.385L464.442 366.824ZM484.61 400.645L504.423 412.085V366.824L484.61 355.385V400.645ZM504.962 390.015L524.775 401.454L563.968 378.823L544.155 367.384L504.962 390.015ZM504.784 366.824V389.702L543.978 367.072V344.194L504.784 366.824ZM544.332 367.072L564.146 378.511V355.633L544.332 344.194V367.072ZM465.407 343.881L504.6 366.512L524.413 355.073L485.22 332.442L465.407 343.881ZM445.239 355.526L464.265 366.512L484.078 355.073L465.052 344.087L445.239 355.526ZM445.062 332.343V355.222L464.875 343.782V320.904L445.062 332.343ZM544.332 343.782L564.146 355.222V332.343L544.332 320.904V343.782ZM524.952 331.882V354.761L543.978 343.775V320.897L524.952 331.882ZM485.397 332.13L524.597 354.761V331.882L485.397 309.252V332.13ZM465.229 320.691V343.569L485.042 332.13V309.252L465.229 320.691Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M705.233 745.057V699.384L685.853 688.193V664.698L726.372 641.309L765.92 664.145L785.301 652.954L805.646 664.705V711.497L766.09 734.334V756.716L745.745 768.46L705.233 745.057ZM725.932 756.602L745.745 768.041L765.559 756.602L745.745 745.163L725.932 756.602ZM705.765 744.957L725.578 756.397L745.391 744.957L725.578 733.518L705.765 744.957ZM745.93 744.851L765.743 756.29V734.319L745.93 722.88V744.851ZM705.588 699.384V744.652L725.401 733.213V687.945L705.588 699.384ZM725.755 733.206L745.568 744.645V699.384L725.755 687.945V733.206ZM746.107 722.575L765.92 734.014L805.114 711.384L785.301 699.945L746.107 722.575ZM745.93 699.384V722.263L785.123 699.633V676.754L745.93 699.384ZM785.478 699.633L805.291 711.072V688.193L785.478 676.754V699.633ZM706.552 676.442L745.745 699.072L765.559 687.633L726.365 665.003L706.552 676.442ZM686.384 688.087L705.41 699.072L725.223 687.633L706.197 676.648L686.384 688.087ZM686.207 664.903V687.782L706.02 676.343V653.464L686.207 664.903ZM785.478 676.343L805.291 687.782V664.903L785.478 653.464V676.343ZM766.098 664.443V687.321L785.123 676.336V653.457L766.098 664.443ZM726.542 664.691L765.743 687.321V664.443L726.542 641.812V664.691ZM706.375 653.251V676.13L726.188 664.691V641.812L706.375 653.251Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M725.722 633.152V587.48L706.342 576.289V552.794L746.861 529.404L786.41 552.24L805.79 541.049L826.135 552.801V599.593L786.58 622.429V644.811L766.235 656.556L725.722 633.152ZM746.422 644.698L766.235 656.137L786.048 644.698L766.235 633.259L746.422 644.698ZM726.254 633.053L746.067 644.492L765.88 633.053L746.067 621.614L726.254 633.053ZM766.419 632.947L786.232 644.386V622.415L766.419 610.976V632.947ZM726.077 587.48V632.748L745.89 621.309V576.041L726.077 587.48ZM746.244 621.302L766.057 632.741V587.48L746.244 576.041V621.302ZM766.596 610.671L786.41 622.11L825.603 599.48L805.79 588.04L766.596 610.671ZM766.419 587.48V610.359L805.613 587.728V564.85L766.419 587.48ZM805.967 587.728L825.78 599.168V576.289L805.967 564.85V587.728ZM727.041 564.538L766.235 587.168L786.048 575.729L746.854 553.098L727.041 564.538ZM706.874 576.183L725.9 587.168L745.713 575.729L726.687 564.743L706.874 576.183ZM706.696 552.999V575.878L726.509 564.438V541.56L706.696 552.999ZM805.967 564.438L825.78 575.878V552.999L805.967 541.56V564.438ZM786.587 552.538V575.417L805.613 564.431V541.553L786.587 552.538ZM747.032 552.786L786.232 575.417V552.538L747.032 529.908V552.786ZM726.864 541.347V564.226L746.677 552.786V529.908L726.864 541.347Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M747 522.037V476.365L727.619 465.174V441.678L768.139 418.289L807.687 441.125L827.067 429.934L847.412 441.685V488.478L807.857 511.314V533.696L787.512 545.44L747 522.037ZM767.699 533.583L787.512 545.022L807.325 533.583L787.512 522.143L767.699 533.583ZM747.531 521.938L767.345 533.377L787.158 521.938L767.345 510.498L747.531 521.938ZM787.696 521.831L807.51 533.271V511.3L787.696 499.861V521.831ZM747.354 476.365V521.633L767.167 510.193V464.926L747.354 476.365ZM767.522 510.186L787.335 521.626V476.365L767.522 464.926V510.186ZM787.874 499.556L807.687 510.995L846.88 488.364L827.067 476.925L787.874 499.556ZM787.696 476.365V499.244L826.89 476.613V453.735L787.696 476.365ZM827.245 476.613L847.058 488.052V465.174L827.245 453.735V476.613ZM748.319 453.423L787.512 476.053L807.325 464.614L768.132 441.983L748.319 453.423ZM728.151 465.067L747.177 476.053L766.99 464.614L747.964 453.628L728.151 465.067ZM727.974 441.884V464.763L747.787 453.323V430.445L727.974 441.884ZM827.245 453.323L847.058 464.763V441.884L827.245 430.445V453.323ZM807.864 441.423V464.302L826.89 453.316V430.438L807.864 441.423ZM768.309 441.671L807.51 464.302V441.423L768.309 418.793V441.671ZM748.141 430.232V453.11L767.954 441.671V418.793L748.141 430.232Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M444.385 526.218V480.539L425.005 469.348V445.852L465.524 422.463L505.073 445.299L524.453 434.108L544.798 445.859V492.652L505.243 515.488V537.87L484.898 549.614L444.385 526.218ZM465.085 537.764L484.898 549.203L504.711 537.764L484.898 526.324L465.085 537.764ZM444.917 526.119L464.73 537.558L484.543 526.119L464.73 514.679L444.917 526.119ZM485.075 526.012L504.888 537.452V515.481L485.075 504.041V526.012ZM444.74 480.546V525.814L464.553 514.374V469.114L444.74 480.546ZM464.908 514.367L484.721 525.807V480.546L464.908 469.107V514.367ZM485.252 503.729L505.065 515.169L544.259 492.538L524.446 481.099L485.252 503.729ZM485.075 480.546V503.424L524.269 480.794V457.915L485.075 480.546ZM524.63 480.794L544.443 492.233V469.355L524.63 457.915V480.794ZM445.704 457.603L484.898 480.234L504.711 468.795L465.517 446.164L445.704 457.603ZM425.537 469.248L444.563 480.234L464.376 468.795L445.35 457.809L425.537 469.248ZM425.352 446.058V468.936L445.165 457.497V434.618L425.352 446.058ZM524.63 457.504L544.443 468.943V446.065L524.63 434.626V457.504ZM505.243 445.604V468.482L524.269 457.497V434.618L505.243 445.604ZM465.695 445.852L504.895 468.482V445.604L465.695 422.974V445.852ZM445.52 434.413V457.291L465.333 445.852V422.974L445.52 434.413Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M423.108 638.677V592.998L403.728 581.807V558.311L444.247 534.922L483.795 557.758L503.176 546.567L523.521 558.318V605.111L483.965 627.947V650.329L463.62 662.073L423.108 638.677ZM443.807 650.223L463.62 661.662L483.434 650.223L463.62 638.783L443.807 650.223ZM423.64 638.578L443.453 650.017L463.266 638.578L443.453 627.138L423.64 638.578ZM463.798 638.471L483.611 649.911V627.94L463.798 616.5V638.471ZM423.463 593.005V638.273L443.276 626.833V581.573L423.463 593.005ZM443.63 626.826L463.443 638.266V593.005L443.63 581.566V626.826ZM463.975 616.188L483.788 627.628L522.982 604.997L503.169 593.558L463.975 616.188ZM463.798 593.005V615.883L502.991 593.253V570.374L463.798 593.005ZM503.353 593.253L523.166 604.692V581.814L503.353 570.374V593.253ZM424.427 570.062L463.62 592.693L483.434 581.253L444.24 558.623L424.427 570.062ZM404.259 581.707L423.285 592.693L443.098 581.253L424.072 570.268L404.259 581.707ZM404.075 558.517V581.395L423.888 569.956V547.077L404.075 558.517ZM503.353 569.963L523.166 581.402V558.524L503.353 547.085V569.963ZM483.965 558.063V580.941L502.991 569.956V547.077L483.965 558.063ZM444.417 558.311L483.618 580.941V558.063L444.417 535.432V558.311ZM424.243 546.872V569.75L444.056 558.311V535.432L424.243 546.872Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M531.786 599.145V553.466L512.405 542.274V518.779L552.925 495.39L592.473 518.226L611.853 507.035L632.198 518.779V565.571L592.643 588.407V610.79L572.298 622.534L531.786 599.145ZM552.492 610.69L572.305 622.13L592.118 610.69L572.305 599.251L552.492 610.69ZM532.325 599.045L552.138 610.485L571.951 599.045L552.138 587.606L532.325 599.045ZM572.483 598.939L592.296 610.378V588.407L572.483 576.968V598.939ZM532.14 553.473V598.733L551.953 587.294V542.026L532.14 553.473ZM552.315 587.294L572.128 598.733V553.473L552.315 542.033V587.294ZM572.66 576.663L592.473 588.102L631.666 565.472L611.853 554.033L572.66 576.663ZM572.483 553.473V576.351L611.676 553.721V530.842L572.483 553.473ZM612.038 553.721L631.851 565.16V542.282L612.038 530.842V553.721ZM533.112 530.53L572.305 553.161L592.118 541.721L552.925 519.091L533.112 530.53ZM512.937 542.175L531.963 553.161L551.776 541.721L532.75 530.736L512.937 542.175ZM512.76 518.984V541.863L532.573 530.424V507.545L512.76 518.984ZM612.038 530.431L631.851 541.87V518.992L612.038 507.552V530.431ZM592.65 518.531V541.409L611.676 530.424V507.545L592.65 518.531ZM553.102 518.779L592.303 541.409V518.531L553.102 495.9V518.779ZM532.927 507.34V530.218L552.74 518.779V495.9L532.927 507.34Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M639.55 560.032V514.353L620.17 503.162V479.667L660.689 456.277L700.245 479.113L719.625 467.922L739.97 479.674V526.466L700.415 549.302V571.684L680.07 583.429L639.55 560.032ZM660.25 571.571L680.063 583.01L699.876 571.571L680.063 560.132L660.25 571.571ZM640.082 559.926L659.895 571.365L679.708 559.926L659.895 548.487L640.082 559.926ZM680.24 559.827L700.053 571.266V549.295L680.24 537.856V559.827ZM639.905 514.353V559.621L659.718 548.182V502.914L639.905 514.353ZM660.073 548.175L679.886 559.614V514.353L660.073 502.914V548.175ZM680.417 537.544L700.23 548.983L739.424 526.353L719.611 514.913L680.417 537.544ZM680.24 514.353V537.232L719.434 514.601V491.723L680.24 514.353ZM719.795 514.601L739.608 526.041V503.162L719.795 491.723V514.601ZM620.702 503.063L639.728 514.048L659.541 502.609L640.515 491.624L620.702 503.063ZM640.869 491.418L680.063 514.048L699.876 502.609L660.682 479.979L640.869 491.418ZM620.524 479.872V502.751L640.338 491.312V468.433L620.524 479.872ZM719.795 491.312L739.608 502.751V479.872L719.795 468.433V491.312ZM700.415 479.418V502.297L719.441 491.312V468.433L700.415 479.418ZM660.86 479.667L700.06 502.297V479.418L660.86 456.788V479.667ZM640.692 468.227V491.106L660.505 479.667V456.788L640.692 468.227Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M19.3805 1204.51V1159.02L0 1147.87V1124.47L40.5196 1101.17L80.0677 1123.92L99.4482 1112.77L119.793 1124.47V1171.07L80.2379 1193.81V1216.11L59.893 1227.81L19.3805 1204.51ZM40.0799 1216.01L59.9001 1227.4L79.7202 1216.01L59.9001 1204.61L40.0799 1216.01ZM19.9052 1204.41L39.7254 1215.81L59.5455 1204.41L39.7254 1193.02L19.9052 1204.41ZM60.0774 1204.31L79.8904 1215.71V1193.82L60.0774 1182.42V1204.31ZM19.7351 1159.02V1204.11L39.5481 1192.71V1147.62L19.7351 1159.02ZM39.9027 1192.71L59.7157 1204.11V1159.02L39.9027 1147.62V1192.71ZM60.2476 1182.12L80.0677 1193.51L119.268 1170.97L99.4482 1159.57L60.2476 1182.12ZM60.0774 1159.02V1181.82L99.2709 1159.27V1136.47L60.0774 1159.02ZM99.6255 1159.27L119.439 1170.67V1147.88L99.6255 1136.48V1159.27ZM0.524791 1147.77L19.5578 1158.72L39.3779 1147.32L20.3449 1136.38L0.524791 1147.77ZM20.6924 1136.17L59.893 1158.72L79.7131 1147.32L40.5125 1124.78L20.6924 1136.17ZM99.6255 1136.07L119.439 1147.47V1124.67L99.6255 1113.27V1136.07ZM0.354564 1124.67V1147.47L20.1676 1136.07V1113.28L0.354564 1124.67ZM80.245 1124.22V1147.01L99.2709 1136.07V1113.27L80.245 1124.22ZM40.6898 1124.47L79.8904 1147.01V1124.22L40.6898 1101.68V1124.47ZM20.5222 1113.07V1135.87L40.3352 1124.47V1101.68L20.5222 1113.07Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M-0.98572 1320.51V1275.02L-20.3662 1263.87V1240.47L20.1534 1217.17L59.7015 1239.92L79.082 1228.77L99.4269 1240.47V1287.07L59.8717 1309.81V1332.11L39.5268 1343.81L-0.98572 1320.51ZM19.7137 1332.01L39.5339 1343.4L59.354 1332.01L39.5339 1320.61L19.7137 1332.01ZM-0.460972 1320.41L19.3592 1331.81L39.1793 1320.41L19.3592 1309.02L-0.460972 1320.41ZM39.7112 1320.31L59.5242 1331.71V1309.82L39.7112 1298.42V1320.31ZM-0.631156 1275.02V1320.11L19.1819 1308.71V1263.62L-0.631156 1275.02ZM19.5365 1308.71L39.3495 1320.11V1275.02L19.5365 1263.62V1308.71ZM39.8814 1298.12L59.7015 1309.51L98.9021 1286.97L79.082 1275.57L39.8814 1298.12ZM39.7112 1275.02V1297.82L78.9047 1275.27V1252.47L39.7112 1275.02ZM79.2593 1275.27L99.0723 1286.67V1263.88L79.2593 1252.48V1275.27ZM-19.8414 1263.77L-0.808438 1274.72L19.0117 1263.32L-0.021316 1252.38L-19.8414 1263.77ZM0.326193 1252.17L39.5268 1274.72L59.3469 1263.32L20.1463 1240.78L0.326193 1252.17ZM79.2593 1252.07L99.0723 1263.47V1240.67L79.2593 1229.27V1252.07ZM-20.0116 1240.67V1263.47L-0.198598 1252.07V1229.28L-20.0116 1240.67ZM59.8788 1240.22V1263.01L78.9047 1252.07V1229.27L59.8788 1240.22ZM20.3236 1240.47L59.5242 1263.01V1240.22L20.3236 1217.68V1240.47ZM0.155966 1229.07V1251.87L19.969 1240.47V1217.68L0.155966 1229.07Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M322.014 1204.4V1158.9L302.634 1147.76V1124.36L343.153 1101.06L382.701 1123.81L402.082 1112.66L422.427 1124.36V1170.95L382.872 1193.7V1215.99L362.527 1227.7L322.014 1204.4ZM342.714 1215.9L362.534 1227.29L382.354 1215.9L362.534 1204.5L342.714 1215.9ZM322.539 1204.3L342.359 1215.7L362.179 1204.3L342.359 1192.9L322.539 1204.3ZM362.711 1204.2L382.524 1215.6V1193.7L362.711 1182.31V1204.2ZM322.369 1158.9V1203.99L342.182 1192.6V1147.51L322.369 1158.9ZM342.536 1192.6L362.35 1203.99V1158.9L342.536 1147.51V1192.6ZM362.881 1182L382.701 1193.4L421.902 1170.85L402.082 1159.46L362.881 1182ZM362.711 1158.9V1181.7L401.905 1159.16V1136.36L362.711 1158.9ZM402.259 1159.16L422.072 1170.56V1147.76L402.259 1136.37V1159.16ZM303.159 1147.66L322.192 1158.61L342.012 1147.21L322.979 1136.27L303.159 1147.66ZM323.326 1136.06L362.527 1158.61L382.347 1147.21L343.146 1124.66L323.326 1136.06ZM402.259 1135.96L422.072 1147.36V1124.56L402.259 1113.16V1135.96ZM302.988 1124.56V1147.36L322.801 1135.96V1113.17L302.988 1124.56ZM382.879 1124.1V1146.9L401.905 1135.95V1113.15L382.879 1124.1ZM343.324 1124.36L382.524 1146.9V1124.1L343.324 1101.57V1124.36ZM323.156 1112.96V1135.76L342.969 1124.36V1101.57L323.156 1112.96Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M301.648 1320.4V1274.9L282.268 1263.76V1240.36L322.787 1217.06L362.335 1239.81L381.716 1228.66L402.061 1240.36V1286.95L362.505 1309.7V1331.99L342.161 1343.7L301.648 1320.4ZM322.348 1331.9L342.168 1343.29L361.988 1331.9L342.168 1320.5L322.348 1331.9ZM302.173 1320.3L321.993 1331.7L341.813 1320.3L321.993 1308.9L302.173 1320.3ZM342.345 1320.2L362.158 1331.6V1309.7L342.345 1298.31V1320.2ZM302.003 1274.9V1319.99L321.816 1308.6V1263.51L302.003 1274.9ZM322.17 1308.6L341.983 1319.99V1274.9L322.17 1263.51V1308.6ZM342.515 1298L362.335 1309.4L401.536 1286.85L381.716 1275.46L342.515 1298ZM342.345 1274.9V1297.7L381.538 1275.16V1252.36L342.345 1274.9ZM381.893 1275.16L401.706 1286.56V1263.76L381.893 1252.37V1275.16ZM282.792 1263.66L301.825 1274.61L321.645 1263.21L302.612 1252.27L282.792 1263.66ZM302.96 1252.06L342.161 1274.61L361.981 1263.21L322.78 1240.66L302.96 1252.06ZM381.893 1251.96L401.706 1263.36V1240.56L381.893 1229.16V1251.96ZM282.622 1240.56V1263.36L302.435 1251.96V1229.17L282.622 1240.56ZM362.513 1240.1V1262.9L381.538 1251.95V1229.15L362.513 1240.1ZM322.957 1240.36L362.158 1262.9V1240.1L322.957 1217.57V1240.36ZM302.79 1228.96V1251.76L322.603 1240.36V1217.57L302.79 1228.96Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M552.101 486.985V441.306L532.721 430.115V406.612L573.24 383.223L612.788 406.059L632.176 394.868L652.521 406.619V453.412L612.966 476.248V498.63L592.621 510.374L552.101 486.985ZM572.801 498.53L592.614 509.97L612.427 498.53L592.614 487.091L572.801 498.53ZM552.633 486.885L572.446 498.325L592.259 486.885L572.446 475.446L552.633 486.885ZM592.791 486.779L612.604 498.218V476.248L592.791 464.808V486.779ZM552.456 441.313V486.58L572.269 475.141V429.866L552.456 441.313ZM572.623 475.134L592.436 486.573V441.313L572.623 429.873V475.134ZM592.968 464.496L612.781 475.935L651.975 453.305L632.162 441.866L592.968 464.496ZM592.791 441.313V464.191L631.985 441.561V418.682L592.791 441.313ZM632.346 441.561L652.159 453V430.122L632.346 418.682V441.561ZM553.42 418.37L592.614 441.001L612.427 429.561L573.233 406.931L553.42 418.37ZM533.253 430.015L552.278 441.001L572.092 429.561L553.066 418.576L533.253 430.015ZM533.075 406.825V429.703L552.888 418.264V395.385L533.075 406.825ZM632.346 418.264L652.159 429.703V406.825L632.346 395.385V418.264ZM612.966 406.371V429.249L631.992 418.264V395.385L612.966 406.371ZM573.41 406.619L612.611 429.249V406.371L573.41 383.74V406.619ZM553.243 395.18V418.058L573.056 406.619V383.74L553.243 395.18Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M146.391 1053.38V1007.88L127.011 996.736V973.34L167.53 950.043L207.078 972.787L226.459 961.638L246.804 973.34V1019.93L207.249 1042.68V1064.97L186.904 1076.68L146.391 1053.38ZM167.091 1064.88L186.911 1076.27L206.731 1064.88L186.911 1053.48L167.091 1064.88ZM146.916 1053.28L166.736 1064.68L186.556 1053.28L166.736 1041.88L146.916 1053.28ZM187.088 1053.18L206.901 1064.58V1042.68L187.088 1031.29V1053.18ZM146.746 1007.88V1052.98L166.559 1041.58V996.488L146.746 1007.88ZM166.913 1041.58L186.726 1052.98V1007.88L166.913 996.488V1041.58ZM187.258 1030.98L207.078 1042.38L246.279 1019.83L226.459 1008.44L187.258 1030.98ZM187.088 1007.88V1030.69L226.282 1008.14V985.34L187.088 1007.88ZM226.636 1008.14L246.449 1019.54V996.743L226.636 985.347V1008.14ZM127.536 996.637L146.569 1007.59L166.389 996.19L147.356 985.247L127.536 996.637ZM147.703 985.042L186.904 1007.59L206.724 996.19L167.523 973.645L147.703 985.042ZM226.636 984.942L246.449 996.339V973.539L226.636 962.142V984.942ZM127.365 973.539V996.339L147.178 984.942V962.149L127.365 973.539ZM207.256 973.085V995.878L226.282 984.935V962.135L207.256 973.085ZM167.701 973.34L206.901 995.878V973.085L167.701 950.547V973.34ZM147.533 961.936V984.737L167.346 973.34V950.547L147.533 961.936Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M622.014 1195.4V1149.9L602.634 1138.76V1115.36L643.153 1092.06L682.701 1114.81L702.082 1103.66L722.427 1115.36V1161.95L682.872 1184.7V1206.99L662.527 1218.7L622.014 1195.4ZM642.714 1206.9L662.534 1218.29L682.354 1206.9L662.534 1195.5L642.714 1206.9ZM622.539 1195.3L642.359 1206.7L662.179 1195.3L642.359 1183.9L622.539 1195.3ZM662.711 1195.2L682.524 1206.6V1184.7L662.711 1173.31V1195.2ZM622.369 1149.9V1194.99L642.182 1183.6V1138.51L622.369 1149.9ZM642.536 1183.6L662.35 1194.99V1149.9L642.536 1138.51V1183.6ZM662.881 1173L682.701 1184.4L721.902 1161.85L702.082 1150.46L662.881 1173ZM662.711 1149.9V1172.7L701.905 1150.16V1127.36L662.711 1149.9ZM702.259 1150.16L722.072 1161.56V1138.76L702.259 1127.37V1150.16ZM603.159 1138.66L622.192 1149.61L642.012 1138.21L622.979 1127.27L603.159 1138.66ZM623.326 1127.06L662.527 1149.61L682.347 1138.21L643.146 1115.66L623.326 1127.06ZM702.259 1126.96L722.072 1138.36V1115.56L702.259 1104.16V1126.96ZM602.988 1115.56V1138.36L622.801 1126.96V1104.17L602.988 1115.56ZM682.879 1115.1V1137.9L701.905 1126.95V1104.15L682.879 1115.1ZM643.324 1115.36L682.524 1137.9V1115.1L643.324 1092.57V1115.36ZM623.156 1103.96V1126.76L642.969 1115.36V1092.57L623.156 1103.96Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M603.014 1313.34V1267.84L583.634 1256.69V1233.3L624.153 1210L663.701 1232.74L683.082 1221.6L703.427 1233.3V1279.89L663.872 1302.63V1324.93L643.527 1336.63L603.014 1313.34ZM623.714 1324.83L643.534 1336.23L663.354 1324.83L643.534 1313.44L623.714 1324.83ZM603.539 1313.24L623.359 1324.63L643.179 1313.24L623.359 1301.84L603.539 1313.24ZM643.711 1313.14L663.524 1324.53V1302.64L643.711 1291.25V1313.14ZM603.369 1267.84V1312.93L623.182 1301.54V1256.45L603.369 1267.84ZM623.536 1301.54L643.35 1312.93V1267.84L623.536 1256.45V1301.54ZM643.881 1290.94L663.701 1302.34L702.902 1279.79L683.082 1268.4L643.881 1290.94ZM643.711 1267.84V1290.64L682.905 1268.1V1245.3L643.711 1267.84ZM683.259 1268.1L703.072 1279.49V1256.7L683.259 1245.3V1268.1ZM584.159 1256.59L603.192 1267.54L623.012 1256.15L603.979 1245.2L584.159 1256.59ZM604.326 1245L643.527 1267.54L663.347 1256.15L624.146 1233.6L604.326 1245ZM683.259 1244.9L703.072 1256.3V1233.5L683.259 1222.1V1244.9ZM583.988 1233.5V1256.3L603.801 1244.9V1222.11L583.988 1233.5ZM663.879 1233.04V1255.84L682.905 1244.89V1222.09L663.879 1233.04ZM624.324 1233.3L663.524 1255.84V1233.04L624.324 1210.5V1233.3ZM604.156 1221.89V1244.69L623.969 1233.3V1210.5L604.156 1221.89Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1074.62 926.595V880.923L1055.24 869.731V846.236L1095.76 822.847L1135.31 845.683L1154.69 834.492L1175.04 846.243V893.036L1135.48 915.872V938.254L1115.14 949.998L1074.62 926.595ZM1095.32 938.14L1115.14 949.58L1134.95 938.14L1115.14 926.701L1095.32 938.14ZM1075.16 926.495L1094.97 937.935L1114.78 926.495L1094.97 915.056L1075.16 926.495ZM1115.32 926.389L1135.13 937.828V915.857L1115.32 904.418V926.389ZM1074.98 880.923V926.19L1094.79 914.751V869.483L1074.98 880.923ZM1095.15 914.744L1114.96 926.183V880.923L1095.15 869.483V914.744ZM1115.5 904.113L1135.31 915.552L1174.51 892.922L1154.69 881.483L1115.5 904.113ZM1115.32 880.923V903.801L1154.52 881.171V858.292L1115.32 880.923ZM1154.87 881.171L1174.68 892.61V869.731L1154.87 858.292V881.171ZM1075.94 857.98L1115.14 880.61L1134.95 869.171L1095.76 846.541L1075.94 857.98ZM1055.78 869.625L1074.8 880.61L1094.61 869.171L1075.59 858.186L1055.78 869.625ZM1055.6 846.442V869.32L1075.41 857.881V835.002L1055.6 846.442ZM1154.87 857.881L1174.68 869.32V846.442L1154.87 835.002V857.881ZM1135.49 845.981V868.859L1154.52 857.874V834.995L1135.49 845.981ZM1095.93 846.229L1135.13 868.859V845.981L1095.93 823.35V846.229ZM1075.77 834.79V857.668L1095.58 846.229V823.35L1075.77 834.79Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M859.382 1005.64V959.963L840.002 948.772V925.276L880.522 901.887L920.07 924.723L939.45 913.532L959.795 925.283V972.076L920.24 994.912V1017.29L899.895 1029.04L859.382 1005.64ZM880.082 1017.19L899.895 1028.63L919.708 1017.19L899.895 1005.75L880.082 1017.19ZM859.914 1005.54L879.727 1016.98L899.54 1005.54L879.727 994.103L859.914 1005.54ZM900.072 1005.44L919.885 1016.88V994.905L900.072 983.465V1005.44ZM859.737 959.97V1005.24L879.55 993.798V948.537L859.737 959.97ZM879.905 993.791L899.718 1005.23V959.97L879.905 948.53V993.791ZM900.249 983.153L920.063 994.593L959.256 971.962L939.443 960.523L900.249 983.153ZM900.072 959.97V982.848L939.266 960.218V937.339L900.072 959.97ZM939.627 960.218L959.44 971.657V948.779L939.627 937.339V960.218ZM860.701 937.027L899.895 959.658L919.708 948.218L880.514 925.588L860.701 937.027ZM840.534 948.672L859.56 959.658L879.373 948.218L860.347 937.233L840.534 948.672ZM840.349 925.482V948.36L860.162 936.921V914.042L840.349 925.482ZM939.627 936.928L959.44 948.367V925.489L939.627 914.049V936.928ZM920.24 925.028V947.906L939.266 936.921V914.042L920.24 925.028ZM880.692 925.276L919.892 947.906V925.028L880.692 902.397V925.276ZM860.517 913.837V936.715L880.33 925.276V902.397L860.517 913.837Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M946.783 1078.57V1032.89L927.402 1021.7V998.202L967.922 974.812L1007.47 997.649L1026.85 986.457L1047.2 998.202V1044.99L1007.64 1067.83V1090.21L987.295 1101.96L946.783 1078.57ZM967.489 1090.11L987.302 1101.55L1007.12 1090.11L987.302 1078.67L967.489 1090.11ZM947.322 1078.47L967.135 1089.91L986.948 1078.47L967.135 1067.03L947.322 1078.47ZM987.48 1078.36L1007.29 1089.8V1067.83L987.48 1056.39V1078.36ZM947.137 1032.9V1078.16L966.95 1066.72V1021.45L947.137 1032.9ZM967.312 1066.72L987.125 1078.16V1032.9L967.312 1021.46V1066.72ZM987.657 1056.09L1007.47 1067.53L1046.66 1044.9L1026.85 1033.46L987.657 1056.09ZM987.48 1032.9V1055.77L1026.67 1033.14V1010.27L987.48 1032.9ZM1027.03 1033.14L1046.85 1044.58V1021.7L1027.03 1010.27V1033.14ZM948.109 1009.95L987.302 1032.58L1007.12 1021.14L967.922 998.514L948.109 1009.95ZM927.934 1021.6L946.96 1032.58L966.773 1021.14L947.747 1010.16L927.934 1021.6ZM927.757 998.407V1021.29L947.57 1009.85V986.968L927.757 998.407ZM1027.03 1009.85L1046.85 1021.29V998.414L1027.03 986.975V1009.85ZM1007.65 997.953V1020.83L1026.67 1009.85V986.968L1007.65 997.953ZM968.099 998.202L1007.3 1020.83V997.953L968.099 975.323V998.202ZM947.925 986.762V1009.64L967.738 998.202V975.323L947.925 986.762Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1054.55 1039.46V993.777L1035.17 982.586V959.09L1075.69 935.701L1115.24 958.537L1134.62 947.346L1154.97 959.097V1005.89L1115.41 1028.73V1051.11L1095.07 1062.85L1054.55 1039.46ZM1075.25 1050.99L1095.06 1062.43L1114.87 1050.99L1095.06 1039.56L1075.25 1050.99ZM1055.08 1039.35L1074.89 1050.79L1094.71 1039.35L1074.89 1027.91L1055.08 1039.35ZM1095.24 1039.25L1115.05 1050.69V1028.72L1095.24 1017.28V1039.25ZM1054.9 993.777V1039.04L1074.72 1027.61V982.338L1054.9 993.777ZM1075.07 1027.6L1094.88 1039.04V993.777L1075.07 982.338V1027.6ZM1095.41 1016.97L1115.23 1028.41L1154.42 1005.78L1134.61 994.337L1095.41 1016.97ZM1095.24 993.777V1016.66L1134.43 994.025V971.147L1095.24 993.777ZM1134.79 994.025L1154.61 1005.46V982.586L1134.79 971.147V994.025ZM1035.7 982.487L1054.72 993.472L1074.54 982.033L1055.51 971.047L1035.7 982.487ZM1055.87 970.842L1095.06 993.472L1114.87 982.033L1075.68 959.402L1055.87 970.842ZM1035.52 959.296V982.175L1055.33 970.735V947.857L1035.52 959.296ZM1134.79 970.735L1154.61 982.175V959.296L1134.79 947.857V970.735ZM1115.41 958.842V981.721L1134.44 970.735V947.857L1115.41 958.842ZM1075.86 959.09L1115.06 981.721V958.842L1075.86 936.212V959.09ZM1055.69 947.651V970.53L1075.5 959.09V936.212L1055.69 947.651Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M967.099 966.409V920.729L947.719 909.538V886.036L988.238 862.646L1027.79 885.482L1047.17 874.291L1067.52 886.043V932.835L1027.96 955.671V978.054L1007.62 989.798L967.099 966.409ZM987.799 977.954L1007.61 989.394L1027.42 977.954L1007.61 966.515L987.799 977.954ZM967.631 966.309L987.444 977.749L1007.26 966.309L987.444 954.87L967.631 966.309ZM1007.79 966.203L1027.6 977.642V955.671L1007.79 944.232V966.203ZM967.454 920.737V966.004L987.267 954.565V909.29L967.454 920.737ZM987.621 954.558L1007.43 965.997V920.737L987.621 909.297V954.558ZM1007.97 943.92L1027.78 955.359L1066.97 932.729L1047.16 921.29L1007.97 943.92ZM1007.79 920.737V943.615L1046.98 920.985V898.106L1007.79 920.737ZM1047.34 920.985L1067.16 932.424V909.545L1047.34 898.106V920.985ZM968.418 897.794L1007.61 920.424L1027.42 908.985L988.231 886.355L968.418 897.794ZM948.251 909.439L967.277 920.424L987.09 908.985L968.064 898L948.251 909.439ZM948.073 886.248V909.127L967.886 897.688V874.809L948.073 886.248ZM1047.34 897.688L1067.16 909.127V886.248L1047.34 874.809V897.688ZM1027.96 885.795V908.673L1046.99 897.688V874.809L1027.96 885.795ZM988.409 886.043L1027.61 908.673V885.795L988.409 863.164V886.043ZM968.241 874.603V897.482L988.054 886.043V863.164L968.241 874.603Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1141.82 1113.09V1067.41L1122.44 1056.22V1032.72L1162.96 1009.33L1202.5 1032.17L1221.88 1020.98L1242.23 1032.72V1079.51L1202.67 1102.35V1124.73L1182.33 1136.48L1141.82 1113.09ZM1162.52 1124.63L1182.34 1136.07L1202.15 1124.63L1182.34 1113.19L1162.52 1124.63ZM1142.36 1112.99L1162.17 1124.43L1181.98 1112.99L1162.17 1101.55L1142.36 1112.99ZM1182.51 1112.88L1202.33 1124.32V1102.35L1182.51 1090.91V1112.88ZM1142.17 1067.41V1112.68L1161.98 1101.24V1055.97L1142.17 1067.41ZM1162.35 1101.24L1182.16 1112.68V1067.41L1162.35 1055.98V1101.24ZM1182.69 1090.61L1202.5 1102.04L1241.7 1079.41L1221.88 1067.98L1182.69 1090.61ZM1182.51 1067.41V1090.29L1221.71 1067.66V1044.78L1182.51 1067.41ZM1222.07 1067.66L1241.88 1079.1V1056.22L1222.07 1044.78V1067.66ZM1143.14 1044.47L1182.34 1067.1L1202.15 1055.66L1162.96 1033.03L1143.14 1044.47ZM1122.97 1056.12L1141.99 1067.1L1161.81 1055.66L1142.78 1044.68L1122.97 1056.12ZM1122.79 1032.93V1055.81L1142.6 1044.37V1021.49L1122.79 1032.93ZM1222.07 1044.37L1241.88 1055.81V1032.93L1222.07 1021.49V1044.37ZM1202.68 1032.47V1055.35L1221.71 1044.37V1021.49L1202.68 1032.47ZM1163.13 1032.72L1202.33 1055.35V1032.47L1163.13 1009.84V1032.72ZM1142.96 1021.28V1044.16L1162.77 1032.72V1009.84L1142.96 1021.28Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1033.85 1154.07V1108.39L1014.47 1097.2V1073.7L1054.99 1050.31L1094.54 1073.15L1113.92 1061.96L1134.27 1073.7V1120.49L1094.71 1143.33V1165.71L1074.37 1177.45L1033.85 1154.07ZM1054.56 1165.61L1074.37 1177.05L1094.19 1165.61L1074.37 1154.17L1054.56 1165.61ZM1034.39 1153.97L1054.21 1165.41L1074.02 1153.97L1054.21 1142.53L1034.39 1153.97ZM1074.55 1153.86L1094.36 1165.3V1143.33L1074.55 1131.89V1153.86ZM1034.21 1108.39V1153.65L1054.02 1142.21V1096.95L1034.21 1108.39ZM1054.38 1142.21L1074.2 1153.65V1108.39L1054.38 1096.95V1142.21ZM1074.73 1131.58L1094.54 1143.02L1133.73 1120.39L1113.92 1108.95L1074.73 1131.58ZM1074.55 1108.39V1131.27L1113.74 1108.64V1085.76L1074.55 1108.39ZM1114.11 1108.64L1133.92 1120.08V1097.2L1114.11 1085.76V1108.64ZM1035.18 1085.45L1074.37 1108.08L1094.19 1096.64L1054.99 1074.01L1035.18 1085.45ZM1015 1097.1L1034.03 1108.08L1053.84 1096.64L1034.82 1085.66L1015 1097.1ZM1014.83 1073.91V1096.78L1034.64 1085.34V1062.47L1014.83 1073.91ZM1114.11 1085.35L1133.92 1096.79V1073.91L1114.11 1062.47V1085.35ZM1094.72 1073.45V1096.33L1113.74 1085.34V1062.47L1094.72 1073.45ZM1055.17 1073.7L1094.37 1096.33V1073.45L1055.17 1050.82V1073.7ZM1034.99 1062.26V1085.14L1054.81 1073.7V1050.82L1034.99 1062.26Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1422.85 1220.75V1175.08L1403.47 1163.88V1140.39L1443.99 1117L1483.54 1139.84L1502.92 1128.64L1523.27 1140.39V1187.18L1483.71 1210.02V1232.4L1463.37 1244.14L1422.85 1220.75ZM1443.56 1232.3L1463.37 1243.74L1483.19 1232.3L1463.37 1220.86L1443.56 1232.3ZM1423.39 1220.66L1443.21 1232.09L1463.02 1220.66L1443.21 1209.22L1423.39 1220.66ZM1463.55 1220.55L1483.36 1231.99V1210.02L1463.55 1198.58V1220.55ZM1423.21 1175.08V1220.34L1443.02 1208.9V1163.64L1423.21 1175.08ZM1443.38 1208.9L1463.2 1220.34V1175.08L1443.38 1163.64V1208.9ZM1463.73 1198.27L1483.54 1209.71L1522.73 1187.08L1502.92 1175.64L1463.73 1198.27ZM1463.55 1175.08V1197.96L1502.74 1175.33V1152.45L1463.55 1175.08ZM1503.11 1175.33L1522.92 1186.77V1163.89L1503.11 1152.45V1175.33ZM1424.18 1152.14L1463.37 1174.77L1483.19 1163.33L1443.99 1140.7L1424.18 1152.14ZM1404 1163.79L1423.03 1174.77L1442.84 1163.33L1423.82 1152.35L1404 1163.79ZM1403.83 1140.59V1163.47L1423.64 1152.03V1129.16L1403.83 1140.59ZM1503.11 1152.04L1522.92 1163.48V1140.6L1503.11 1129.16V1152.04ZM1483.72 1140.14V1163.02L1502.74 1152.03V1129.16L1483.72 1140.14ZM1444.17 1140.39L1483.37 1163.02V1140.14L1444.17 1117.51V1140.39ZM1423.99 1128.95V1151.83L1443.81 1140.39V1117.51L1423.99 1128.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1617.4 1256.56V1210.88L1598.02 1199.69V1176.19L1638.54 1152.8L1678.08 1175.64L1697.46 1164.45L1717.81 1176.19V1222.98L1678.25 1245.82V1268.2L1657.91 1279.94L1617.4 1256.56ZM1638.1 1268.1L1657.92 1279.54L1677.73 1268.1L1657.92 1256.66L1638.1 1268.1ZM1617.94 1256.46L1637.75 1267.9L1657.56 1256.46L1637.75 1245.02L1617.94 1256.46ZM1658.09 1256.35L1677.91 1267.79V1245.82L1658.09 1234.38V1256.35ZM1617.75 1210.88V1256.14L1637.56 1244.71V1199.44L1617.75 1210.88ZM1637.93 1244.71L1657.74 1256.14V1210.88L1637.93 1199.44V1244.71ZM1658.27 1234.07L1678.08 1245.51L1717.28 1222.88L1697.46 1211.44L1658.27 1234.07ZM1658.09 1210.88V1233.76L1697.29 1211.13V1188.25L1658.09 1210.88ZM1697.65 1211.13L1717.46 1222.57V1199.69L1697.65 1188.25V1211.13ZM1618.72 1187.94L1657.92 1210.57L1677.73 1199.13L1638.54 1176.5L1618.72 1187.94ZM1598.55 1199.59L1617.57 1210.57L1637.39 1199.13L1618.36 1188.15L1598.55 1199.59ZM1598.37 1176.4V1199.27L1618.18 1187.83V1164.96L1598.37 1176.4ZM1697.65 1187.84L1717.46 1199.28V1176.4L1697.65 1164.96V1187.84ZM1678.26 1175.94V1198.82L1697.29 1187.83V1164.96L1678.26 1175.94ZM1638.71 1176.19L1677.91 1198.82V1175.94L1638.71 1153.31V1176.19ZM1618.54 1164.75V1187.63L1638.35 1176.19V1153.31L1618.54 1164.75Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1509.85 1298.78V1253.1L1490.47 1241.91V1218.41L1530.99 1195.02L1570.54 1217.86L1589.92 1206.67L1610.27 1218.41V1265.2L1570.71 1288.04V1310.42L1550.37 1322.17L1509.85 1298.78ZM1530.56 1310.32L1550.37 1321.76L1570.19 1310.32L1550.37 1298.88L1530.56 1310.32ZM1510.39 1298.68L1530.21 1310.12L1550.02 1298.68L1530.21 1287.24L1510.39 1298.68ZM1550.55 1298.57L1570.36 1310.01V1288.04L1550.55 1276.6V1298.57ZM1510.21 1253.11V1298.37L1530.02 1286.93V1241.66L1510.21 1253.11ZM1530.38 1286.93L1550.2 1298.37V1253.11L1530.38 1241.67V1286.93ZM1550.73 1276.3L1570.54 1287.74L1609.73 1265.1L1589.92 1253.67L1550.73 1276.3ZM1550.55 1253.11V1275.98L1589.74 1253.35V1230.48L1550.55 1253.11ZM1590.11 1253.35L1609.92 1264.79V1241.91L1590.11 1230.48V1253.35ZM1511.18 1230.16L1550.37 1252.79L1570.19 1241.35L1530.99 1218.72L1511.18 1230.16ZM1491 1241.81L1510.03 1252.79L1529.84 1241.35L1510.82 1230.37L1491 1241.81ZM1490.83 1218.62V1241.5L1510.64 1230.06V1207.18L1490.83 1218.62ZM1590.11 1230.06L1609.92 1241.5V1218.62L1590.11 1207.19V1230.06ZM1570.72 1218.16V1241.04L1589.74 1230.06V1207.18L1570.72 1218.16ZM1531.17 1218.41L1570.37 1241.04V1218.16L1531.17 1195.53V1218.41ZM1510.99 1206.97V1229.85L1530.81 1218.41V1195.53L1510.99 1206.97Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1007.95 740.329V694.657L988.568 683.466V659.97L1029.09 636.581L1068.64 659.417L1088.02 648.226L1108.36 659.977V706.77L1068.81 729.606V751.988L1048.46 763.732L1007.95 740.329ZM1028.65 751.875L1048.46 763.314L1068.27 751.875L1048.46 740.435L1028.65 751.875ZM1008.48 740.23L1028.29 751.669L1048.11 740.23L1028.29 728.79L1008.48 740.23ZM1048.65 740.123L1068.46 751.563V729.592L1048.65 718.152V740.123ZM1008.3 694.657V739.925L1028.12 728.485V683.218L1008.3 694.657ZM1028.47 728.478L1048.28 739.918V694.657L1028.47 683.218V728.478ZM1048.82 717.848L1068.64 729.287L1107.83 706.656L1088.02 695.217L1048.82 717.848ZM1048.65 694.657V717.535L1087.84 694.905V672.027L1048.65 694.657ZM1088.19 694.905L1108.01 706.344V683.466L1088.19 672.027V694.905ZM1009.27 671.715L1048.46 694.345L1068.27 682.906L1029.08 660.275L1009.27 671.715ZM989.1 683.359L1008.13 694.345L1027.94 682.906L1008.91 671.92L989.1 683.359ZM988.923 660.176V683.055L1008.74 671.615V648.737L988.923 660.176ZM1088.19 671.615L1108.01 683.055V660.176L1088.19 648.737V671.615ZM1068.81 659.715V682.594L1087.84 671.608V648.73L1068.81 659.715ZM1029.26 659.963L1068.46 682.594V659.715L1029.26 637.085V659.963ZM1009.09 648.524V671.402L1028.9 659.963V637.085L1009.09 648.524Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M792.707 819.376V773.697L773.326 762.506V739.01L813.846 715.621L853.394 738.457L872.774 727.266L893.119 739.017V785.81L853.564 808.646V831.028L833.219 842.772L792.707 819.376ZM813.406 830.922L833.219 842.361L853.032 830.922L833.219 819.482L813.406 830.922ZM793.238 819.277L813.052 830.716L832.865 819.277L813.052 807.838L793.238 819.277ZM833.396 819.17L853.209 830.61V808.639L833.396 797.2V819.17ZM793.061 773.704V818.972L812.874 807.533V762.272L793.061 773.704ZM813.229 807.525L833.042 818.965V773.704L813.229 762.265V807.525ZM833.574 796.888L853.387 808.327L892.58 785.696L872.767 774.257L833.574 796.888ZM833.396 773.704V796.583L872.59 773.952V751.074L833.396 773.704ZM872.952 773.952L892.765 785.392V762.513L872.952 751.074V773.952ZM794.026 750.762L833.219 773.392L853.032 761.953L813.839 739.322L794.026 750.762ZM773.858 762.407L792.884 773.392L812.697 761.953L793.671 750.967L773.858 762.407ZM773.674 739.216V762.095L793.487 750.655V727.777L773.674 739.216ZM872.952 750.662L892.765 762.102V739.223L872.952 727.784V750.662ZM853.564 738.762V761.641L872.59 750.655V727.777L853.564 738.762ZM814.016 739.01L853.217 761.641V738.762L814.016 716.132V739.01ZM793.841 727.571V750.45L813.654 739.01V716.132L793.841 727.571Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M880.107 892.302V846.623L860.727 835.432V811.936L901.246 788.547L940.794 811.383L960.175 800.192L980.52 811.936V858.729L940.964 881.565V903.947L920.62 915.691L880.107 892.302ZM900.814 903.848L920.627 915.287L940.44 903.848L920.627 892.408L900.814 903.848ZM880.646 892.203L900.459 903.642L920.272 892.203L900.459 880.763L880.646 892.203ZM920.804 892.096L940.617 903.536V881.565L920.804 870.125V892.096ZM880.462 846.63V891.891L900.275 880.451V835.183L880.462 846.63ZM900.636 880.451L920.449 891.891V846.63L900.636 835.19V880.451ZM920.981 869.82L940.794 881.26L979.988 858.629L960.175 847.19L920.981 869.82ZM920.804 846.63V869.508L959.997 846.878V823.999L920.804 846.63ZM960.359 846.878L980.172 858.317V835.439L960.359 823.999V846.878ZM881.433 823.687L920.627 846.318L940.44 834.878L901.246 812.248L881.433 823.687ZM861.258 835.332L880.284 846.318L900.097 834.878L881.071 823.893L861.258 835.332ZM861.081 812.142V835.02L880.894 823.581V800.702L861.081 812.142ZM960.359 823.588L980.172 835.027V812.149L960.359 800.71V823.588ZM940.972 811.688V834.566L959.997 823.581V800.702L940.972 811.688ZM901.423 811.936L940.624 834.566V811.688L901.423 789.057V811.936ZM881.249 800.497V823.375L901.062 811.936V789.057L881.249 800.497Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M987.873 853.191V807.511L968.492 796.32V772.825L1009.01 749.436L1048.57 772.272L1067.95 761.081L1088.29 772.832V819.624L1048.74 842.46V864.843L1028.39 876.587L987.873 853.191ZM1008.57 864.729L1028.39 876.168L1048.2 864.729L1028.39 853.29L1008.57 864.729ZM988.405 853.084L1008.22 864.523L1028.03 853.084L1008.22 841.645L988.405 853.084ZM1028.56 852.985L1048.38 864.424V842.453L1028.56 831.014V852.985ZM988.227 807.511V852.779L1008.04 841.34V796.072L988.227 807.511ZM1008.39 841.333L1028.21 852.772V807.511L1008.39 796.072V841.333ZM1028.74 830.702L1048.55 842.141L1087.75 819.511L1067.93 808.072L1028.74 830.702ZM1028.56 807.511V830.39L1067.76 807.76V784.881L1028.56 807.511ZM1068.12 807.76L1087.93 819.199V796.32L1068.12 784.881V807.76ZM969.024 796.221L988.05 807.206L1007.86 795.767L988.837 784.782L969.024 796.221ZM989.192 784.576L1028.39 807.206L1048.2 795.767L1009 773.137L989.192 784.576ZM968.847 773.03V795.909L988.66 784.47V761.591L968.847 773.03ZM1068.12 784.47L1087.93 795.909V773.03L1068.12 761.591V784.47ZM1048.74 772.577V795.455L1067.76 784.47V761.591L1048.74 772.577ZM1009.18 772.825L1048.38 795.455V772.577L1009.18 749.946V772.825ZM989.014 761.385V784.264L1008.83 772.825V749.946L989.014 761.385Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M900.423 780.143V734.464L881.043 723.273V699.77L921.563 676.381L961.111 699.217L980.498 688.026L1000.84 699.777V746.57L961.288 769.406V791.788L940.943 803.532L900.423 780.143ZM921.123 791.689L940.936 803.128L960.749 791.689L940.936 780.249L921.123 791.689ZM900.955 780.044L920.768 791.483L940.581 780.044L920.768 768.604L900.955 780.044ZM941.113 779.937L960.926 791.377V769.406L941.113 757.966V779.937ZM900.778 734.471V779.739L920.591 768.299V723.025L900.778 734.471ZM920.946 768.292L940.759 779.732V734.471L920.946 723.032V768.292ZM941.291 757.654L961.104 769.094L1000.3 746.463L980.484 735.024L941.291 757.654ZM941.113 734.471V757.349L980.307 734.719V711.841L941.113 734.471ZM980.668 734.719L1000.48 746.158V723.28L980.668 711.841V734.719ZM901.742 711.528L940.936 734.159L960.749 722.72L921.555 700.089L901.742 711.528ZM881.575 723.173L900.601 734.159L920.414 722.72L901.388 711.734L881.575 723.173ZM881.398 699.983V722.861L901.211 711.422V688.544L881.398 699.983ZM980.668 711.422L1000.48 722.861V699.983L980.668 688.544V711.422ZM961.288 699.529V722.408L980.314 711.422V688.544L961.288 699.529ZM921.733 699.777L960.933 722.408V699.529L921.733 676.899V699.777ZM901.565 688.338V711.216L921.378 699.777V676.899L901.565 688.338Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1311.35 734.812V689.14L1291.97 677.949V654.454L1332.49 631.064L1372.04 653.9L1391.42 642.709L1411.76 654.461V701.253L1372.21 724.089V746.472L1351.86 758.216L1311.35 734.812ZM1332.05 746.358L1351.86 757.797L1371.68 746.358L1351.86 734.919L1332.05 746.358ZM1311.88 734.713L1331.7 746.152L1351.51 734.713L1331.7 723.274L1311.88 734.713ZM1352.05 734.607L1371.86 746.046V724.075L1352.05 712.636V734.607ZM1311.71 689.14V734.408L1331.52 722.969V677.701L1311.71 689.14ZM1331.87 722.962L1351.69 734.401V689.14L1331.87 677.701V722.962ZM1352.23 712.331L1372.04 723.77L1411.23 701.14L1391.42 689.701L1352.23 712.331ZM1352.05 689.14V712.019L1391.24 689.389V666.51L1352.05 689.14ZM1391.6 689.389L1411.41 700.828V677.949L1391.6 666.51V689.389ZM1312.67 666.198L1351.86 688.828L1371.68 677.389L1332.48 654.759L1312.67 666.198ZM1292.5 677.843L1311.53 688.828L1331.34 677.389L1312.32 666.404L1292.5 677.843ZM1292.33 654.659V677.538L1312.14 666.099V643.22L1292.33 654.659ZM1391.6 666.099L1411.41 677.538V654.659L1391.6 643.22V666.099ZM1372.22 654.198V677.077L1391.24 666.092V643.213L1372.22 654.198ZM1332.66 654.447L1371.86 677.077V654.198L1332.66 631.568V654.447ZM1312.49 643.007V665.886L1332.31 654.447V631.568L1312.49 643.007Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1096.11 813.859V768.18L1076.73 756.989V733.494L1117.25 710.104L1156.8 732.941L1176.18 721.749L1196.52 733.501V780.293L1156.97 803.129V825.512L1136.62 837.256L1096.11 813.859ZM1116.81 825.405L1136.62 836.844L1156.43 825.405L1136.62 813.966L1116.81 825.405ZM1096.64 813.76L1116.45 825.199L1136.27 813.76L1116.45 802.321L1096.64 813.76ZM1136.8 813.654L1156.61 825.093V803.122L1136.8 791.683V813.654ZM1096.46 768.187V813.455L1116.28 802.016V756.755L1096.46 768.187ZM1116.63 802.009L1136.44 813.448V768.187L1116.63 756.748V802.009ZM1136.98 791.371L1156.79 802.81L1195.98 780.18L1176.17 768.741L1136.98 791.371ZM1136.8 768.187V791.066L1175.99 768.436V745.557L1136.8 768.187ZM1176.35 768.436L1196.17 779.875V756.996L1176.35 745.557V768.436ZM1097.43 745.245L1136.62 767.875L1156.43 756.436L1117.24 733.806L1097.43 745.245ZM1077.26 756.89L1096.29 767.875L1116.1 756.436L1097.07 745.451L1077.26 756.89ZM1077.08 733.699V756.578L1096.89 745.139V722.26L1077.08 733.699ZM1176.35 745.146L1196.17 756.585V733.706L1176.35 722.267V745.146ZM1156.97 733.245V756.124L1175.99 745.139V722.26L1156.97 733.245ZM1117.42 733.494L1156.62 756.124V733.245L1117.42 710.615V733.494ZM1097.24 722.054V744.933L1117.06 733.494V710.615L1097.24 722.054Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1183.51 886.786V841.107L1164.13 829.916V806.42L1204.65 783.031L1244.2 805.867L1263.58 794.676L1283.92 806.42V853.213L1244.37 876.049V898.431L1224.02 910.175L1183.51 886.786ZM1204.22 898.332L1224.03 909.771L1243.84 898.332L1224.03 886.893L1204.22 898.332ZM1184.05 886.687L1203.86 898.126L1223.67 886.687L1203.86 875.248L1184.05 886.687ZM1224.21 886.581L1244.02 898.02V876.049L1224.21 864.61V886.581ZM1183.86 841.114V886.375L1203.68 874.936V829.668L1183.86 841.114ZM1204.04 874.936L1223.85 886.375V841.114L1204.04 829.675V874.936ZM1224.38 864.305L1244.2 875.744L1283.39 853.114L1263.58 841.674L1224.38 864.305ZM1224.21 841.114V863.993L1263.4 841.362V818.484L1224.21 841.114ZM1263.76 841.362L1283.57 852.802V829.923L1263.76 818.484V841.362ZM1184.84 818.172L1224.03 840.802L1243.84 829.363L1204.65 806.732L1184.84 818.172ZM1164.66 829.817L1183.69 840.802L1203.5 829.363L1184.47 818.377L1164.66 829.817ZM1164.48 806.626V829.505L1184.3 818.065V795.187L1164.48 806.626ZM1263.76 818.072L1283.57 829.512V806.633L1263.76 795.194V818.072ZM1244.37 806.172V829.051L1263.4 818.065V795.187L1244.37 806.172ZM1204.83 806.42L1244.03 829.051V806.172L1204.83 783.542V806.42ZM1184.65 794.981V817.86L1204.46 806.42V783.542L1184.65 794.981Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1291.28 847.674V801.995L1271.89 790.804V767.308L1312.41 743.919L1351.97 766.755L1371.35 755.564L1391.69 767.315V814.108L1352.14 836.944V859.326L1331.79 871.07L1291.28 847.674ZM1311.97 859.213L1331.79 870.652L1351.6 859.213L1331.79 847.773L1311.97 859.213ZM1291.81 847.568L1311.62 859.007L1331.43 847.568L1311.62 836.128L1291.81 847.568ZM1331.96 847.468L1351.78 858.908V836.937L1331.96 825.497V847.468ZM1291.63 801.995V847.263L1311.44 835.823V790.556L1291.63 801.995ZM1311.8 835.816L1331.61 847.256V801.995L1311.8 790.556V835.816ZM1332.14 825.185L1351.96 836.625L1391.15 813.994L1371.34 802.555L1332.14 825.185ZM1331.96 801.995V824.873L1371.16 802.243V779.364L1331.96 801.995ZM1371.52 802.243L1391.33 813.682V790.804L1371.52 779.364V802.243ZM1272.43 790.704L1291.45 801.69L1311.27 790.251L1292.24 779.265L1272.43 790.704ZM1292.59 779.059L1331.79 801.69L1351.6 790.251L1312.41 767.62L1292.59 779.059ZM1272.25 767.514V790.392L1292.06 778.953V756.075L1272.25 767.514ZM1371.52 778.953L1391.33 790.392V767.514L1371.52 756.075V778.953ZM1352.14 767.06V789.939L1371.17 778.953V756.075L1352.14 767.06ZM1312.58 767.308L1351.78 789.939V767.06L1312.58 744.43V767.308ZM1292.42 755.869V778.747L1312.23 767.308V744.43L1292.42 755.869Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1203.83 774.626V728.947L1184.45 717.756V694.253L1224.96 670.864L1264.51 693.7L1283.9 682.509L1304.25 694.261V741.053L1264.69 763.889V786.271L1244.35 798.016L1203.83 774.626ZM1224.53 786.172L1244.34 797.611L1264.15 786.172L1244.34 774.733L1224.53 786.172ZM1204.36 774.527L1224.17 785.966L1243.98 774.527L1224.17 763.088L1204.36 774.527ZM1244.52 774.421L1264.33 785.86V763.889L1244.52 752.45V774.421ZM1204.18 728.954V774.222L1223.99 762.783V717.508L1204.18 728.954ZM1224.35 762.776L1244.16 774.215V728.954L1224.35 717.515V762.776ZM1244.69 752.138L1264.51 763.577L1303.7 740.947L1283.89 729.507L1244.69 752.138ZM1244.52 728.954V751.833L1283.71 729.202V706.324L1244.52 728.954ZM1284.07 729.202L1303.88 740.642V717.763L1284.07 706.324V729.202ZM1205.14 706.012L1244.34 728.642L1264.15 717.203L1224.96 694.573L1205.14 706.012ZM1184.98 717.657L1204 728.642L1223.82 717.203L1204.79 706.218L1184.98 717.657ZM1184.8 694.466V717.345L1204.61 705.906V683.027L1184.8 694.466ZM1284.07 705.906L1303.88 717.345V694.466L1284.07 683.027V705.906ZM1264.69 694.012V716.891L1283.72 705.906V683.027L1264.69 694.012ZM1225.14 694.261L1264.34 716.891V694.012L1225.14 671.382V694.261ZM1204.97 682.821V705.7L1224.78 694.261V671.382L1204.97 682.821Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1377.55 920.794V875.122L1358.17 863.931V840.435L1398.69 817.046L1438.23 839.882L1457.62 828.691L1477.96 840.442V887.235L1438.4 910.071V932.453L1418.06 944.197L1377.55 920.794ZM1398.25 932.339L1418.06 943.779L1437.87 932.339L1418.06 920.9L1398.25 932.339ZM1378.08 920.695L1397.89 932.134L1417.71 920.695L1397.89 909.255L1378.08 920.695ZM1418.24 920.588L1438.06 932.027V910.057L1418.24 898.617V920.588ZM1377.9 875.122V920.39L1397.72 908.95V863.682L1377.9 875.122ZM1398.07 908.943L1417.88 920.382V875.122L1398.07 863.682V908.943ZM1418.42 898.312L1438.23 909.752L1477.43 887.121L1457.62 875.682L1418.42 898.312ZM1418.24 875.122V898L1457.44 875.37V852.491L1418.24 875.122ZM1457.79 875.37L1477.61 886.809V863.931L1457.79 852.491V875.37ZM1378.87 852.179L1418.06 874.81L1437.87 863.37L1398.68 840.74L1378.87 852.179ZM1358.7 863.824L1377.72 874.81L1397.54 863.37L1378.51 852.385L1358.7 863.824ZM1358.52 840.641V863.519L1378.33 852.08V829.201L1358.52 840.641ZM1457.79 852.08L1477.61 863.519V840.641L1457.79 829.201V852.08ZM1438.41 840.18V863.058L1457.44 852.073V829.194L1438.41 840.18ZM1398.86 840.428L1438.06 863.058V840.18L1398.86 817.549V840.428ZM1378.69 828.989V851.867L1398.5 840.428V817.549L1378.69 828.989Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1572.09 956.596V910.924L1552.71 899.732V876.237L1593.23 852.848L1632.78 875.684L1652.16 864.493L1672.5 876.244V923.037L1632.95 945.873V968.255L1612.6 979.999L1572.09 956.596ZM1592.79 968.141L1612.6 979.581L1632.42 968.141L1612.6 956.702L1592.79 968.141ZM1572.62 956.496L1592.44 967.936L1612.25 956.496L1592.44 945.057L1572.62 956.496ZM1612.79 956.39L1632.6 967.829V945.858L1612.79 934.419V956.39ZM1572.44 910.924V956.191L1592.26 944.752V899.484L1572.44 910.924ZM1592.61 944.745L1612.43 956.184V910.924L1592.61 899.484V944.745ZM1612.96 934.114L1632.78 945.553L1671.97 922.923L1652.16 911.484L1612.96 934.114ZM1612.79 910.924V933.802L1651.98 911.172V888.293L1612.79 910.924ZM1652.34 911.172L1672.15 922.611V899.732L1652.34 888.293V911.172ZM1573.41 887.981L1612.6 910.611L1632.42 899.172L1593.22 876.542L1573.41 887.981ZM1553.24 899.626L1572.27 910.611L1592.08 899.172L1573.05 888.187L1553.24 899.626ZM1553.06 876.443V899.321L1572.88 887.882V865.003L1553.06 876.443ZM1652.34 887.882L1672.15 899.321V876.443L1652.34 865.003V887.882ZM1632.95 875.982V898.86L1651.98 887.875V864.996L1632.95 875.982ZM1593.4 876.23L1632.6 898.86V875.982L1593.4 853.351V876.23ZM1573.23 864.79V887.669L1593.05 876.23V853.351L1573.23 864.79Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1162.31 999.841V954.162L1142.93 942.971V919.475L1183.45 896.086L1222.99 918.922L1242.37 907.731L1262.72 919.482V966.275L1223.16 989.111V1011.49L1202.82 1023.24L1162.31 999.841ZM1183.01 1011.39L1202.82 1022.83L1222.63 1011.39L1202.82 999.947L1183.01 1011.39ZM1162.84 999.742L1182.65 1011.18L1202.46 999.742L1182.65 988.302L1162.84 999.742ZM1203 999.635L1222.81 1011.07V989.104L1203 977.664V999.635ZM1162.66 954.169V999.437L1182.47 987.997V942.737L1162.66 954.169ZM1182.83 987.99L1202.64 999.43V954.169L1182.83 942.73V987.99ZM1203.17 977.352L1222.99 988.792L1262.18 966.161L1242.37 954.722L1203.17 977.352ZM1203 954.169V977.047L1242.19 954.417V931.539L1203 954.169ZM1242.55 954.417L1262.36 965.856V942.978L1242.55 931.539V954.417ZM1163.63 931.226L1202.82 953.857L1222.63 942.418L1183.44 919.787L1163.63 931.226ZM1143.46 942.871L1162.48 953.857L1182.3 942.418L1163.27 931.432L1143.46 942.871ZM1143.27 919.681V942.559L1163.09 931.12V908.242L1143.27 919.681ZM1242.55 931.127L1262.36 942.566V919.688L1242.55 908.249V931.127ZM1223.16 919.227V942.106L1242.19 931.12V908.242L1223.16 919.227ZM1183.62 919.475L1222.82 942.106V919.227L1183.62 896.597V919.475ZM1163.44 908.036V930.914L1183.25 919.475V896.597L1163.44 908.036Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1249.71 1072.77V1027.09L1230.33 1015.9V992.401L1270.84 969.012L1310.39 991.848L1329.77 980.657L1350.12 992.401V1039.19L1310.56 1062.03V1084.41L1290.22 1096.16L1249.71 1072.77ZM1270.41 1084.31L1290.23 1095.75L1310.04 1084.31L1290.23 1072.87L1270.41 1084.31ZM1250.24 1072.67L1270.06 1084.11L1289.87 1072.67L1270.06 1061.23L1250.24 1072.67ZM1290.4 1072.56L1310.22 1084V1062.03L1290.4 1050.59V1072.56ZM1250.06 1027.09V1072.36L1269.87 1060.92V1015.65L1250.06 1027.09ZM1270.23 1060.92L1290.05 1072.36V1027.09L1270.23 1015.66V1060.92ZM1290.58 1050.29L1310.39 1061.72L1349.59 1039.09L1329.77 1027.65L1290.58 1050.29ZM1290.4 1027.09V1049.97L1329.6 1027.34V1004.46L1290.4 1027.09ZM1329.96 1027.34L1349.77 1038.78V1015.9L1329.96 1004.46V1027.34ZM1251.03 1004.15L1290.23 1026.78L1310.04 1015.34L1270.84 992.713L1251.03 1004.15ZM1230.86 1015.8L1249.88 1026.78L1269.7 1015.34L1250.67 1004.36L1230.86 1015.8ZM1230.68 992.607V1015.49L1250.49 1004.05V981.167L1230.68 992.607ZM1329.96 1004.05L1349.77 1015.49V992.614L1329.96 981.174V1004.05ZM1310.57 992.153V1015.03L1329.6 1004.05V981.167L1310.57 992.153ZM1271.02 992.401L1310.22 1015.03V992.153L1271.02 969.522V992.401ZM1250.85 980.962V1003.84L1270.66 992.401V969.522L1250.85 980.962Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1357.47 1033.66V987.976L1338.09 976.785V953.29L1378.61 929.9L1418.17 952.736L1437.55 941.545L1457.89 953.297V1000.09L1418.34 1022.93V1045.31L1397.99 1057.05L1357.47 1033.66ZM1378.17 1045.19L1397.98 1056.63L1417.8 1045.19L1397.98 1033.75L1378.17 1045.19ZM1358 1033.55L1377.82 1044.99L1397.63 1033.55L1377.82 1022.11L1358 1033.55ZM1398.16 1033.45L1417.97 1044.89V1022.92L1398.16 1011.48V1033.45ZM1357.83 987.976V1033.24L1377.64 1021.8V976.537L1357.83 987.976ZM1377.99 1021.8L1397.81 1033.24V987.976L1377.99 976.537V1021.8ZM1398.34 1011.17L1418.15 1022.61L1457.34 999.976L1437.53 988.536L1398.34 1011.17ZM1398.16 987.976V1010.85L1437.35 988.224V965.346L1398.16 987.976ZM1437.72 988.224L1457.53 999.664V976.785L1437.72 965.346V988.224ZM1338.62 976.686L1357.65 987.671L1377.46 976.232L1358.44 965.247L1338.62 976.686ZM1358.79 965.041L1397.98 987.671L1417.8 976.232L1378.6 953.602L1358.79 965.041ZM1338.45 953.495V976.374L1358.26 964.935V942.056L1338.45 953.495ZM1437.72 964.935L1457.53 976.374V953.495L1437.72 942.056V964.935ZM1418.34 953.041V975.92L1437.36 964.935V942.056L1418.34 953.041ZM1378.78 953.29L1417.98 975.92V953.041L1378.78 930.411V953.29ZM1358.61 941.85V964.729L1378.43 953.29V930.411L1358.61 941.85Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1552.01 1069.46V1023.78L1532.63 1012.59V989.09L1573.15 965.701L1612.71 988.537L1632.09 977.346L1652.43 989.097V1035.89L1612.88 1058.73V1081.11L1592.53 1092.85L1552.01 1069.46ZM1572.71 1080.99L1592.53 1092.43L1612.34 1080.99L1592.53 1069.56L1572.71 1080.99ZM1552.55 1069.35L1572.36 1080.79L1592.17 1069.35L1572.36 1057.91L1552.55 1069.35ZM1592.7 1069.25L1612.52 1080.69V1058.72L1592.7 1047.28V1069.25ZM1552.37 1023.78V1069.04L1572.18 1057.61V1012.34L1552.37 1023.78ZM1572.54 1057.6L1592.35 1069.04V1023.78L1572.54 1012.34V1057.6ZM1592.88 1046.97L1612.69 1058.41L1651.89 1035.78L1632.07 1024.34L1592.88 1046.97ZM1592.7 1023.78V1046.66L1631.9 1024.03V1001.15L1592.7 1023.78ZM1632.26 1024.03L1652.07 1035.46V1012.59L1632.26 1001.15V1024.03ZM1533.17 1012.49L1552.19 1023.47L1572 1012.03L1552.98 1001.05L1533.17 1012.49ZM1553.33 1000.84L1592.53 1023.47L1612.34 1012.03L1573.15 989.402L1553.33 1000.84ZM1532.99 989.296V1012.17L1552.8 1000.74V977.857L1532.99 989.296ZM1632.26 1000.74L1652.07 1012.17V989.296L1632.26 977.857V1000.74ZM1612.88 988.842V1011.72L1631.9 1000.74V977.857L1612.88 988.842ZM1573.32 989.09L1612.52 1011.72V988.842L1573.32 966.212V989.09ZM1553.16 977.651V1000.53L1572.97 989.09V966.212L1553.16 977.651Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1336.47 1146.19V1100.51L1317.09 1089.32V1065.82L1357.61 1042.43L1397.16 1065.27L1416.54 1054.08L1436.89 1065.83V1112.62L1397.33 1135.46V1157.84L1376.99 1169.58L1336.47 1146.19ZM1357.17 1157.72L1376.98 1169.16L1396.79 1157.72L1376.98 1146.28L1357.17 1157.72ZM1337 1146.08L1356.81 1157.52L1376.63 1146.08L1356.81 1134.64L1337 1146.08ZM1377.16 1145.98L1396.97 1157.42V1135.45L1377.16 1124.01V1145.98ZM1336.82 1100.51V1145.77L1356.63 1134.34V1089.07L1336.82 1100.51ZM1356.99 1134.33L1376.8 1145.77V1100.51L1356.99 1089.07V1134.33ZM1377.33 1123.7L1397.15 1135.14L1436.34 1112.51L1416.53 1101.07L1377.33 1123.7ZM1377.16 1100.51V1123.39L1416.35 1100.75V1077.88L1377.16 1100.51ZM1416.71 1100.75L1436.53 1112.19V1089.32L1416.71 1077.88V1100.75ZM1317.62 1089.22L1336.64 1100.2L1356.46 1088.76L1337.43 1077.78L1317.62 1089.22ZM1337.79 1077.57L1376.98 1100.2L1396.79 1088.76L1357.6 1066.13L1337.79 1077.57ZM1317.44 1066.03V1088.9L1337.25 1077.46V1054.59L1317.44 1066.03ZM1416.71 1077.46L1436.53 1088.9V1066.03L1416.71 1054.59V1077.46ZM1397.33 1065.57V1088.45L1416.36 1077.46V1054.59L1397.33 1065.57ZM1357.78 1065.82L1396.98 1088.45V1065.57L1357.78 1042.94V1065.82ZM1337.61 1054.38V1077.26L1357.42 1065.82V1042.94L1337.61 1054.38Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1228.5 1186.38V1140.7L1209.12 1129.51V1106.01L1249.64 1082.62L1289.2 1105.46L1308.58 1094.27L1328.92 1106.02V1152.81L1289.37 1175.65V1198.03L1269.02 1209.77L1228.5 1186.38ZM1249.2 1197.91L1269.02 1209.35L1288.83 1197.91L1269.02 1186.48L1249.2 1197.91ZM1229.04 1186.27L1248.85 1197.71L1268.66 1186.27L1248.85 1174.83L1229.04 1186.27ZM1269.19 1186.17L1289.01 1197.61V1175.64L1269.19 1164.2V1186.17ZM1228.86 1140.7V1185.96L1248.67 1174.53V1129.26L1228.86 1140.7ZM1249.03 1174.52L1268.84 1185.96V1140.7L1249.03 1129.26V1174.52ZM1269.37 1163.89L1289.18 1175.33L1328.38 1152.7L1308.56 1141.26L1269.37 1163.89ZM1269.19 1140.7V1163.58L1308.39 1140.95V1118.07L1269.19 1140.7ZM1308.75 1140.95L1328.56 1152.38V1129.51L1308.75 1118.07V1140.95ZM1209.65 1129.41L1228.68 1140.39L1248.49 1128.95L1229.47 1117.97L1209.65 1129.41ZM1229.82 1117.76L1269.02 1140.39L1288.83 1128.95L1249.64 1106.32L1229.82 1117.76ZM1209.48 1106.22V1129.09L1229.29 1117.66V1094.78L1209.48 1106.22ZM1308.75 1117.66L1328.56 1129.09V1106.22L1308.75 1094.78V1117.66ZM1289.37 1105.76V1128.64L1308.39 1117.66V1094.78L1289.37 1105.76ZM1249.81 1106.01L1289.01 1128.64V1105.76L1249.81 1083.13V1106.01ZM1229.65 1094.57V1117.45L1249.46 1106.01V1083.13L1229.65 1094.57Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1316.01 1258.75V1213.08L1296.63 1201.88V1178.39L1337.15 1155L1376.71 1177.84L1396.09 1166.64L1416.43 1178.4V1225.19L1376.88 1248.02V1270.41L1356.53 1282.15L1316.01 1258.75ZM1336.71 1270.29L1356.53 1281.73L1376.34 1270.29L1356.53 1258.85L1336.71 1270.29ZM1316.55 1258.65L1336.36 1270.09L1356.17 1258.65L1336.36 1247.21L1316.55 1258.65ZM1356.7 1258.55L1376.52 1269.99V1248.02L1356.7 1236.58V1258.55ZM1316.37 1213.08V1258.34L1336.18 1246.9V1201.64L1316.37 1213.08ZM1336.54 1246.9L1356.35 1258.34V1213.08L1336.54 1201.64V1246.9ZM1356.88 1236.27L1376.69 1247.71L1415.89 1225.08L1396.07 1213.64L1356.88 1236.27ZM1356.7 1213.08V1235.95L1395.9 1213.32V1190.45L1356.7 1213.08ZM1396.26 1213.32L1416.07 1224.76V1201.88L1396.26 1190.45V1213.32ZM1297.17 1201.79L1316.19 1212.77L1336 1201.33L1316.98 1190.35L1297.17 1201.79ZM1317.33 1190.14L1356.53 1212.77L1376.34 1201.33L1337.15 1178.7L1317.33 1190.14ZM1296.99 1178.59V1201.47L1316.8 1190.03V1167.16L1296.99 1178.59ZM1396.26 1190.03L1416.07 1201.47V1178.59L1396.26 1167.16V1190.03ZM1376.88 1178.14V1201.02L1395.9 1190.03V1167.16L1376.88 1178.14ZM1337.32 1178.39L1376.52 1201.02V1178.14L1337.32 1155.51V1178.39ZM1317.16 1166.95V1189.83L1336.97 1178.39V1155.51L1317.16 1166.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1270.02 960.608V914.929L1250.64 903.738V880.235L1291.16 856.846L1330.71 879.682L1350.1 868.491L1370.44 880.242V927.035L1330.89 949.871V972.253L1310.54 983.997L1270.02 960.608ZM1290.72 972.153L1310.53 983.593L1330.35 972.153L1310.53 960.714L1290.72 972.153ZM1270.55 960.508L1290.37 971.948L1310.18 960.508L1290.37 949.069L1270.55 960.508ZM1310.71 960.402L1330.52 971.841V949.871L1310.71 938.431V960.402ZM1270.38 914.936V960.204L1290.19 948.764V903.489L1270.38 914.936ZM1290.54 948.757L1310.36 960.196V914.936L1290.54 903.496V948.757ZM1310.89 938.119L1330.7 949.559L1369.9 926.928L1350.08 915.489L1310.89 938.119ZM1310.71 914.936V937.814L1349.91 915.184V892.305L1310.71 914.936ZM1350.27 915.184L1370.08 926.623V903.745L1350.27 892.305V915.184ZM1271.34 891.993L1310.53 914.624L1330.35 903.184L1291.15 880.554L1271.34 891.993ZM1251.17 903.638L1270.2 914.624L1290.01 903.184L1270.99 892.199L1251.17 903.638ZM1251 880.448V903.326L1270.81 891.887V869.008L1251 880.448ZM1350.27 891.887L1370.08 903.326V880.448L1350.27 869.008V891.887ZM1330.89 879.994V902.872L1349.91 891.887V869.008L1330.89 879.994ZM1291.33 880.242L1330.53 902.872V879.994L1291.33 857.363V880.242ZM1271.16 868.803V891.681L1290.98 880.242V857.363L1271.16 868.803Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1048.93 516.52V470.848L1029.55 459.657V436.162L1070.07 412.772L1109.61 435.608L1129 424.417L1149.34 436.169V482.961L1109.78 505.797V528.18L1089.44 539.924L1048.93 516.52ZM1069.63 528.066L1089.44 539.505L1109.25 528.066L1089.44 516.627L1069.63 528.066ZM1049.46 516.421L1069.27 527.86L1089.09 516.421L1069.27 504.982L1049.46 516.421ZM1089.62 516.315L1109.44 527.754V505.783L1089.62 494.344V516.315ZM1049.28 470.848V516.116L1069.09 504.677V459.409L1049.28 470.848ZM1069.45 504.67L1089.26 516.109V470.848L1069.45 459.409V504.67ZM1089.8 494.039L1109.61 505.478L1148.81 482.848L1129 471.409L1089.8 494.039ZM1089.62 470.848V493.727L1128.82 471.097V448.218L1089.62 470.848ZM1129.17 471.097L1148.99 482.536V459.657L1129.17 448.218V471.097ZM1050.25 447.906L1089.44 470.536L1109.25 459.097L1070.06 436.467L1050.25 447.906ZM1030.08 459.551L1049.1 470.536L1068.92 459.097L1049.89 448.112L1030.08 459.551ZM1029.9 436.367V459.246L1049.71 447.807V424.928L1029.9 436.367ZM1129.17 447.807L1148.99 459.246V436.367L1129.17 424.928V447.807ZM1109.79 435.906V458.785L1128.82 447.8V424.921L1109.79 435.906ZM1070.24 436.155L1109.44 458.785V435.906L1070.24 413.276V436.155ZM1050.07 424.715V447.594L1069.88 436.155V413.276L1050.07 424.715Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M961.351 442.443V396.771L941.971 385.58V362.085L982.49 338.695L1022.04 361.531L1041.42 350.34L1061.76 362.092V408.884L1022.21 431.72V454.102L1001.86 465.847L961.351 442.443ZM982.051 453.989L1001.86 465.428L1021.68 453.989L1001.86 442.55L982.051 453.989ZM961.883 442.344L981.696 453.783L1001.51 442.344L981.696 430.905L961.883 442.344ZM1002.05 442.238L1021.86 453.677V431.706L1002.05 420.267V442.238ZM961.706 396.771V442.039L981.519 430.6V385.332L961.706 396.771ZM981.873 430.593L1001.69 442.032V396.771L981.873 385.332V430.593ZM1002.23 419.962L1022.04 431.401L1061.23 408.771L1041.42 397.331L1002.23 419.962ZM1002.05 396.771V419.65L1041.24 397.019V374.141L1002.05 396.771ZM1041.6 397.019L1061.41 408.459V385.58L1041.6 374.141V397.019ZM962.67 373.829L1001.86 396.459L1021.68 385.02L982.483 362.389L962.67 373.829ZM942.503 385.474L961.528 396.459L981.342 385.02L962.316 374.034L942.503 385.474ZM942.325 362.29V385.169L962.138 373.729V350.851L942.325 362.29ZM1041.6 373.729L1061.41 385.169V362.29L1041.6 350.851V373.729ZM1022.22 361.829V384.708L1041.24 373.722V350.844L1022.22 361.829ZM982.66 362.077L1021.86 384.708V361.829L982.66 339.199V362.077ZM962.493 350.638V373.517L982.306 362.077V339.199L962.493 350.638Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M853.387 481.846V436.174L834.007 424.982V401.487L874.526 378.098L914.075 400.934L933.455 389.743L953.8 401.494V448.287L914.245 471.123V493.505L893.9 505.249L853.387 481.846ZM874.087 493.391L893.9 504.831L913.713 493.391L893.9 481.952L874.087 493.391ZM853.919 481.746L873.732 493.186L893.545 481.746L873.732 470.307L853.919 481.746ZM894.084 481.64L913.897 493.079V471.108L894.084 459.669V481.64ZM853.742 436.174V481.441L873.555 470.002V424.734L853.742 436.174ZM873.91 469.995L893.723 481.434V436.174L873.91 424.734V469.995ZM894.261 459.364L914.075 470.803L953.268 448.173L933.455 436.734L894.261 459.364ZM894.084 436.174V459.052L933.278 436.422V413.543L894.084 436.174ZM933.632 436.422L953.445 447.861V424.982L933.632 413.543V436.422ZM854.706 413.231L893.9 435.861L913.713 424.422L874.519 401.792L854.706 413.231ZM834.539 424.876L853.565 435.861L873.378 424.422L854.352 413.437L834.539 424.876ZM834.361 401.693V424.571L854.174 413.132V390.253L834.361 401.693ZM933.632 413.132L953.445 424.571V401.693L933.632 390.253V413.132ZM914.252 401.232V424.11L933.278 413.125V390.246L914.252 401.232ZM874.697 401.48L913.897 424.11V401.232L874.697 378.601V401.48ZM854.529 390.04V412.919L874.342 401.48V378.601L854.529 390.04Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M767.489 408.557V362.884L748.108 351.693V328.198L788.628 304.809L828.176 327.645L847.557 316.454L867.901 328.205V374.997L828.346 397.834V420.216L808.001 431.96L767.489 408.557ZM788.188 420.102L808.001 431.541L827.814 420.102L808.001 408.663L788.188 420.102ZM768.021 408.457L787.834 419.897L807.647 408.457L787.834 397.018L768.021 408.457ZM808.186 408.351L827.999 419.79V397.819L808.186 386.38V408.351ZM767.843 362.884V408.152L787.657 396.713V351.445L767.843 362.884ZM788.011 396.706L807.824 408.145V362.884L788.011 351.445V396.706ZM808.363 386.075L828.176 397.514L867.37 374.884L847.557 363.445L808.363 386.075ZM808.186 362.884V385.763L847.379 363.133V340.254L808.186 362.884ZM847.734 363.133L867.547 374.572V351.693L847.734 340.254V363.133ZM768.808 339.942L808.001 362.572L827.814 351.133L788.621 328.503L768.808 339.942ZM748.64 351.587L767.666 362.572L787.479 351.133L768.453 340.148L748.64 351.587ZM748.463 328.403V351.282L768.276 339.843V316.964L748.463 328.403ZM847.734 339.843L867.547 351.282V328.403L847.734 316.964V339.843ZM828.353 327.943V350.821L847.379 339.836V316.957L828.353 327.943ZM788.798 328.191L827.999 350.821V327.943L788.798 305.312V328.191ZM768.631 316.751V339.63L788.444 328.191V305.312L768.631 316.751Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M874.666 369.941V324.269L855.285 313.078V289.583L895.805 266.193L935.353 289.029L954.733 277.838L975.078 289.59V336.382L935.523 359.218V381.6L915.178 393.345L874.666 369.941ZM895.365 381.487L915.178 392.926L934.991 381.487L915.178 370.048L895.365 381.487ZM875.197 369.842L895.011 381.281L914.824 369.842L895.011 358.403L875.197 369.842ZM915.362 369.736L935.176 381.175V359.204L915.362 347.765V369.736ZM875.02 324.269V369.537L894.833 358.098V312.83L875.02 324.269ZM895.188 358.091L915.001 369.53V324.269L895.188 312.83V358.091ZM915.54 347.46L935.353 358.899L974.546 336.269L954.733 324.829L915.54 347.46ZM915.362 324.269V347.148L954.556 324.517V301.639L915.362 324.269ZM954.911 324.517L974.724 335.957V313.078L954.911 301.639V324.517ZM875.985 301.327L915.178 323.957L934.991 312.518L895.798 289.888L875.985 301.327ZM855.817 312.972L874.843 323.957L894.656 312.518L875.63 301.532L855.817 312.972ZM855.64 289.788V312.667L875.453 301.228V278.349L855.64 289.788ZM954.911 301.228L974.724 312.667V289.788L954.911 278.349V301.228ZM935.53 289.327V312.206L954.556 301.22V278.342L935.53 289.327ZM895.975 289.575L935.176 312.206V289.327L895.975 266.697V289.575ZM875.807 278.136V301.015L895.62 289.575V266.697L875.807 278.136Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M680.015 334.478V288.806L660.635 277.615V254.12L701.154 230.73L740.702 253.567L760.083 242.375L780.428 254.127V300.919L740.873 323.755V346.138L720.528 357.882L680.015 334.478ZM700.715 346.024L720.528 357.463L740.341 346.024L720.528 334.585L700.715 346.024ZM680.547 334.379L700.36 345.818L720.173 334.379L700.36 322.94L680.547 334.379ZM720.712 334.273L740.525 345.712V323.741L720.712 312.302V334.273ZM680.37 288.806V334.074L700.183 322.635V277.367L680.37 288.806ZM700.537 322.628L720.35 334.067V288.806L700.537 277.367V322.628ZM720.889 311.997L740.702 323.436L779.896 300.806L760.083 289.367L720.889 311.997ZM720.712 288.806V311.685L759.906 289.055V266.176L720.712 288.806ZM760.26 289.055L780.073 300.494V277.615L760.26 266.176V289.055ZM681.334 265.864L720.528 288.494L740.341 277.055L701.147 254.425L681.334 265.864ZM661.167 277.509L680.192 288.494L700.006 277.055L680.98 266.07L661.167 277.509ZM660.989 254.325V277.204L680.802 265.765V242.886L660.989 254.325ZM760.26 265.765L780.073 277.204V254.325L760.26 242.886V265.765ZM740.88 253.864V276.743L759.906 265.758V242.879L740.88 253.864ZM701.325 254.113L740.525 276.743V253.864L701.325 231.234V254.113ZM681.157 242.673V265.552L700.97 254.113V231.234L681.157 242.673Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M484.577 298.228V252.556L465.196 241.365V217.87L505.716 194.48L545.264 217.317L564.644 206.125L584.989 217.877V264.669L545.434 287.505V309.888L525.089 321.632L484.577 298.228ZM505.276 309.774L525.089 321.213L544.902 309.774L525.089 298.335L505.276 309.774ZM485.109 298.129L504.922 309.568L524.735 298.129L504.922 286.69L485.109 298.129ZM525.274 298.023L545.087 309.462V287.491L525.274 276.052V298.023ZM484.931 252.556V297.824L504.744 286.385V241.117L484.931 252.556ZM505.099 286.378L524.912 297.817V252.556L505.099 241.117V286.378ZM525.451 275.747L545.264 287.186L584.458 264.556L564.644 253.117L525.451 275.747ZM525.274 252.556V275.435L564.467 252.805V229.926L525.274 252.556ZM564.822 252.805L584.635 264.244V241.365L564.822 229.926V252.805ZM485.896 229.614L525.089 252.244L544.902 240.805L505.709 218.175L485.896 229.614ZM465.728 241.259L484.754 252.244L504.567 240.805L485.541 229.82L465.728 241.259ZM465.551 218.075V240.954L485.364 229.515V206.636L465.551 218.075ZM564.822 229.515L584.635 240.954V218.075L564.822 206.636V229.515ZM545.441 217.614V240.493L564.467 229.508V206.629L545.441 217.614ZM505.886 217.863L545.087 240.493V217.614L505.886 194.984V217.863ZM485.719 206.423V229.302L505.532 217.863V194.984L485.719 206.423Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M289.138 265.13V219.458L269.758 208.267V184.771L310.277 161.382L349.826 184.218L369.206 173.027L389.551 184.778V231.571L349.996 254.407V276.789L329.651 288.533L289.138 265.13ZM309.838 276.675L329.651 288.115L349.464 276.675L329.651 265.236L309.838 276.675ZM289.67 265.03L309.483 276.47L329.296 265.03L309.483 253.591L289.67 265.03ZM329.835 264.924L349.648 276.363V254.393L329.835 242.953V264.924ZM289.493 219.458V264.726L309.306 253.286V208.018L289.493 219.458ZM309.66 253.279L329.473 264.718V219.458L309.66 208.018V253.279ZM330.012 242.648L349.826 254.088L389.019 231.457L369.206 220.018L330.012 242.648ZM329.835 219.458V242.336L369.029 219.706V196.827L329.835 219.458ZM369.383 219.706L389.196 231.145V208.267L369.383 196.827V219.706ZM290.457 196.515L329.651 219.146L349.464 207.706L310.27 185.076L290.457 196.515ZM270.29 208.16L289.316 219.146L309.129 207.706L290.103 196.721L270.29 208.16ZM270.112 184.977V207.855L289.925 196.416V173.537L270.112 184.977ZM369.383 196.416L389.196 207.855V184.977L369.383 173.537V196.416ZM350.003 184.516V207.394L369.029 196.409V173.53L350.003 184.516ZM310.448 184.764L349.648 207.394V184.516L310.448 161.885V184.764ZM290.28 173.325V196.203L310.093 184.764V161.885L290.28 173.325Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M88.6588 226.726V181.054L69.2783 169.863V146.368L109.798 122.979L149.346 145.815L168.726 134.623L189.071 146.375V193.167L149.516 216.003V238.386L129.171 250.13L88.6588 226.726ZM109.358 238.272L129.171 249.711L148.984 238.272L129.171 226.833L109.358 238.272ZM89.1906 226.627L109.004 238.066L128.817 226.627L109.004 215.188L89.1906 226.627ZM129.356 226.521L149.169 237.96V215.989L129.356 204.55V226.521ZM89.0133 181.054V226.322L108.826 214.883V169.615L89.0133 181.054ZM109.181 214.876L128.994 226.315V181.054L109.181 169.615V214.876ZM129.533 204.245L149.346 215.684L188.54 193.054L168.726 181.615L129.533 204.245ZM129.356 181.054V203.933L168.549 181.303V158.424L129.356 181.054ZM168.904 181.303L188.717 192.742V169.863L168.904 158.424V181.303ZM89.9778 158.112L129.171 180.742L148.984 169.303L109.791 146.673L89.9778 158.112ZM69.8102 169.757L88.836 180.742L108.649 169.303L89.6233 158.318L69.8102 169.757ZM69.6329 146.573V169.452L89.446 158.013V135.134L69.6329 146.573ZM168.904 158.013L188.717 169.452V146.573L168.904 135.134V158.013ZM149.523 146.112V168.991L168.549 158.006V135.127L149.523 146.112ZM109.968 146.361L149.169 168.991V146.112L109.968 123.482V146.361ZM89.8005 134.921V157.8L109.614 146.361V123.482L89.8005 134.921Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M110.014 107.748V62.0759L90.6338 50.8848V27.3892L131.153 4L170.701 26.836L190.082 15.645L210.427 27.3963V74.1889L170.872 97.0249V119.407L150.527 131.151L110.014 107.748ZM130.714 119.294L150.527 130.733L170.34 119.294L150.527 107.854L130.714 119.294ZM110.546 107.649L130.359 119.088L150.172 107.649L130.359 96.2093L110.546 107.649ZM150.711 107.542L170.524 118.982V97.0107L150.711 85.5714V107.542ZM110.369 62.0759V107.344L130.182 95.9044V50.6366L110.369 62.0759ZM130.536 95.8973L150.349 107.337V62.0759L130.536 50.6366V95.8973ZM150.888 85.2665L170.701 96.7058L209.895 74.0754L190.082 62.6361L150.888 85.2665ZM150.711 62.0759V84.9544L189.905 62.3241V39.4455L150.711 62.0759ZM190.259 62.3241L210.072 73.7634V50.8848L190.259 39.4455V62.3241ZM111.333 39.1334L150.527 61.7638L170.34 50.3245L131.146 27.6942L111.333 39.1334ZM91.1656 50.7784L110.192 61.7638L130.005 50.3245L110.979 39.3391L91.1656 50.7784ZM90.9884 27.5949V50.4735L110.801 39.0342V16.1556L90.9884 27.5949ZM190.259 39.0342L210.072 50.4735V27.5949L190.259 16.1556V39.0342ZM170.879 27.1339V50.0125L189.905 39.0271V16.1485L170.879 27.1339ZM131.324 27.3821L170.524 50.0125V27.1339L131.324 4.50354V27.3821ZM111.156 15.9428V38.8214L130.969 27.3821V4.50354L111.156 15.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M221.014 67.7479V22.0759L201.634 10.8848V-12.6108L242.153 -36L281.701 -13.164L301.082 -24.355L321.427 -12.6037V34.1889L281.872 57.0249V79.4071L261.527 91.1513L221.014 67.7479ZM241.714 79.2936L261.527 90.7329L281.34 79.2936L261.527 67.8543L241.714 79.2936ZM221.546 67.6486L241.359 79.0879L261.172 67.6486L241.359 56.2093L221.546 67.6486ZM261.711 67.5422L281.524 78.9815V57.0107L261.711 45.5714V67.5422ZM221.369 22.0759V67.3437L241.182 55.9044V10.6366L221.369 22.0759ZM241.536 55.8973L261.349 67.3366V22.0759L241.536 10.6366V55.8973ZM261.888 45.2665L281.701 56.7058L320.895 34.0754L301.082 22.6361L261.888 45.2665ZM261.711 22.0759V44.9544L300.905 22.3241V-0.554506L261.711 22.0759ZM301.259 22.3241L321.072 33.7634V10.8848L301.259 -0.554506V22.3241ZM222.333 -0.866552L261.527 21.7638L281.34 10.3245L242.146 -12.3058L222.333 -0.866552ZM202.166 10.7784L221.192 21.7638L241.005 10.3245L221.979 -0.66088L202.166 10.7784ZM201.988 -12.4051V10.4735L221.801 -0.965828V-23.8444L201.988 -12.4051ZM301.259 -0.965828L321.072 10.4735V-12.4051L301.259 -23.8444V-0.965828ZM281.879 -12.8661V10.0125L300.905 -0.972927V-23.8515L281.879 -12.8661ZM242.324 -12.6179L281.524 10.0125V-12.8661L242.324 -35.4965V-12.6179ZM222.156 -24.0572V-1.1786L241.969 -12.6179V-35.4965L222.156 -24.0572Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M524.014 58.7479V13.0759L504.634 1.88479V-21.6108L545.153 -45L584.701 -22.164L604.082 -33.355L624.427 -21.6037V25.1889L584.872 48.0249V70.4071L564.527 82.1513L524.014 58.7479ZM544.714 70.2936L564.527 81.7329L584.34 70.2936L564.527 58.8543L544.714 70.2936ZM524.546 58.6486L544.359 70.0879L564.172 58.6486L544.359 47.2093L524.546 58.6486ZM564.711 58.5422L584.524 69.9815V48.0107L564.711 36.5714V58.5422ZM524.369 13.0759V58.3437L544.182 46.9044V1.63657L524.369 13.0759ZM544.536 46.8973L564.349 58.3366V13.0759L544.536 1.63657V46.8973ZM564.888 36.2665L584.701 47.7058L623.895 25.0754L604.082 13.6361L564.888 36.2665ZM564.711 13.0759V35.9544L603.905 13.3241V-9.55451L564.711 13.0759ZM604.259 13.3241L624.072 24.7634V1.88479L604.259 -9.55451V13.3241ZM525.333 -9.86655L564.527 12.7638L584.34 1.32452L545.146 -21.3058L525.333 -9.86655ZM505.166 1.77841L524.192 12.7638L544.005 1.32452L524.979 -9.66088L505.166 1.77841ZM504.988 -21.4051V1.47346L524.801 -9.96583V-32.8444L504.988 -21.4051ZM604.259 -9.96583L624.072 1.47346V-21.4051L604.259 -32.8444V-9.96583ZM584.879 -21.8661V1.01247L603.905 -9.97293V-32.8515L584.879 -21.8661ZM545.324 -21.6179L584.524 1.01247V-21.8661L545.324 -44.4965V-21.6179ZM525.156 -33.0572V-10.1786L544.969 -21.6179V-44.4965L525.156 -33.0572Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M416.014 103.748V58.0759L396.634 46.8848V23.3892L437.153 0L476.701 22.836L496.082 11.645L516.427 23.3963V70.1889L476.872 93.0249V115.407L456.527 127.151L416.014 103.748ZM436.714 115.294L456.527 126.733L476.34 115.294L456.527 103.854L436.714 115.294ZM416.546 103.649L436.359 115.088L456.172 103.649L436.359 92.2093L416.546 103.649ZM456.711 103.542L476.524 114.982V93.0107L456.711 81.5714V103.542ZM416.369 58.0759V103.344L436.182 91.9044V46.6366L416.369 58.0759ZM436.536 91.8973L456.349 103.337V58.0759L436.536 46.6366V91.8973ZM456.888 81.2665L476.701 92.7058L515.895 70.0754L496.082 58.6361L456.888 81.2665ZM456.711 58.0759V80.9544L495.905 58.3241V35.4455L456.711 58.0759ZM496.259 58.3241L516.072 69.7634V46.8848L496.259 35.4455V58.3241ZM417.333 35.1334L456.527 57.7638L476.34 46.3245L437.146 23.6942L417.333 35.1334ZM397.166 46.7784L416.192 57.7638L436.005 46.3245L416.979 35.3391L397.166 46.7784ZM396.988 23.5949V46.4735L416.801 35.0342V12.1556L396.988 23.5949ZM496.259 35.0342L516.072 46.4735V23.5949L496.259 12.1556V35.0342ZM476.879 23.1339V46.0125L495.905 35.0271V12.1485L476.879 23.1339ZM437.324 23.3821L476.524 46.0125V23.1339L437.324 0.503543V23.3821ZM417.156 11.9428V34.8214L436.969 23.3821V0.503543L417.156 11.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M201.014 186.15V140.478L181.634 129.287V105.792L222.153 82.4023L261.701 105.238L281.082 94.0473L301.427 105.799V152.591L261.872 175.427V197.809L241.527 209.554L201.014 186.15ZM221.714 197.696L241.527 209.135L261.34 197.696L241.527 186.257L221.714 197.696ZM201.546 186.051L221.359 197.49L241.172 186.051L221.359 174.612L201.546 186.051ZM241.711 185.945L261.524 197.384V175.413L241.711 163.974V185.945ZM201.369 140.478V185.746L221.182 174.307V129.039L201.369 140.478ZM221.536 174.3L241.349 185.739V140.478L221.536 129.039V174.3ZM241.888 163.669L261.701 175.108L300.895 152.478L281.082 141.038L241.888 163.669ZM241.711 140.478V163.357L280.905 140.726V117.848L241.711 140.478ZM281.259 140.726L301.072 152.166V129.287L281.259 117.848V140.726ZM202.333 117.536L241.527 140.166L261.34 128.727L222.146 106.096L202.333 117.536ZM182.166 129.181L201.192 140.166L221.005 128.727L201.979 117.741L182.166 129.181ZM181.988 105.997V128.876L201.801 117.437V94.5579L181.988 105.997ZM281.259 117.437L301.072 128.876V105.997L281.259 94.5579V117.437ZM261.879 105.536V128.415L280.905 117.429V94.5508L261.879 105.536ZM222.324 105.784L261.524 128.415V105.536L222.324 82.9059V105.784ZM202.156 94.3452V117.224L221.969 105.784V82.9059L202.156 94.3452Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M0.534744 147.748V102.076L-18.8457 90.8848V67.3892L21.6739 44L61.222 66.836L80.6025 55.645L100.947 67.3963V114.189L61.3922 137.025V159.407L41.0473 171.151L0.534744 147.748ZM21.2342 159.294L41.0473 170.733L60.8603 159.294L41.0473 147.854L21.2342 159.294ZM1.06659 147.649L20.8797 159.088L40.6927 147.649L20.8797 136.209L1.06659 147.649ZM41.2316 147.542L61.0447 158.982V137.011L41.2316 125.571V147.542ZM0.889309 102.076V147.344L20.7024 135.904V90.6366L0.889309 102.076ZM21.057 135.897L40.87 147.337V102.076L21.057 90.6366V135.897ZM41.4089 125.266L61.222 136.706L100.416 114.075L80.6025 102.636L41.4089 125.266ZM41.2316 102.076V124.954L80.4252 102.324V79.4455L41.2316 102.076ZM80.7797 102.324L100.593 113.763V90.8848L80.7797 79.4455V102.324ZM1.8538 79.1334L41.0473 101.764L60.8603 90.3245L21.6668 67.6942L1.8538 79.1334ZM-18.3139 90.7784L0.712026 101.764L20.5251 90.3245L1.49924 79.3391L-18.3139 90.7784ZM-18.4911 67.5949V90.4735L1.32195 79.0342V56.1556L-18.4911 67.5949ZM80.7797 79.0342L100.593 90.4735V67.5949L80.7797 56.1556V79.0342ZM61.3993 67.1339V90.0125L80.4252 79.0271V56.1485L61.3993 67.1339ZM21.8441 67.3821L61.0447 90.0125V67.1339L21.8441 44.5035V67.3821ZM1.67652 55.9428V78.8214L21.4895 67.3821V44.5035L1.67652 55.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M181.174 304.533V258.861L161.794 247.67V224.174L202.314 200.785L241.862 223.621L261.242 212.43L281.587 224.181V270.974L242.032 293.81V316.192L221.687 327.936L181.174 304.533ZM201.874 316.079L221.687 327.518L241.5 316.079L221.687 304.639L201.874 316.079ZM181.706 304.434L201.519 315.873L221.332 304.434L201.519 292.994L181.706 304.434ZM221.871 304.327L241.684 315.767V293.796L221.871 282.357V304.327ZM181.529 258.861V304.129L201.342 292.69V247.422L181.529 258.861ZM201.697 292.682L221.51 304.122V258.861L201.697 247.422V292.682ZM222.049 282.052L241.862 293.491L281.055 270.861L261.242 259.421L222.049 282.052ZM221.871 258.861V281.74L261.065 259.109V236.231L221.871 258.861ZM261.419 259.109L281.232 270.549V247.67L261.419 236.231V259.109ZM182.493 235.919L221.687 258.549L241.5 247.11L202.306 224.479L182.493 235.919ZM162.326 247.564L181.352 258.549L201.165 247.11L182.139 236.124L162.326 247.564ZM162.149 224.38V247.259L181.962 235.819V212.941L162.149 224.38ZM261.419 235.819L281.232 247.259V224.38L261.419 212.941V235.819ZM242.039 223.919V246.798L261.065 235.812V212.934L242.039 223.919ZM202.484 224.167L241.684 246.798V223.919L202.484 201.289V224.167ZM182.316 212.728V235.607L202.129 224.167V201.289L182.316 212.728Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M-19.3051 266.13V220.458L-38.6855 209.267V185.771L1.83406 162.382L41.3822 185.218L60.7626 174.027L81.1075 185.778V232.571L41.5523 255.407V277.789L21.2074 289.533L-19.3051 266.13ZM1.3944 277.675L21.2074 289.115L41.0205 277.675L21.2074 266.236L1.3944 277.675ZM-18.7733 266.03L1.03984 277.47L20.8528 266.03L1.03984 254.591L-18.7733 266.03ZM21.3918 265.924L41.2049 277.363V255.393L21.3918 243.953V265.924ZM-18.9505 220.458V265.726L0.862557 254.286V209.018L-18.9505 220.458ZM1.21712 254.279L21.0301 265.718V220.458L1.21712 209.018V254.279ZM21.5691 243.648L41.3822 255.088L80.5757 232.457L60.7626 221.018L21.5691 243.648ZM21.3918 220.458V243.336L60.5853 220.706V197.827L21.3918 220.458ZM60.9399 220.706L80.753 232.145V209.267L60.9399 197.827V220.706ZM-17.986 197.515L21.2074 220.146L41.0205 208.706L1.82696 186.076L-17.986 197.515ZM-38.1537 209.16L-19.1278 220.146L0.685275 208.706L-18.3406 197.721L-38.1537 209.16ZM-38.331 185.977V208.855L-18.5179 197.416V174.537L-38.331 185.977ZM60.9399 197.416L80.753 208.855V185.977L60.9399 174.537V197.416ZM41.5594 185.516V208.394L60.5853 197.409V174.53L41.5594 185.516ZM2.00424 185.764L41.2049 208.394V185.516L2.00424 162.885V185.764ZM-18.1633 174.325V197.203L1.64968 185.764V162.885L-18.1633 174.325Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M397.102 225.726V180.054L377.722 168.863V145.368L418.241 121.979L457.789 144.815L477.17 133.623L497.515 145.375V192.167L457.96 215.003V237.386L437.615 249.13L397.102 225.726ZM417.802 237.272L437.615 248.711L457.428 237.272L437.615 225.833L417.802 237.272ZM397.634 225.627L417.447 237.066L437.26 225.627L417.447 214.188L397.634 225.627ZM437.799 225.521L457.612 236.96V214.989L437.799 203.55V225.521ZM397.457 180.054V225.322L417.27 213.883V168.615L397.457 180.054ZM417.624 213.876L437.437 225.315V180.054L417.624 168.615V213.876ZM437.976 203.245L457.789 214.684L496.983 192.054L477.17 180.615L437.976 203.245ZM437.799 180.054V202.933L476.993 180.303V157.424L437.799 180.054ZM477.347 180.303L497.16 191.742V168.863L477.347 157.424V180.303ZM398.421 157.112L437.615 179.742L457.428 168.303L418.234 145.673L398.421 157.112ZM378.254 168.757L397.279 179.742L417.093 168.303L398.067 157.318L378.254 168.757ZM378.076 145.573V168.452L397.889 157.013V134.134L378.076 145.573ZM477.347 157.013L497.16 168.452V145.573L477.347 134.134V157.013ZM457.967 145.112V167.991L476.993 157.006V134.127L457.967 145.112ZM418.411 145.361L457.612 167.991V145.112L418.411 122.482V145.361ZM398.244 133.921V156.8L418.057 145.361V122.482L398.244 133.921Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M308.978 146.748V101.076L289.598 89.8848V66.3892L330.117 43L369.665 65.836L389.046 54.645L409.391 66.3963V113.189L369.836 136.025V158.407L349.491 170.151L308.978 146.748ZM329.678 158.294L349.491 169.733L369.304 158.294L349.491 146.854L329.678 158.294ZM309.51 146.649L329.323 158.088L349.136 146.649L329.323 135.209L309.51 146.649ZM349.675 146.542L369.488 157.982V136.011L349.675 124.571V146.542ZM309.333 101.076V146.344L329.146 134.904V89.6366L309.333 101.076ZM329.5 134.897L349.313 146.337V101.076L329.5 89.6366V134.897ZM349.852 124.266L369.665 135.706L408.859 113.075L389.046 101.636L349.852 124.266ZM349.675 101.076V123.954L388.869 101.324V78.4455L349.675 101.076ZM389.223 101.324L409.036 112.763V89.8848L389.223 78.4455V101.324ZM310.297 78.1334L349.491 100.764L369.304 89.3245L330.11 66.6942L310.297 78.1334ZM290.13 89.7784L309.155 100.764L328.968 89.3245L309.943 78.3391L290.13 89.7784ZM289.952 66.5949V89.4735L309.765 78.0342V55.1556L289.952 66.5949ZM389.223 78.0342L409.036 89.4735V66.5949L389.223 55.1556V78.0342ZM369.843 66.1339V89.0125L388.869 78.0271V55.1485L369.843 66.1339ZM330.287 66.3821L369.488 89.0125V66.1339L330.287 43.5035V66.3821ZM310.12 54.9428V77.8214L329.933 66.3821V43.5035L310.12 54.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M505.066 184.748V139.076L485.686 127.885V104.389L526.205 81L565.753 103.836L585.134 92.645L605.479 104.396V151.189L565.923 174.025V196.407L545.579 208.151L505.066 184.748ZM525.765 196.294L545.579 207.733L565.392 196.294L545.579 184.854L525.765 196.294ZM505.598 184.649L525.411 196.088L545.224 184.649L525.411 173.209L505.598 184.649ZM545.763 184.542L565.576 195.982V174.011L545.763 162.571V184.542ZM505.421 139.076V184.344L525.234 172.904V127.637L505.421 139.076ZM525.588 172.897L545.401 184.337V139.076L525.588 127.637V172.897ZM545.94 162.266L565.753 173.706L604.947 151.075L585.134 139.636L545.94 162.266ZM545.763 139.076V161.954L584.956 139.324V116.445L545.763 139.076ZM585.311 139.324L605.124 150.763V127.885L585.311 116.445V139.324ZM506.385 116.133L545.579 138.764L565.392 127.325L526.198 104.694L506.385 116.133ZM486.217 127.778L505.243 138.764L525.056 127.325L506.03 116.339L486.217 127.778ZM486.04 104.595V127.473L505.853 116.034V93.1556L486.04 104.595ZM585.311 116.034L605.124 127.473V104.595L585.311 93.1556V116.034ZM565.931 104.134V127.012L584.956 116.027V93.1485L565.931 104.134ZM526.375 104.382L565.576 127.012V104.134L526.375 81.5035V104.382ZM506.208 92.9428V115.821L526.021 104.382V81.5035L506.208 92.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M591.753 259.613V213.941L572.372 202.75V179.254L612.892 155.865L652.44 178.701L671.82 167.51L692.165 179.262V226.054L652.61 248.89V271.272L632.265 283.017L591.753 259.613ZM612.452 271.159L632.265 282.598L652.078 271.159L632.265 259.72L612.452 271.159ZM592.284 259.514L612.097 270.953L631.91 259.514L612.097 248.075L592.284 259.514ZM632.449 259.407L652.262 270.847V248.876L632.449 237.437V259.407ZM592.107 213.941V259.209L611.92 247.77V202.502L592.107 213.941ZM612.275 247.763L632.088 259.202V213.941L612.275 202.502V247.763ZM632.627 237.132L652.44 248.571L691.633 225.941L671.82 214.501L632.627 237.132ZM632.449 213.941V236.82L671.643 214.189V191.311L632.449 213.941ZM671.998 214.189L691.811 225.629V202.75L671.998 191.311V214.189ZM593.072 190.999L632.265 213.629L652.078 202.19L612.885 179.559L593.072 190.999ZM572.904 202.644L591.93 213.629L611.743 202.19L592.717 191.204L572.904 202.644ZM572.727 179.46V202.339L592.54 190.899V168.021L572.727 179.46ZM671.998 190.899L691.811 202.339V179.46L671.998 168.021V190.899ZM652.617 178.999V201.878L671.643 190.892V168.014L652.617 178.999ZM613.062 179.247L652.262 201.878V178.999L613.062 156.369V179.247ZM592.894 167.808V190.687L612.707 179.247V156.369L592.894 167.808Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M612.014 147.748V102.076L592.634 90.8848V67.3892L633.153 44L672.701 66.836L692.082 55.645L712.427 67.3963V114.189L672.872 137.025V159.407L652.527 171.151L612.014 147.748ZM632.714 159.294L652.527 170.733L672.34 159.294L652.527 147.854L632.714 159.294ZM612.546 147.649L632.359 159.088L652.172 147.649L632.359 136.209L612.546 147.649ZM652.711 147.542L672.524 158.982V137.011L652.711 125.571V147.542ZM612.369 102.076V147.344L632.182 135.904V90.6366L612.369 102.076ZM632.536 135.897L652.349 147.337V102.076L632.536 90.6366V135.897ZM652.888 125.266L672.701 136.706L711.895 114.075L692.082 102.636L652.888 125.266ZM652.711 102.076V124.954L691.905 102.324V79.4455L652.711 102.076ZM692.259 102.324L712.072 113.763V90.8848L692.259 79.4455V102.324ZM613.333 79.1334L652.527 101.764L672.34 90.3245L633.146 67.6942L613.333 79.1334ZM593.166 90.7784L612.192 101.764L632.005 90.3245L612.979 79.3391L593.166 90.7784ZM592.988 67.5949V90.4735L612.801 79.0342V56.1556L592.988 67.5949ZM692.259 79.0342L712.072 90.4735V67.5949L692.259 56.1556V79.0342ZM672.879 67.1339V90.0125L691.905 79.0271V56.1485L672.879 67.1339ZM633.324 67.3821L672.524 90.0125V67.1339L633.324 44.5035V67.3821ZM613.156 55.9428V78.8214L632.969 67.3821V44.5035L613.156 55.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1264.75 436.927V391.255L1245.37 380.063V356.568L1285.89 333.179L1325.44 356.015L1344.82 344.824L1365.17 356.575V403.368L1325.61 426.204V448.586L1305.27 460.33L1264.75 436.927ZM1285.45 448.472L1305.27 459.912L1325.08 448.472L1305.27 437.033L1285.45 448.472ZM1265.29 436.827L1285.1 448.267L1304.91 436.827L1285.1 425.388L1265.29 436.827ZM1305.45 436.721L1325.26 448.16V426.189L1305.45 414.75V436.721ZM1265.11 391.255V436.522L1284.92 425.083V379.815L1265.11 391.255ZM1285.28 425.076L1305.09 436.515V391.255L1285.28 379.815V425.076ZM1305.63 414.445L1325.44 425.884L1364.63 403.254L1344.82 391.815L1305.63 414.445ZM1305.45 391.255V414.133L1344.64 391.503V368.624L1305.45 391.255ZM1345 391.503L1364.81 402.942V380.063L1345 368.624V391.503ZM1266.07 368.312L1305.27 390.943L1325.08 379.503L1285.89 356.873L1266.07 368.312ZM1245.9 379.957L1264.93 390.943L1284.74 379.503L1265.72 368.518L1245.9 379.957ZM1245.73 356.774V379.652L1265.54 368.213V345.334L1245.73 356.774ZM1345 368.213L1364.81 379.652V356.774L1345 345.334V368.213ZM1325.62 356.313V379.191L1344.64 368.206V345.327L1325.62 356.313ZM1286.06 356.561L1325.26 379.191V356.313L1286.06 333.682V356.561ZM1265.9 345.122V368L1285.71 356.561V333.682L1265.9 345.122Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1069.32 403.04V357.368L1049.93 346.177V322.681L1090.45 299.292L1130 322.128L1149.38 310.937L1169.73 322.688V369.481L1130.17 392.317V414.699L1109.83 426.443L1069.32 403.04ZM1090.01 414.586L1109.83 426.025L1129.64 414.586L1109.83 403.146L1090.01 414.586ZM1069.85 402.941L1089.66 414.38L1109.47 402.941L1089.66 391.501L1069.85 402.941ZM1110.01 402.834L1129.82 414.274V392.303L1110.01 380.863V402.834ZM1069.67 357.368V402.636L1089.48 391.196V345.929L1069.67 357.368ZM1089.84 391.189L1109.65 402.629V357.368L1089.84 345.929V391.189ZM1110.19 380.558L1130 391.998L1169.2 369.367L1149.38 357.928L1110.19 380.558ZM1110.01 357.368V380.246L1149.21 357.616V334.737L1110.01 357.368ZM1149.56 357.616L1169.37 369.055V346.177L1149.56 334.737V357.616ZM1070.63 334.425L1109.83 357.056L1129.64 345.617L1090.45 322.986L1070.63 334.425ZM1050.47 346.07L1069.49 357.056L1089.31 345.617L1070.28 334.631L1050.47 346.07ZM1050.29 322.887V345.765L1070.1 334.326V311.448L1050.29 322.887ZM1149.56 334.326L1169.37 345.765V322.887L1149.56 311.448V334.326ZM1130.18 322.426V345.304L1149.21 334.319V311.44L1130.18 322.426ZM1090.62 322.674L1129.82 345.304V322.426L1090.62 299.796V322.674ZM1070.46 311.235V334.113L1090.27 322.674V299.796L1070.46 311.235Z"
              fill="#192530"
            ></path>
          </g>
          <g class="">
            {" "}
            <path
              d="M833.686 595.567V549.888L814.306 538.697V515.202L854.825 491.812L894.373 514.649L913.754 503.457L934.099 515.209V562.001L894.544 584.837V607.22L874.199 618.964L833.686 595.567ZM854.386 607.113L874.199 618.552L894.012 607.113L874.199 595.674L854.386 607.113ZM834.218 595.468L854.031 606.907L873.844 595.468L854.031 584.029L834.218 595.468ZM874.376 595.362L894.189 606.801V584.83L874.376 573.391V595.362ZM834.041 549.895V595.163L853.854 583.724V538.463L834.041 549.895ZM854.208 583.717L874.021 595.156V549.895L854.208 538.456V583.717ZM874.553 573.079L894.366 584.518L933.56 561.888L913.747 550.449L874.553 573.079ZM874.376 549.895V572.774L913.569 550.144V527.265L874.376 549.895ZM913.931 550.144L933.744 561.583V538.704L913.931 527.265V550.144ZM835.005 526.953L874.199 549.583L894.012 538.144L854.818 515.514L835.005 526.953ZM814.838 538.598L833.863 549.583L853.676 538.144L834.651 527.159L814.838 538.598ZM814.653 515.407V538.286L834.466 526.847V503.968L814.653 515.407ZM913.931 526.854L933.744 538.293V515.414L913.931 503.975V526.854ZM894.544 514.953V537.832L913.569 526.847V503.968L894.544 514.953ZM854.995 515.202L894.196 537.832V514.953L854.995 492.323V515.202ZM834.821 503.762V526.641L854.634 515.202V492.323L834.821 503.762Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M813.197 706.449V660.77L793.816 649.579V626.084L834.336 602.694L873.884 625.53L893.265 614.339L913.609 626.091V672.883L874.054 695.719V718.101L853.709 729.846L813.197 706.449ZM833.896 717.995L853.709 729.434L873.522 717.995L853.709 706.556L833.896 717.995ZM813.729 706.35L833.542 717.789L853.355 706.35L833.542 694.911L813.729 706.35ZM853.887 706.244L873.7 717.683V695.712L853.887 684.273V706.244ZM813.551 660.777V706.045L833.364 694.606V649.345L813.551 660.777ZM833.719 694.599L853.532 706.038V660.777L833.719 649.338V694.599ZM854.064 683.961L873.877 695.4L913.071 672.77L893.257 661.33L854.064 683.961ZM853.887 660.777V683.656L893.08 661.025V638.147L853.887 660.777ZM893.442 661.025L913.255 672.465V649.586L893.442 638.147V661.025ZM814.516 637.835L853.709 660.465L873.522 649.026L834.329 626.396L814.516 637.835ZM794.348 649.48L813.374 660.465L833.187 649.026L814.161 638.041L794.348 649.48ZM794.164 626.289V649.168L813.977 637.728V614.85L794.164 626.289ZM893.442 637.736L913.255 649.175V626.296L893.442 614.857V637.736ZM874.054 625.835V648.714L893.08 637.728V614.85L874.054 625.835ZM834.506 626.084L873.707 648.714V625.835L834.506 603.205V626.084ZM814.331 614.644V637.523L834.145 626.084V603.205L814.331 614.644Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M921.086 668.493V622.814L901.705 611.623V588.127L942.225 564.738L981.773 587.574L1001.15 576.383L1021.5 588.127V634.92L981.943 657.756V680.138L961.598 691.882L921.086 668.493ZM941.792 680.039L961.605 691.478L981.418 680.039L961.605 668.6L941.792 680.039ZM921.624 668.394L941.438 679.833L961.251 668.394L941.438 656.955L921.624 668.394ZM961.782 668.288L981.595 679.727V657.756L961.782 646.317V668.288ZM921.44 622.821V668.082L941.253 656.643V611.375L921.44 622.821ZM941.615 656.643L961.428 668.082V622.821L941.615 611.382V656.643ZM961.96 646.012L981.773 657.451L1020.97 634.821L1001.15 623.381L961.96 646.012ZM961.782 622.821V645.7L1000.98 623.069V600.191L961.782 622.821ZM1001.34 623.069L1021.15 634.509V611.63L1001.34 600.191V623.069ZM922.412 599.879L961.605 622.509L981.418 611.07L942.225 588.44L922.412 599.879ZM902.237 611.524L921.263 622.509L941.076 611.07L922.05 600.084L902.237 611.524ZM902.06 588.333V611.212L921.873 599.772V576.894L902.06 588.333ZM1001.34 599.78L1021.15 611.219V588.34L1001.34 576.901V599.78ZM981.95 587.879V610.758L1000.98 599.772V576.894L981.95 587.879ZM942.402 588.127L981.603 610.758V587.879L942.402 565.249V588.127ZM922.227 576.688V599.567L942.04 588.127V565.249L922.227 576.688Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1028.85 629.382V583.703L1009.47 572.512V549.016L1049.99 525.627L1089.55 548.463L1108.93 537.272L1129.27 549.023V595.816L1089.72 618.652V641.034L1069.37 652.778L1028.85 629.382ZM1049.55 640.921L1069.36 652.36L1089.18 640.921L1069.36 629.481L1049.55 640.921ZM1029.38 629.276L1049.2 640.715L1069.01 629.276L1049.2 617.836L1029.38 629.276ZM1069.54 629.176L1089.35 640.616V618.645L1069.54 607.205V629.176ZM1029.21 583.703V628.971L1049.02 617.531V572.264L1029.21 583.703ZM1049.37 617.524L1069.19 628.964V583.703L1049.37 572.264V617.524ZM1069.72 606.893L1089.53 618.333L1128.72 595.702L1108.91 584.263L1069.72 606.893ZM1069.54 583.703V606.581L1108.73 583.951V561.072L1069.54 583.703ZM1109.1 583.951L1128.91 595.39V572.512L1109.1 561.072V583.951ZM1010 572.412L1029.03 583.398L1048.84 571.959L1029.82 560.973L1010 572.412ZM1030.17 560.767L1069.36 583.398L1089.18 571.959L1049.98 549.328L1030.17 560.767ZM1009.83 549.222V572.1L1029.64 560.661V537.783L1009.83 549.222ZM1109.1 560.661L1128.91 572.1V549.222L1109.1 537.783V560.661ZM1089.72 548.768V571.647L1108.74 560.661V537.783L1089.72 548.768ZM1050.16 549.016L1089.36 571.647V548.768L1050.16 526.138V549.016ZM1029.99 537.577V560.455L1049.81 549.016V526.138L1029.99 537.577Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M941.402 556.334V510.655L922.021 499.464V475.961L962.541 452.572L1002.09 475.408L1021.48 464.217L1041.82 475.969V522.761L1002.27 545.597V567.979L981.922 579.724L941.402 556.334ZM962.101 567.88L981.914 579.319L1001.73 567.88L981.914 556.441L962.101 567.88ZM941.934 556.235L961.747 567.674L981.56 556.235L961.747 544.796L941.934 556.235ZM982.092 556.129L1001.9 567.568V545.597L982.092 534.158V556.129ZM941.757 510.662V555.93L961.57 544.491V499.216L941.757 510.662ZM961.924 544.484L981.737 555.923V510.662L961.924 499.223V544.484ZM982.269 533.846L1002.08 545.285L1041.28 522.655L1021.46 511.215L982.269 533.846ZM982.092 510.662V533.541L1021.29 510.911V488.032L982.092 510.662ZM1021.65 510.911L1041.46 522.35V499.471L1021.65 488.032V510.911ZM942.721 487.72L981.914 510.35L1001.73 498.911L962.534 476.281L942.721 487.72ZM922.553 499.365L941.579 510.35L961.392 498.911L942.366 487.926L922.553 499.365ZM922.376 476.174V499.053L942.189 487.614V464.735L922.376 476.174ZM1021.65 487.614L1041.46 499.053V476.174L1021.65 464.735V487.614ZM1002.27 475.72V498.599L1021.29 487.614V464.735L1002.27 475.72ZM962.711 475.969L1001.91 498.599V475.72L962.711 453.09V475.969ZM942.544 464.529V487.408L962.357 475.969V453.09L942.544 464.529Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M773.005 931.046V885.367L753.625 874.176V850.68L794.145 827.291L833.693 850.127L853.073 838.936L873.418 850.687V897.48L833.863 920.316V942.698L813.518 954.442L773.005 931.046ZM793.705 942.592L813.518 954.031L833.331 942.592L813.518 931.152L793.705 942.592ZM773.537 930.947L793.35 942.386L813.163 930.947L793.35 919.507L773.537 930.947ZM813.695 930.84L833.508 942.28V920.309L813.695 908.87V930.84ZM773.36 885.374V930.642L793.173 919.202V873.942L773.36 885.374ZM793.528 919.195L813.341 930.635V885.374L793.528 873.935V919.195ZM813.873 908.558L833.686 919.997L872.879 897.366L853.066 885.927L813.873 908.558ZM813.695 885.374V908.253L852.889 885.622V862.744L813.695 885.374ZM853.25 885.622L873.063 897.061V874.183L853.25 862.744V885.622ZM774.324 862.432L813.518 885.062L833.331 873.623L794.138 850.992L774.324 862.432ZM754.157 874.077L773.183 885.062L792.996 873.623L773.97 862.637L754.157 874.077ZM753.972 850.886V873.764L773.786 862.325V839.447L753.972 850.886ZM853.25 862.332L873.063 873.772V850.893L853.25 839.454V862.332ZM833.863 850.432V873.311L852.889 862.325V839.447L833.863 850.432ZM794.315 850.68L833.515 873.311V850.432L794.315 827.802V850.68ZM774.14 839.241V862.12L793.953 850.68V827.802L774.14 839.241Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M751.728 1043.74V998.059L732.348 986.868V963.373L772.867 939.983L812.415 962.819L831.796 951.628L852.141 963.38V1010.17L812.585 1033.01V1055.39L792.241 1067.13L751.728 1043.74ZM772.428 1055.28L792.241 1066.72L812.054 1055.28L792.241 1043.84L772.428 1055.28ZM752.26 1043.64L772.073 1055.08L791.886 1043.64L772.073 1032.2L752.26 1043.64ZM792.418 1043.53L812.231 1054.97V1033L792.418 1021.56V1043.53ZM752.083 998.066V1043.33L771.896 1031.89V986.634L752.083 998.066ZM772.25 1031.89L792.063 1043.33V998.066L772.25 986.627V1031.89ZM792.595 1021.25L812.408 1032.69L851.602 1010.06L831.789 998.62L792.595 1021.25ZM792.418 998.066V1020.94L831.611 998.315V975.436L792.418 998.066ZM831.973 998.315L851.786 1009.75V986.875L831.973 975.436V998.315ZM753.047 975.124L792.241 997.754L812.054 986.315L772.86 963.685L753.047 975.124ZM732.88 986.769L751.905 997.754L771.718 986.315L752.693 975.33L732.88 986.769ZM732.695 963.578V986.457L752.508 975.018V952.139L732.695 963.578ZM831.973 975.025L851.786 986.464V963.585L831.973 952.146V975.025ZM812.585 963.124V986.003L831.611 975.018V952.139L812.585 963.124ZM773.037 963.373L812.238 986.003V963.124L773.037 940.494V963.373ZM752.863 951.933V974.812L772.676 963.373V940.494L752.863 951.933Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1352.33 511.004V465.332L1332.95 454.141V430.645L1373.47 407.256L1413.02 430.092L1432.4 418.901L1452.74 430.652V477.445L1413.19 500.281V522.663L1392.84 534.407L1352.33 511.004ZM1373.03 522.549L1392.84 533.989L1412.66 522.549L1392.84 511.11L1373.03 522.549ZM1352.86 510.904L1372.67 522.344L1392.49 510.904L1372.67 499.465L1352.86 510.904ZM1393.03 510.798L1412.84 522.237V500.267L1393.03 488.827V510.798ZM1352.68 465.332V510.6L1372.5 499.16V453.892L1352.68 465.332ZM1372.85 499.153L1392.66 510.592V465.332L1372.85 453.892V499.153ZM1393.2 488.522L1413.02 499.962L1452.21 477.331L1432.4 465.892L1393.2 488.522ZM1393.03 465.332V488.21L1432.22 465.58V442.701L1393.03 465.332ZM1432.57 465.58L1452.39 477.019V454.141L1432.57 442.701V465.58ZM1353.65 442.389L1392.84 465.02L1412.66 453.58L1373.46 430.95L1353.65 442.389ZM1333.48 454.034L1352.51 465.02L1372.32 453.58L1353.29 442.595L1333.48 454.034ZM1333.3 430.851V453.729L1353.12 442.29V419.411L1333.3 430.851ZM1432.57 442.29L1452.39 453.729V430.851L1432.57 419.411V442.29ZM1413.19 430.39V453.268L1432.22 442.283V419.404L1413.19 430.39ZM1373.64 430.638L1412.84 453.268V430.39L1373.64 407.759V430.638ZM1353.47 419.199V442.077L1373.28 430.638V407.759L1353.47 419.199Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1156.79 476.33V430.658L1137.41 419.467V395.971L1177.93 372.582L1217.48 395.418L1236.86 384.227L1257.2 395.978V442.771L1217.65 465.607V487.989L1197.3 499.733L1156.79 476.33ZM1177.49 487.876L1197.3 499.315L1217.12 487.876L1197.3 476.436L1177.49 487.876ZM1157.32 476.231L1177.13 487.67L1196.95 476.231L1177.13 464.791L1157.32 476.231ZM1197.49 476.124L1217.3 487.564V465.593L1197.49 454.153V476.124ZM1157.14 430.658V475.926L1176.96 464.486V419.219L1157.14 430.658ZM1177.31 464.479L1197.12 475.919V430.658L1177.31 419.219V464.479ZM1197.66 453.849L1217.48 465.288L1256.67 442.657L1236.86 431.218L1197.66 453.849ZM1197.49 430.658V453.536L1236.68 430.906V408.028L1197.49 430.658ZM1237.03 430.906L1256.85 442.345V419.467L1237.03 408.028V430.906ZM1158.11 407.715L1197.3 430.346L1217.12 418.907L1177.92 396.276L1158.11 407.715ZM1137.94 419.36L1156.97 430.346L1176.78 418.907L1157.75 407.921L1137.94 419.36ZM1137.76 396.177V419.055L1157.58 407.616V384.738L1137.76 396.177ZM1237.03 407.616L1256.85 419.055V396.177L1237.03 384.738V407.616ZM1217.65 395.716V418.595L1236.68 407.609V384.731L1217.65 395.716ZM1178.1 395.964L1217.3 418.595V395.716L1178.1 373.086V395.964ZM1157.93 384.525V407.403L1177.74 395.964V373.086L1157.93 384.525Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1089.81 289.559V243.887L1070.42 232.696V209.201L1110.94 185.812L1150.49 208.648L1169.87 197.456L1190.22 209.208V256L1150.66 278.836V301.219L1130.32 312.963L1089.81 289.559ZM1110.5 301.105L1130.32 312.544L1150.13 301.105L1130.32 289.666L1110.5 301.105ZM1090.34 289.46L1110.15 300.899L1129.96 289.46L1110.15 278.021L1090.34 289.46ZM1130.5 289.354L1150.32 300.793V278.822L1130.5 267.383V289.354ZM1090.16 243.887V289.155L1109.97 277.716V232.448L1090.16 243.887ZM1110.33 277.709L1130.14 289.148V243.887L1110.33 232.448V277.709ZM1130.68 267.078L1150.49 278.517L1189.69 255.887L1169.87 244.448L1130.68 267.078ZM1130.5 243.887V266.766L1169.7 244.136V221.257L1130.5 243.887ZM1170.05 244.136L1189.86 255.575V232.696L1170.05 221.257V244.136ZM1091.12 220.945L1130.32 243.575L1150.13 232.136L1110.94 209.506L1091.12 220.945ZM1070.96 232.59L1089.98 243.575L1109.8 232.136L1090.77 221.151L1070.96 232.59ZM1070.78 209.406V232.285L1090.59 220.846V197.967L1070.78 209.406ZM1170.05 220.846L1189.86 232.285V209.406L1170.05 197.967V220.846ZM1150.67 208.945V231.824L1169.7 220.839V197.96L1150.67 208.945ZM1111.11 209.194L1150.32 231.824V208.945L1111.11 186.315V209.194ZM1090.95 197.754V220.633L1110.76 209.194V186.315L1090.95 197.754Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1107.7 176.249V130.577L1088.32 119.386V95.8902L1128.84 72.501L1168.39 95.337L1187.77 84.1459L1208.11 95.8973V142.69L1168.56 165.526V187.908L1148.21 199.652L1107.7 176.249ZM1128.4 187.795L1148.21 199.234L1168.03 187.795L1148.21 176.355L1128.4 187.795ZM1108.23 176.15L1128.05 187.589L1147.86 176.15L1128.05 164.71L1108.23 176.15ZM1148.4 176.043L1168.21 187.483V165.512L1148.4 154.072V176.043ZM1108.06 130.577V175.845L1127.87 164.405V119.138L1108.06 130.577ZM1128.22 164.398L1148.04 175.838V130.577L1128.22 119.138V164.398ZM1148.57 153.767L1168.39 165.207L1207.58 142.576L1187.77 131.137L1148.57 153.767ZM1148.4 130.577V153.455L1187.59 130.825V107.946L1148.4 130.577ZM1187.95 130.825L1207.76 142.264V119.386L1187.95 107.946V130.825ZM1109.02 107.634L1148.21 130.265L1168.03 118.825L1128.83 96.1951L1109.02 107.634ZM1088.85 119.279L1107.88 130.265L1127.69 118.825L1108.67 107.84L1088.85 119.279ZM1088.67 96.0959V118.974L1108.49 107.535V84.6566L1088.67 96.0959ZM1187.95 107.535L1207.76 118.974V96.0959L1187.95 84.6566V107.535ZM1168.57 95.6349V118.513L1187.59 107.528V84.6495L1168.57 95.6349ZM1129.01 95.8831L1168.21 118.513V95.6349L1129.01 73.0045V95.8831ZM1108.84 84.4438V107.322L1128.66 95.8831V73.0045L1108.84 84.4438Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M981.841 328.963V283.291L962.461 272.1V248.604L1002.98 225.215L1042.53 248.051L1061.91 236.86L1082.25 248.611V295.404L1042.7 318.24V340.622L1022.35 352.366L981.841 328.963ZM1002.54 340.508L1022.35 351.948L1042.17 340.508L1022.35 329.069L1002.54 340.508ZM982.373 328.863L1002.19 340.303L1022 328.863L1002.19 317.424L982.373 328.863ZM1022.54 328.757L1042.35 340.196V318.226L1022.54 306.786V328.757ZM982.196 283.291V328.559L1002.01 317.119V271.851L982.196 283.291ZM1002.36 317.112L1022.18 328.551V283.291L1002.36 271.851V317.112ZM1022.72 306.481L1042.53 317.921L1081.72 295.29L1061.91 283.851L1022.72 306.481ZM1022.54 283.291V306.169L1061.73 283.539V260.66L1022.54 283.291ZM1062.09 283.539L1081.9 294.978V272.1L1062.09 260.66V283.539ZM983.16 260.348L1022.35 282.979L1042.17 271.539L1002.97 248.909L983.16 260.348ZM962.993 271.993L982.019 282.979L1001.83 271.539L982.806 260.554L962.993 271.993ZM962.816 248.81V271.688L982.629 260.249V237.37L962.816 248.81ZM1062.09 260.249L1081.9 271.688V248.81L1062.09 237.37V260.249ZM1042.71 248.349V271.227L1061.73 260.242V237.363L1042.71 248.349ZM1003.15 248.597L1042.35 271.227V248.349L1003.15 225.718V248.597ZM982.983 237.158V260.036L1002.8 248.597V225.718L982.983 237.158Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1003.12 217.058V171.386L983.738 160.195V136.7L1024.26 113.311L1063.81 136.147L1083.19 124.956L1103.53 136.707V183.499L1063.98 206.335V228.718L1043.63 240.462L1003.12 217.058ZM1023.82 228.604L1043.63 240.043L1063.44 228.604L1043.63 217.165L1023.82 228.604ZM1003.65 216.959L1023.46 228.398L1043.28 216.959L1023.46 205.52L1003.65 216.959ZM1043.82 216.853L1063.63 228.292V206.321L1043.82 194.882V216.853ZM1003.47 171.386V216.654L1023.29 205.215V159.947L1003.47 171.386ZM1023.64 205.208L1043.45 216.647V171.386L1023.64 159.947V205.208ZM1043.99 194.577L1063.81 206.016L1103 183.386L1083.19 171.947L1043.99 194.577ZM1043.82 171.386V194.265L1083.01 171.635V148.756L1043.82 171.386ZM1083.36 171.635L1103.18 183.074V160.195L1083.36 148.756V171.635ZM1004.44 148.444L1043.63 171.074L1063.44 159.635L1024.25 137.005L1004.44 148.444ZM984.27 160.089L1003.3 171.074L1023.11 159.635L1004.08 148.65L984.27 160.089ZM984.093 136.905V159.784L1003.91 148.345V125.466L984.093 136.905ZM1083.36 148.345L1103.18 159.784V136.905L1083.36 125.466V148.345ZM1063.98 136.444V159.323L1083.01 148.338V125.459L1063.98 136.444ZM1024.43 136.693L1063.63 159.323V136.444L1024.43 113.814V136.693ZM1004.26 125.253V148.132L1024.07 136.693V113.814L1004.26 125.253Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1021.01 103.748V58.0759L1001.63 46.8848V23.3892L1042.15 0L1081.7 22.836L1101.08 11.645L1121.43 23.3963V70.1889L1081.87 93.0249V115.407L1061.53 127.151L1021.01 103.748ZM1041.71 115.294L1061.53 126.733L1081.34 115.294L1061.53 103.854L1041.71 115.294ZM1021.55 103.649L1041.36 115.088L1061.17 103.649L1041.36 92.2093L1021.55 103.649ZM1061.71 103.542L1081.52 114.982V93.0107L1061.71 81.5714V103.542ZM1021.37 58.0759V103.344L1041.18 91.9044V46.6366L1021.37 58.0759ZM1041.54 91.8973L1061.35 103.337V58.0759L1041.54 46.6366V91.8973ZM1061.89 81.2665L1081.7 92.7058L1120.9 70.0754L1101.08 58.6361L1061.89 81.2665ZM1061.71 58.0759V80.9544L1100.9 58.3241V35.4455L1061.71 58.0759ZM1101.26 58.3241L1121.07 69.7634V46.8848L1101.26 35.4455V58.3241ZM1022.33 35.1334L1061.53 57.7638L1081.34 46.3245L1042.15 23.6942L1022.33 35.1334ZM1002.17 46.7784L1021.19 57.7638L1041 46.3245L1021.98 35.3391L1002.17 46.7784ZM1001.99 23.5949V46.4735L1021.8 35.0342V12.1556L1001.99 23.5949ZM1101.26 35.0342L1121.07 46.4735V23.5949L1101.26 12.1556V35.0342ZM1081.88 23.1339V46.0125L1100.9 35.0271V12.1485L1081.88 23.1339ZM1042.32 23.3821L1081.52 46.0125V23.1339L1042.32 0.503543V23.3821ZM1022.16 11.9428V34.8214L1041.97 23.3821V0.503543L1022.16 11.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M787.979 295.076V249.404L768.599 238.213V214.717L809.118 191.328L848.666 214.164L868.047 202.973L888.392 214.724V261.517L848.837 284.353V306.735L828.492 318.479L787.979 295.076ZM808.679 306.622L828.492 318.061L848.305 306.622L828.492 295.182L808.679 306.622ZM788.511 294.977L808.324 306.416L828.137 294.977L808.324 283.537L788.511 294.977ZM828.676 294.87L848.489 306.31V284.339L828.676 272.9V294.87ZM788.334 249.404V294.672L808.147 283.233V237.965L788.334 249.404ZM808.501 283.225L828.314 294.665V249.404L808.501 237.965V283.225ZM828.853 272.595L848.666 284.034L887.86 261.404L868.047 249.964L828.853 272.595ZM828.676 249.404V272.283L867.87 249.652V226.774L828.676 249.404ZM868.224 249.652L888.037 261.091V238.213L868.224 226.774V249.652ZM789.298 226.462L828.492 249.092L848.305 237.653L809.111 215.022L789.298 226.462ZM769.13 238.107L788.156 249.092L807.969 237.653L788.944 226.667L769.13 238.107ZM768.953 214.923V237.802L788.766 226.362V203.484L768.953 214.923ZM868.224 226.362L888.037 237.802V214.923L868.224 203.484V226.362ZM848.844 214.462V237.341L867.87 226.355V203.477L848.844 214.462ZM809.288 214.71L848.489 237.341V214.462L809.288 191.832V214.71ZM789.121 203.271V226.15L808.934 214.71V191.832L789.121 203.271Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M808.277 180.25V134.578L788.896 123.387V99.8912L829.416 76.502L868.964 99.338L888.345 88.1469L908.69 99.8983V146.691L869.134 169.527V191.909L848.789 203.653L808.277 180.25ZM828.976 191.796L848.789 203.235L868.603 191.796L848.789 180.356L828.976 191.796ZM808.809 180.151L828.622 191.59L848.435 180.151L828.622 168.711L808.809 180.151ZM848.974 180.044L868.787 191.483V169.513L848.974 158.073V180.044ZM808.631 134.578V179.846L828.445 168.406V123.139L808.631 134.578ZM828.799 168.399L848.612 179.839V134.578L828.799 123.139V168.399ZM849.151 157.768L868.964 169.208L908.158 146.577L888.345 135.138L849.151 157.768ZM848.974 134.578V157.456L888.167 134.826V111.947L848.974 134.578ZM888.522 134.826L908.335 146.265V123.387L888.522 111.947V134.826ZM809.596 111.635L848.789 134.266L868.603 122.826L829.409 100.196L809.596 111.635ZM789.428 123.28L808.454 134.266L828.267 122.826L809.241 111.841L789.428 123.28ZM789.251 100.097V122.975L809.064 111.536V88.6575L789.251 100.097ZM888.522 111.536L908.335 122.975V100.097L888.522 88.6575V111.536ZM869.141 99.6359V122.514L888.167 111.529V88.6504L869.141 99.6359ZM829.586 99.8841L868.787 122.514V99.6359L829.586 77.0055V99.8841ZM809.419 88.4448V111.323L829.232 99.8841V77.0055L809.419 88.4448Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M895.155 255.674V210.002L875.774 198.811V175.315L916.294 151.926L955.842 174.762L975.223 163.571L995.568 175.322V222.115L956.012 244.951V267.333L935.667 279.077L895.155 255.674ZM915.854 267.219L935.667 278.659L955.48 267.219L935.667 255.78L915.854 267.219ZM895.687 255.574L915.5 267.014L935.313 255.574L915.5 244.135L895.687 255.574ZM935.852 255.468L955.665 266.907V244.937L935.852 233.497V255.468ZM895.509 210.002V255.269L915.323 243.83V198.562L895.509 210.002ZM915.677 243.823L935.49 255.262V210.002L915.677 198.562V243.823ZM936.029 233.192L955.842 244.632L995.036 222.001L975.223 210.562L936.029 233.192ZM935.852 210.002V232.88L975.045 210.25V187.371L935.852 210.002ZM975.4 210.25L995.213 221.689V198.811L975.4 187.371V210.25ZM896.474 187.059L935.667 209.69L955.48 198.25L916.287 175.62L896.474 187.059ZM876.306 198.704L895.332 209.69L915.145 198.25L896.119 187.265L876.306 198.704ZM876.129 175.521V198.399L895.942 186.96V164.081L876.129 175.521ZM975.4 186.96L995.213 198.399V175.521L975.4 164.081V186.96ZM956.019 175.06V197.938L975.045 186.953V164.074L956.019 175.06ZM916.464 175.308L955.665 197.938V175.06L916.464 152.429V175.308ZM896.297 163.869V186.747L916.11 175.308V152.429L896.297 163.869Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M915.453 140.847V95.1745L896.072 83.9834V60.4878L936.592 37.0986L976.14 59.9347L995.52 48.7436L1015.87 60.4949V107.288L976.31 130.124V152.506L955.965 164.25L915.453 140.847ZM936.152 152.392L955.965 163.832L975.778 152.392L955.965 140.953L936.152 152.392ZM915.985 140.747L935.798 152.187L955.611 140.747L935.798 129.308L915.985 140.747ZM956.15 140.641L975.963 152.08V130.109L956.15 118.67V140.641ZM915.807 95.1745V140.442L935.62 129.003V83.7352L915.807 95.1745ZM935.975 128.996L955.788 140.435V95.1745L935.975 83.7352V128.996ZM956.327 118.365L976.14 129.804L1015.33 107.174L995.52 95.7348L956.327 118.365ZM956.15 95.1745V118.053L995.343 95.4227V72.5441L956.15 95.1745ZM995.698 95.4227L1015.51 106.862V83.9834L995.698 72.5441V95.4227ZM916.772 72.2321L955.965 94.8624L975.778 83.4232L936.585 60.7928L916.772 72.2321ZM896.604 83.877L915.63 94.8624L935.443 83.4232L916.417 72.4378L896.604 83.877ZM896.427 60.6935V83.5721L916.24 72.1328V49.2542L896.427 60.6935ZM995.698 72.1328L1015.51 83.5721V60.6935L995.698 49.2542V72.1328ZM976.317 60.2325V83.1111L995.343 72.1257V49.2471L976.317 60.2325ZM936.762 60.4807L975.963 83.1111V60.2325L936.762 37.6022V60.4807ZM916.594 49.0415V71.92L936.407 60.4807V37.6022L916.594 49.0415Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M699.716 222.574V176.902L680.336 165.711V142.215L720.856 118.826L760.404 141.662L779.784 130.471L800.129 142.222V189.015L760.574 211.851V234.233L740.229 245.977L699.716 222.574ZM720.416 234.12L740.229 245.559L760.042 234.12L740.229 222.68L720.416 234.12ZM700.248 222.475L720.061 233.914L739.874 222.475L720.061 211.036L700.248 222.475ZM740.413 222.368L760.226 233.808V211.837L740.413 200.398V222.368ZM700.071 176.902V222.17L719.884 210.731V165.463L700.071 176.902ZM720.239 210.723L740.052 222.163V176.902L720.239 165.463V210.723ZM740.591 200.093L760.404 211.532L799.597 188.902L779.784 177.462L740.591 200.093ZM740.413 176.902V199.781L779.607 177.15V154.272L740.413 176.902ZM779.961 177.15L799.774 188.59V165.711L779.961 154.272V177.15ZM701.035 153.96L740.229 176.59L760.042 165.151L720.848 142.52L701.035 153.96ZM680.868 165.605L699.894 176.59L719.707 165.151L700.681 154.165L680.868 165.605ZM680.691 142.421V165.3L700.504 153.86V130.982L680.691 142.421ZM779.961 153.86L799.774 165.3V142.421L779.961 130.982V153.86ZM760.581 141.96V164.839L779.607 153.853V130.975L760.581 141.96ZM721.026 142.208L760.226 164.839V141.96L721.026 119.33V142.208ZM700.858 130.769V153.648L720.671 142.208V119.33L700.858 130.769Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M720.014 107.748V62.0759L700.634 50.8848V27.3892L741.153 4L780.701 26.836L800.082 15.645L820.427 27.3963V74.1889L780.872 97.0249V119.407L760.527 131.151L720.014 107.748ZM740.714 119.294L760.527 130.733L780.34 119.294L760.527 107.854L740.714 119.294ZM720.546 107.649L740.359 119.088L760.172 107.649L740.359 96.2093L720.546 107.649ZM760.711 107.542L780.524 118.982V97.0107L760.711 85.5714V107.542ZM720.369 62.0759V107.344L740.182 95.9044V50.6366L720.369 62.0759ZM740.536 95.8973L760.349 107.337V62.0759L740.536 50.6366V95.8973ZM760.888 85.2665L780.701 96.7058L819.895 74.0754L800.082 62.6361L760.888 85.2665ZM760.711 62.0759V84.9544L799.905 62.3241V39.4455L760.711 62.0759ZM800.259 62.3241L820.072 73.7634V50.8848L800.259 39.4455V62.3241ZM721.333 39.1334L760.527 61.7638L780.34 50.3245L741.146 27.6942L721.333 39.1334ZM701.166 50.7784L720.192 61.7638L740.005 50.3245L720.979 39.3391L701.166 50.7784ZM700.988 27.5949V50.4735L720.801 39.0342V16.1556L700.988 27.5949ZM800.259 39.0342L820.072 50.4735V27.5949L800.259 16.1556V39.0342ZM780.879 27.1339V50.0125L799.905 39.0271V16.1485L780.879 27.1339ZM741.324 27.3821L780.524 50.0125V27.1339L741.324 4.50354V27.3821ZM721.156 15.9428V38.8214L740.969 27.3821V4.50354L721.156 15.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1177.28 362.849V317.177L1157.9 305.986V282.491L1198.42 259.102L1237.97 281.938L1257.35 270.747L1277.69 282.498V329.29L1238.14 352.126V374.509L1217.79 386.253L1177.28 362.849ZM1197.98 374.395L1217.79 385.834L1237.6 374.395L1217.79 362.956L1197.98 374.395ZM1177.81 362.75L1197.62 374.189L1217.44 362.75L1197.62 351.311L1177.81 362.75ZM1217.98 362.644L1237.79 374.083V352.112L1217.98 340.673V362.644ZM1177.63 317.177V362.445L1197.45 351.006V305.738L1177.63 317.177ZM1197.8 350.999L1217.61 362.438V317.177L1197.8 305.738V350.999ZM1218.15 340.368L1237.97 351.807L1277.16 329.177L1257.35 317.738L1218.15 340.368ZM1217.98 317.177V340.056L1257.17 317.426V294.547L1217.98 317.177ZM1257.52 317.426L1277.34 328.865V305.986L1257.52 294.547V317.426ZM1178.6 294.235L1217.79 316.865L1237.6 305.426L1198.41 282.796L1178.6 294.235ZM1158.43 305.88L1177.46 316.865L1197.27 305.426L1178.24 294.441L1158.43 305.88ZM1158.25 282.696V305.575L1178.07 294.136V271.257L1158.25 282.696ZM1257.52 294.136L1277.34 305.575V282.696L1257.52 271.257V294.136ZM1238.14 282.235V305.114L1257.17 294.129V271.25L1238.14 282.235ZM1198.59 282.484L1237.79 305.114V282.235L1198.59 259.605V282.484ZM1178.42 271.044V293.923L1198.23 282.484V259.605L1178.42 271.044Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1393.21 284.043V238.371L1373.83 227.18V203.684L1414.35 180.295L1453.89 203.131L1473.28 191.94L1493.62 203.691V250.484L1454.07 273.32V295.702L1433.72 307.446L1393.21 284.043ZM1413.91 295.588L1433.72 307.028L1453.53 295.588L1433.72 284.149L1413.91 295.588ZM1393.74 283.944L1413.55 295.383L1433.37 283.944L1413.55 272.504L1393.74 283.944ZM1433.9 283.837L1453.72 295.276V273.306L1433.9 261.866V283.837ZM1393.56 238.371V283.639L1413.38 272.199V226.931L1393.56 238.371ZM1413.73 272.192L1433.54 283.632V238.371L1413.73 226.931V272.192ZM1434.08 261.561L1453.89 273.001L1493.09 250.37L1473.28 238.931L1434.08 261.561ZM1433.9 238.371V261.249L1473.1 238.619V215.74L1433.9 238.371ZM1473.45 238.619L1493.27 250.058V227.18L1473.45 215.74V238.619ZM1394.53 215.428L1433.72 238.059L1453.53 226.619L1414.34 203.989L1394.53 215.428ZM1374.36 227.073L1393.38 238.059L1413.2 226.619L1394.17 215.634L1374.36 227.073ZM1374.18 203.89V226.768L1393.99 215.329V192.451L1374.18 203.89ZM1473.45 215.329L1493.27 226.768V203.89L1473.45 192.451V215.329ZM1454.07 203.429V226.307L1473.1 215.322V192.443L1454.07 203.429ZM1414.52 203.677L1453.72 226.307V203.429L1414.52 180.798V203.677ZM1394.35 192.238V215.116L1414.16 203.677V180.798L1394.35 192.238Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1413.98 166.726V121.054L1394.6 109.863V86.3677L1435.12 62.9785L1474.67 85.8146L1494.05 74.6235L1514.39 86.3748V133.167L1474.84 156.003V178.386L1454.49 190.13L1413.98 166.726ZM1434.68 178.272L1454.49 189.711L1474.3 178.272L1454.49 166.833L1434.68 178.272ZM1414.51 166.627L1434.32 178.066L1454.14 166.627L1434.32 155.188L1414.51 166.627ZM1454.67 166.521L1474.49 177.96V155.989L1454.67 144.55V166.521ZM1414.33 121.054V166.322L1434.15 154.883V109.615L1414.33 121.054ZM1434.5 154.876L1454.31 166.315V121.054L1434.5 109.615V154.876ZM1454.85 144.245L1474.67 155.684L1513.86 133.054L1494.05 121.615L1454.85 144.245ZM1454.67 121.054V143.933L1493.87 121.303V98.424L1454.67 121.054ZM1494.22 121.303L1514.04 132.742V109.863L1494.22 98.424V121.303ZM1415.3 98.112L1454.49 120.742L1474.3 109.303L1435.11 86.6727L1415.3 98.112ZM1395.13 109.757L1414.16 120.742L1433.97 109.303L1414.94 98.3176L1395.13 109.757ZM1394.95 86.5734V109.452L1414.77 98.0127V75.1341L1394.95 86.5734ZM1494.22 98.0127L1514.04 109.452V86.5734L1494.22 75.1341V98.0127ZM1474.84 86.1124V108.991L1493.87 98.0056V75.127L1474.84 86.1124ZM1435.29 86.3606L1474.49 108.991V86.1124L1435.29 63.4821V86.3606ZM1415.12 74.9214V97.7999L1434.93 86.3606V63.4821L1415.12 74.9214Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1372.72 397.523V351.851L1353.34 340.66V317.165L1393.86 293.775L1433.4 316.611L1452.79 305.42L1473.13 317.172V363.964L1433.57 386.8V409.182L1413.23 420.927L1372.72 397.523ZM1393.42 409.069L1413.23 420.508L1433.04 409.069L1413.23 397.63L1393.42 409.069ZM1373.25 397.424L1393.06 408.863L1412.88 397.424L1393.06 385.985L1373.25 397.424ZM1413.41 397.318L1433.23 408.757V386.786L1413.41 375.347V397.318ZM1373.07 351.851V397.119L1392.89 385.68V340.412L1373.07 351.851ZM1393.24 385.673L1413.05 397.112V351.851L1393.24 340.412V385.673ZM1413.59 375.042L1433.4 386.481L1472.6 363.851L1452.79 352.412L1413.59 375.042ZM1413.41 351.851V374.73L1452.61 352.099V329.221L1413.41 351.851ZM1452.96 352.099L1472.78 363.539V340.66L1452.96 329.221V352.099ZM1374.04 328.909L1413.23 351.539L1433.04 340.1L1393.85 317.47L1374.04 328.909ZM1353.87 340.554L1372.89 351.539L1392.71 340.1L1373.68 329.115L1353.87 340.554ZM1353.69 317.37V340.249L1373.5 328.81V305.931L1353.69 317.37ZM1452.96 328.81L1472.78 340.249V317.37L1452.96 305.931V328.81ZM1433.58 316.909V339.788L1452.61 328.802V305.924L1433.58 316.909ZM1394.03 317.157L1433.23 339.788V316.909L1394.03 294.279V317.157ZM1373.86 305.718V328.597L1393.67 317.157V294.279L1373.86 305.718Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1461.77 471.601V425.929L1442.39 414.738V391.243L1482.91 367.854L1522.46 390.69L1541.84 379.498L1562.18 391.25V438.042L1522.63 460.878V483.261L1502.28 495.005L1461.77 471.601ZM1482.47 483.147L1502.28 494.586L1522.09 483.147L1502.28 471.708L1482.47 483.147ZM1462.3 471.502L1482.11 482.941L1501.93 471.502L1482.11 460.063L1462.3 471.502ZM1502.47 471.396L1522.28 482.835V460.864L1502.47 449.425V471.396ZM1462.12 425.929V471.197L1481.94 459.758V414.49L1462.12 425.929ZM1482.29 459.751L1502.1 471.19V425.929L1482.29 414.49V459.751ZM1502.64 449.12L1522.46 460.559L1561.65 437.929L1541.84 426.49L1502.64 449.12ZM1502.47 425.929V448.808L1541.66 426.178V403.299L1502.47 425.929ZM1542.01 426.178L1561.83 437.617V414.738L1542.01 403.299V426.178ZM1463.09 402.987L1502.28 425.617L1522.09 414.178L1482.9 391.548L1463.09 402.987ZM1442.92 414.632L1461.95 425.617L1481.76 414.178L1462.73 403.193L1442.92 414.632ZM1442.74 391.448V414.327L1462.56 402.888V380.009L1442.74 391.448ZM1542.01 402.888L1561.83 414.327V391.448L1542.01 380.009V402.888ZM1522.63 390.987V413.866L1541.66 402.881V380.002L1522.63 390.987ZM1483.08 391.236L1522.28 413.866V390.987L1483.08 368.357V391.236ZM1462.91 379.796V402.675L1482.72 391.236V368.357L1462.91 379.796Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1481.47 358.121V312.449L1462.09 301.258V277.762L1502.61 254.373L1542.16 277.209L1561.54 266.018L1581.88 277.769V324.562L1542.33 347.398V369.78L1521.98 381.524L1481.47 358.121ZM1502.17 369.667L1521.98 381.106L1541.79 369.667L1521.98 358.227L1502.17 369.667ZM1482 358.022L1501.81 369.461L1521.63 358.022L1501.81 346.582L1482 358.022ZM1522.17 357.915L1541.98 369.355V347.384L1522.17 335.944V357.915ZM1481.82 312.449V357.717L1501.64 346.277V301.01L1481.82 312.449ZM1501.99 346.27L1521.8 357.71V312.449L1501.99 301.01V346.27ZM1522.34 335.64L1542.16 347.079L1581.35 324.448L1561.54 313.009L1522.34 335.64ZM1522.17 312.449V335.327L1561.36 312.697V289.819L1522.17 312.449ZM1561.71 312.697L1581.53 324.136V301.258L1561.71 289.819V312.697ZM1482.79 289.506L1521.98 312.137L1541.79 300.698L1502.6 278.067L1482.79 289.506ZM1462.62 301.151L1481.65 312.137L1501.46 300.698L1482.43 289.712L1462.62 301.151ZM1462.44 277.968V300.847L1482.26 289.407V266.529L1462.44 277.968ZM1561.71 289.407L1581.53 300.847V277.968L1561.71 266.529V289.407ZM1542.33 277.507V300.386L1561.36 289.4V266.522L1542.33 277.507ZM1502.78 277.755L1541.98 300.386V277.507L1502.78 254.877V277.755ZM1482.61 266.316V289.194L1502.42 277.755V254.877L1482.61 266.316Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1197.77 250.156V204.484L1178.39 193.293V169.797L1218.91 146.408L1258.46 169.244L1277.84 158.053L1298.18 169.805V216.597L1258.63 239.433V261.815L1238.28 273.559L1197.77 250.156ZM1218.47 261.702L1238.28 273.141L1258.09 261.702L1238.28 250.262L1218.47 261.702ZM1198.3 250.057L1218.11 261.496L1237.93 250.057L1218.11 238.618L1198.3 250.057ZM1238.47 249.95L1258.28 261.39V239.419L1238.47 227.98V249.95ZM1198.12 204.484V249.752L1217.94 238.313V193.045L1198.12 204.484ZM1218.29 238.305L1238.1 249.745V204.484L1218.29 193.045V238.305ZM1238.64 227.675L1258.46 239.114L1297.65 216.484L1277.84 205.044L1238.64 227.675ZM1238.47 204.484V227.363L1277.66 204.732V181.854L1238.47 204.484ZM1278.01 204.732L1297.83 216.172V193.293L1278.01 181.854V204.732ZM1199.09 181.542L1238.28 204.172L1258.09 192.733L1218.9 170.102L1199.09 181.542ZM1178.92 193.187L1197.95 204.172L1217.76 192.733L1198.73 181.747L1178.92 193.187ZM1178.74 170.003V192.882L1198.56 181.442V158.564L1178.74 170.003ZM1278.01 181.442L1297.83 192.882V170.003L1278.01 158.564V181.442ZM1258.63 169.542V192.421L1277.66 181.435V158.557L1258.63 169.542ZM1219.08 169.79L1258.28 192.421V169.542L1219.08 146.912V169.79ZM1198.91 158.351V181.23L1218.72 169.79V146.912L1198.91 158.351Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1215.66 136.846V91.1735L1196.28 79.9824V56.4869L1236.8 33.0977L1276.35 55.9337L1295.73 44.7426L1316.08 56.494V103.287L1276.52 126.123V148.505L1256.18 160.249L1215.66 136.846ZM1236.36 148.391L1256.18 159.831L1275.99 148.391L1256.18 136.952L1236.36 148.391ZM1216.2 136.746L1236.01 148.186L1255.82 136.746L1236.01 125.307L1216.2 136.746ZM1256.36 136.64L1276.17 148.079V126.108L1256.36 114.669V136.64ZM1216.02 91.1735V136.441L1235.83 125.002V79.7342L1216.02 91.1735ZM1236.19 124.995L1256 136.434V91.1735L1236.19 79.7342V124.995ZM1256.54 114.364L1276.35 125.803L1315.55 103.173L1295.73 91.7338L1256.54 114.364ZM1256.36 91.1735V114.052L1295.56 91.4217V68.5432L1256.36 91.1735ZM1295.91 91.4217L1315.72 102.861V79.9824L1295.91 68.5432V91.4217ZM1216.98 68.2311L1256.18 90.8615L1275.99 79.4222L1236.8 56.7918L1216.98 68.2311ZM1196.82 79.8761L1215.84 90.8615L1235.66 79.4222L1216.63 68.4368L1196.82 79.8761ZM1196.64 56.6925V79.5711L1216.45 68.1318V45.2532L1196.64 56.6925ZM1295.91 68.1318L1315.72 79.5711V56.6925L1295.91 45.2532V68.1318ZM1276.53 56.2316V79.1101L1295.56 68.1247V45.2461L1276.53 56.2316ZM1236.97 56.4798L1276.17 79.1101V56.2316L1236.97 33.6012V56.4798ZM1216.81 45.0405V67.9191L1236.62 56.4798V33.6012L1216.81 45.0405Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1285.24 323.446V277.774L1265.86 266.583V243.087L1306.38 219.698L1345.93 242.534L1365.31 231.343L1385.66 243.095V289.887L1346.1 312.723V335.105L1325.76 346.85L1285.24 323.446ZM1305.94 334.992L1325.76 346.431L1345.57 334.992L1325.76 323.553L1305.94 334.992ZM1285.78 323.347L1305.59 334.786L1325.4 323.347L1305.59 311.908L1285.78 323.347ZM1325.94 323.24L1345.75 334.68V312.709L1325.94 301.27V323.24ZM1285.6 277.774V323.042L1305.41 311.603V266.335L1285.6 277.774ZM1305.77 311.596L1325.58 323.035V277.774L1305.77 266.335V311.596ZM1326.12 300.965L1345.93 312.404L1385.12 289.774L1365.31 278.334L1326.12 300.965ZM1325.94 277.774V300.653L1365.13 278.022V255.144L1325.94 277.774ZM1365.49 278.022L1385.3 289.462V266.583L1365.49 255.144V278.022ZM1286.56 254.832L1325.76 277.462L1345.57 266.023L1306.38 243.392L1286.56 254.832ZM1266.4 266.477L1285.42 277.462L1305.23 266.023L1286.21 255.037L1266.4 266.477ZM1266.22 243.293V266.172L1286.03 254.732V231.854L1266.22 243.293ZM1365.49 254.732L1385.3 266.172V243.293L1365.49 231.854V254.732ZM1346.11 242.832V265.711L1365.13 254.725V231.847L1346.11 242.832ZM1306.55 243.08L1345.75 265.711V242.832L1306.55 220.202V243.08ZM1286.39 231.641V254.52L1306.2 243.08V220.202L1286.39 231.641Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1306.01 206.13V160.458L1286.63 149.267V125.771L1327.15 102.382L1366.7 125.218L1386.08 114.027L1406.43 125.778V172.571L1366.87 195.407V217.789L1346.53 229.533L1306.01 206.13ZM1326.71 217.675L1346.53 229.115L1366.34 217.675L1346.53 206.236L1326.71 217.675ZM1306.55 206.03L1326.36 217.47L1346.17 206.03L1326.36 194.591L1306.55 206.03ZM1346.71 205.924L1366.52 217.363V195.393L1346.71 183.953V205.924ZM1306.37 160.458V205.726L1326.18 194.286V149.018L1306.37 160.458ZM1326.54 194.279L1346.35 205.718V160.458L1326.54 149.018V194.279ZM1346.89 183.648L1366.7 195.088L1405.9 172.457L1386.08 161.018L1346.89 183.648ZM1346.71 160.458V183.336L1385.9 160.706V137.827L1346.71 160.458ZM1386.26 160.706L1406.07 172.145V149.267L1386.26 137.827V160.706ZM1307.33 137.515L1346.53 160.146L1366.34 148.706L1327.15 126.076L1307.33 137.515ZM1287.17 149.16L1306.19 160.146L1326 148.706L1306.98 137.721L1287.17 149.16ZM1286.99 125.977V148.855L1306.8 137.416V114.537L1286.99 125.977ZM1386.26 137.416L1406.07 148.855V125.977L1386.26 114.537V137.416ZM1366.88 125.516V148.394L1385.9 137.409V114.53L1366.88 125.516ZM1327.32 125.764L1366.52 148.394V125.516L1327.32 102.885V125.764ZM1307.16 114.325V137.203L1326.97 125.764V102.885L1307.16 114.325Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1397.94 808.89V763.217L1378.55 752.026V728.531L1419.07 705.142L1458.62 727.978L1478 716.787L1498.35 728.538V775.33L1458.79 798.167V820.549L1438.45 832.293L1397.94 808.89ZM1418.63 820.435L1438.45 831.874L1458.26 820.435L1438.45 808.996L1418.63 820.435ZM1398.47 808.79L1418.28 820.23L1438.09 808.79L1418.28 797.351L1398.47 808.79ZM1438.63 808.684L1458.45 820.123V798.152L1438.63 786.713V808.684ZM1398.29 763.217V808.485L1418.1 797.046V751.778L1398.29 763.217ZM1418.46 797.039L1438.27 808.478V763.217L1418.46 751.778V797.039ZM1438.81 786.408L1458.62 797.847L1497.82 775.217L1478 763.778L1438.81 786.408ZM1438.63 763.217V786.096L1477.83 763.466V740.587L1438.63 763.217ZM1478.18 763.466L1497.99 774.905V752.026L1478.18 740.587V763.466ZM1399.25 740.275L1438.45 762.905L1458.26 751.466L1419.07 728.836L1399.25 740.275ZM1379.09 751.92L1398.11 762.905L1417.93 751.466L1398.9 740.481L1379.09 751.92ZM1378.91 728.736V751.615L1398.72 740.176V717.297L1378.91 728.736ZM1478.18 740.176L1497.99 751.615V728.736L1478.18 717.297V740.176ZM1458.8 728.276V751.154L1477.83 740.169V717.29L1458.8 728.276ZM1419.24 728.524L1458.45 751.154V728.276L1419.24 705.645V728.524ZM1399.08 717.084V739.963L1418.89 728.524V705.645L1399.08 717.084Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1592.48 844.691V799.019L1573.1 787.828V764.333L1613.62 740.943L1653.17 763.779L1672.55 752.588L1692.89 764.34V811.132L1653.34 833.968V856.35L1632.99 868.095L1592.48 844.691ZM1613.18 856.237L1632.99 867.676L1652.8 856.237L1632.99 844.798L1613.18 856.237ZM1593.01 844.592L1612.82 856.031L1632.64 844.592L1612.82 833.153L1593.01 844.592ZM1633.17 844.486L1652.99 855.925V833.954L1633.17 822.515V844.486ZM1592.83 799.019V844.287L1612.65 832.848V787.58L1592.83 799.019ZM1613 832.841L1632.81 844.28V799.019L1613 787.58V832.841ZM1633.35 822.21L1653.17 833.649L1692.36 811.019L1672.55 799.579L1633.35 822.21ZM1633.17 799.019V821.898L1672.37 799.267V776.389L1633.17 799.019ZM1672.72 799.267L1692.54 810.707V787.828L1672.72 776.389V799.267ZM1593.8 776.077L1632.99 798.707L1652.8 787.268L1613.61 764.638L1593.8 776.077ZM1573.63 787.722L1592.66 798.707L1612.47 787.268L1593.44 776.282L1573.63 787.722ZM1573.45 764.538V787.417L1593.27 775.978V753.099L1573.45 764.538ZM1672.72 775.978L1692.54 787.417V764.538L1672.72 753.099V775.978ZM1653.34 764.077V786.956L1672.37 775.97V753.092L1653.34 764.077ZM1613.79 764.325L1652.99 786.956V764.077L1613.79 741.447V764.325ZM1593.62 752.886V775.765L1613.43 764.325V741.447L1593.62 752.886Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1418.43 696.986V651.314L1399.04 640.123V616.627L1439.56 593.238L1479.11 616.074L1498.49 604.883L1518.84 616.635V663.427L1479.28 686.263V708.645L1458.94 720.39L1418.43 696.986ZM1439.12 708.532L1458.94 719.971L1478.75 708.532L1458.94 697.093L1439.12 708.532ZM1418.96 696.887L1438.77 708.326L1458.58 696.887L1438.77 685.448L1418.96 696.887ZM1459.12 696.781L1478.94 708.22V686.249L1459.12 674.81V696.781ZM1418.78 651.314V696.582L1438.59 685.143V639.875L1418.78 651.314ZM1438.95 685.136L1458.76 696.575V651.314L1438.95 639.875V685.136ZM1459.3 674.505L1479.11 685.944L1518.31 663.314L1498.49 651.874L1459.3 674.505ZM1459.12 651.314V674.193L1498.32 651.562V628.684L1459.12 651.314ZM1498.67 651.562L1518.48 663.002V640.123L1498.67 628.684V651.562ZM1419.74 628.372L1458.94 651.002L1478.75 639.563L1439.56 616.932L1419.74 628.372ZM1399.58 640.017L1418.6 651.002L1438.42 639.563L1419.39 628.577L1399.58 640.017ZM1399.4 616.833V639.712L1419.21 628.272V605.394L1399.4 616.833ZM1498.67 628.272L1518.48 639.712V616.833L1498.67 605.394V628.272ZM1479.29 616.372V639.251L1498.32 628.265V605.387L1479.29 616.372ZM1439.73 616.62L1478.94 639.251V616.372L1439.73 593.742V616.62ZM1419.57 605.181V628.06L1439.38 616.62V593.742L1419.57 605.181Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1612.97 732.787V687.115L1593.59 675.924V652.428L1634.11 629.039L1673.66 651.875L1693.04 640.684L1713.38 652.435V699.228L1673.83 722.064V744.446L1653.48 756.19L1612.97 732.787ZM1633.67 744.333L1653.48 755.772L1673.29 744.333L1653.48 732.893L1633.67 744.333ZM1613.5 732.688L1633.31 744.127L1653.13 732.688L1633.31 721.248L1613.5 732.688ZM1653.67 732.581L1673.48 744.021V722.05L1653.67 710.611V732.581ZM1613.32 687.115V732.383L1633.14 720.943V675.676L1613.32 687.115ZM1633.49 720.936L1653.3 732.376V687.115L1633.49 675.676V720.936ZM1653.84 710.306L1673.66 721.745L1712.85 699.114L1693.04 687.675L1653.84 710.306ZM1653.67 687.115V709.994L1692.86 687.363V664.485L1653.67 687.115ZM1693.21 687.363L1713.03 698.802V675.924L1693.21 664.485V687.363ZM1614.29 664.173L1653.48 686.803L1673.29 675.364L1634.1 652.733L1614.29 664.173ZM1594.12 675.817L1613.15 686.803L1632.96 675.364L1613.93 664.378L1594.12 675.817ZM1593.94 652.634V675.513L1613.76 664.073V641.195L1593.94 652.634ZM1693.21 664.073L1713.03 675.513V652.634L1693.21 641.195V664.073ZM1673.83 652.173V675.052L1692.86 664.066V641.188L1673.83 652.173ZM1634.28 652.421L1673.48 675.052V652.173L1634.28 629.543V652.421ZM1614.11 640.982V663.86L1633.92 652.421V629.543L1614.11 640.982Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1439.7 585.869V540.197L1420.32 529.006V505.51L1460.84 482.121L1500.39 504.957L1519.77 493.766L1540.12 505.517V552.31L1500.56 575.146V597.528L1480.22 609.272L1439.7 585.869ZM1460.4 597.415L1480.22 608.854L1500.03 597.415L1480.22 585.975L1460.4 597.415ZM1440.23 585.77L1460.05 597.209L1479.86 585.77L1460.05 574.33L1440.23 585.77ZM1480.4 585.663L1500.21 597.103V575.132L1480.4 563.693V585.663ZM1440.06 540.197V585.465L1459.87 574.025V528.758L1440.06 540.197ZM1460.22 574.018L1480.04 585.458V540.197L1460.22 528.758V574.018ZM1480.58 563.388L1500.39 574.827L1539.58 552.197L1519.77 540.757L1480.58 563.388ZM1480.4 540.197V563.076L1519.59 540.445V517.567L1480.4 540.197ZM1519.95 540.445L1539.76 551.884V529.006L1519.95 517.567V540.445ZM1441.02 517.255L1480.22 539.885L1500.03 528.446L1460.83 505.815L1441.02 517.255ZM1420.85 528.9L1439.88 539.885L1459.69 528.446L1440.67 517.46L1420.85 528.9ZM1420.68 505.716V528.595L1440.49 517.155V494.277L1420.68 505.716ZM1519.95 517.155L1539.76 528.595V505.716L1519.95 494.277V517.155ZM1500.57 505.255V528.134L1519.59 517.148V494.27L1500.57 505.255ZM1461.01 505.503L1500.21 528.134V505.255L1461.01 482.625V505.503ZM1440.84 494.064V516.942L1460.66 505.503V482.625L1440.84 494.064Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1465.3 994.871V949.199L1445.92 938.008V914.512L1486.43 891.123L1525.98 913.959L1545.36 902.768L1565.71 914.519V961.312L1526.15 984.148V1006.53L1505.81 1018.27L1465.3 994.871ZM1485.99 1006.42L1505.81 1017.86L1525.62 1006.42L1505.81 994.977L1485.99 1006.42ZM1465.83 994.772L1485.64 1006.21L1505.45 994.772L1485.64 983.332L1465.83 994.772ZM1505.99 994.665L1525.81 1006.1V984.134L1505.99 972.694V994.665ZM1465.65 949.199V994.467L1485.46 983.027V937.76L1465.65 949.199ZM1485.82 983.02L1505.63 994.46V949.199L1485.82 937.76V983.02ZM1506.17 972.39L1525.98 983.829L1565.18 961.198L1545.36 949.759L1506.17 972.39ZM1505.99 949.199V972.077L1545.19 949.447V926.569L1505.99 949.199ZM1545.54 949.447L1565.35 960.886V938.008L1545.54 926.569V949.447ZM1466.61 926.256L1505.81 948.887L1525.62 937.448L1486.43 914.817L1466.61 926.256ZM1446.45 937.901L1465.47 948.887L1485.29 937.448L1466.26 926.462L1446.45 937.901ZM1446.27 914.718V937.597L1466.08 926.157V903.279L1446.27 914.718ZM1545.54 926.157L1565.35 937.597V914.718L1545.54 903.279V926.157ZM1526.16 914.257V937.136L1545.19 926.15V903.272L1526.16 914.257ZM1486.6 914.505L1525.81 937.136V914.257L1486.6 891.627V914.505ZM1466.44 903.066V925.944L1486.25 914.505V891.627L1466.44 903.066Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1659.84 1030.67V985.001L1640.46 973.81V950.314L1680.98 926.925L1720.53 949.761L1739.91 938.57L1760.25 950.321V997.114L1720.7 1019.95V1042.33L1700.35 1054.08L1659.84 1030.67ZM1680.54 1042.22L1700.35 1053.66L1720.16 1042.22L1700.35 1030.78L1680.54 1042.22ZM1660.37 1030.57L1680.18 1042.01L1700 1030.57L1680.18 1019.13L1660.37 1030.57ZM1700.54 1030.47L1720.35 1041.91V1019.94L1700.54 1008.5V1030.47ZM1660.19 985.001V1030.27L1680.01 1018.83V973.561L1660.19 985.001ZM1680.36 1018.82L1700.17 1030.26V985.001L1680.36 973.561V1018.82ZM1700.71 1008.19L1720.53 1019.63L1759.72 997L1739.91 985.561L1700.71 1008.19ZM1700.54 985.001V1007.88L1739.73 985.249V962.37L1700.54 985.001ZM1740.08 985.249L1759.9 996.688V973.81L1740.08 962.37V985.249ZM1661.16 962.058L1700.35 984.689L1720.16 973.249L1680.97 950.619L1661.16 962.058ZM1640.99 973.703L1660.02 984.689L1679.83 973.249L1660.8 962.264L1640.99 973.703ZM1640.81 950.52V973.398L1660.63 961.959V939.08L1640.81 950.52ZM1740.08 961.959L1759.9 973.398V950.52L1740.08 939.08V961.959ZM1720.7 950.059V972.937L1739.73 961.952V939.073L1720.7 950.059ZM1681.15 950.307L1720.35 972.937V950.059L1681.15 927.428V950.307ZM1660.98 938.868V961.746L1680.79 950.307V927.428L1660.98 938.868Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1445.22 1107.73V1062.05L1425.84 1050.86V1027.37L1466.36 1003.98L1505.91 1026.81L1525.29 1015.62L1545.64 1027.37V1074.17L1506.08 1097V1119.38L1485.74 1131.13L1445.22 1107.73ZM1465.92 1119.27L1485.73 1130.71L1505.54 1119.27L1485.73 1107.83L1465.92 1119.27ZM1445.75 1107.63L1465.56 1119.07L1485.38 1107.63L1465.56 1096.19L1445.75 1107.63ZM1485.91 1107.53L1505.72 1118.97V1097L1485.91 1085.56V1107.53ZM1445.57 1062.05V1107.32L1465.39 1095.88V1050.61L1445.57 1062.05ZM1465.74 1095.87L1485.55 1107.31V1062.05L1465.74 1050.61V1095.87ZM1486.09 1085.24L1505.9 1096.68L1545.09 1074.05L1525.28 1062.61L1486.09 1085.24ZM1485.91 1062.05V1084.93L1525.1 1062.3V1039.42L1485.91 1062.05ZM1525.46 1062.3L1545.28 1073.74V1050.86L1525.46 1039.42V1062.3ZM1426.37 1050.76L1445.4 1061.75L1465.21 1050.31L1446.18 1039.32L1426.37 1050.76ZM1446.54 1039.12L1485.73 1061.75L1505.54 1050.31L1466.35 1027.68L1446.54 1039.12ZM1426.19 1027.57V1050.45L1446.01 1039.01V1016.13L1426.19 1027.57ZM1525.46 1039.01L1545.28 1050.45V1027.57L1525.46 1016.13V1039.01ZM1506.08 1027.12V1050L1525.11 1039.01V1016.13L1506.08 1027.12ZM1466.53 1027.37L1505.73 1050V1027.12L1466.53 1004.49V1027.37ZM1446.36 1015.93V1038.81L1466.17 1027.37V1004.49L1446.36 1015.93Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1639.76 1143.53V1097.86L1620.38 1086.66V1063.17L1660.9 1039.78L1700.46 1062.62L1719.84 1051.42L1740.18 1063.18V1109.97L1700.63 1132.8V1155.19L1680.28 1166.93L1639.76 1143.53ZM1660.46 1155.07L1680.27 1166.51L1700.09 1155.07L1680.27 1143.63L1660.46 1155.07ZM1640.29 1143.43L1660.11 1154.87L1679.92 1143.43L1660.11 1131.99L1640.29 1143.43ZM1680.45 1143.33L1700.27 1154.77V1132.8L1680.45 1121.36V1143.33ZM1640.12 1097.86V1143.12L1659.93 1131.68V1086.42L1640.12 1097.86ZM1660.28 1131.68L1680.1 1143.12V1097.86L1660.28 1086.42V1131.68ZM1680.63 1121.05L1700.44 1132.49L1739.64 1109.85L1719.82 1098.42L1680.63 1121.05ZM1680.45 1097.86V1120.73L1719.65 1098.1V1075.22L1680.45 1097.86ZM1720.01 1098.1L1739.82 1109.54V1086.66L1720.01 1075.22V1098.1ZM1620.91 1086.56L1639.94 1097.55L1659.75 1086.11L1640.73 1075.13L1620.91 1086.56ZM1641.08 1074.92L1680.27 1097.55L1700.09 1086.11L1660.89 1063.48L1641.08 1074.92ZM1620.74 1063.37V1086.25L1640.55 1074.81V1051.93L1620.74 1063.37ZM1720.01 1074.81L1739.82 1086.25V1063.37L1720.01 1051.93V1074.81ZM1700.63 1062.92V1085.8L1719.65 1074.81V1051.93L1700.63 1062.92ZM1661.07 1063.17L1700.27 1085.8V1062.92L1661.07 1040.29V1063.17ZM1640.9 1051.73V1074.61L1660.72 1063.17V1040.29L1640.9 1051.73Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1532.22 1185.75V1140.08L1512.84 1128.88V1105.39L1553.36 1082L1592.91 1104.84L1612.29 1093.64L1632.64 1105.4V1152.19L1593.08 1175.02V1197.41L1572.74 1209.15L1532.22 1185.75ZM1552.92 1197.29L1572.73 1208.73L1592.54 1197.29L1572.73 1185.85L1552.92 1197.29ZM1532.75 1185.65L1552.56 1197.09L1572.38 1185.65L1552.56 1174.21L1532.75 1185.65ZM1572.91 1185.55L1592.72 1196.99V1175.02L1572.91 1163.58V1185.55ZM1532.57 1140.08V1185.34L1552.39 1173.9V1128.64L1532.57 1140.08ZM1552.74 1173.9L1572.55 1185.34V1140.08L1552.74 1128.64V1173.9ZM1573.09 1163.27L1592.9 1174.71L1632.09 1152.08L1612.28 1140.64L1573.09 1163.27ZM1572.91 1140.08V1162.95L1612.1 1140.32V1117.45L1572.91 1140.08ZM1612.46 1140.32L1632.28 1151.76V1128.88L1612.46 1117.45V1140.32ZM1513.37 1128.79L1532.4 1139.77L1552.21 1128.33L1533.18 1117.35L1513.37 1128.79ZM1533.54 1117.14L1572.73 1139.77L1592.54 1128.33L1553.35 1105.7L1533.54 1117.14ZM1513.19 1105.59V1128.47L1533.01 1117.03V1094.16L1513.19 1105.59ZM1612.46 1117.03L1632.28 1128.47V1105.59L1612.46 1094.16V1117.03ZM1593.08 1105.14V1128.02L1612.11 1117.03V1094.16L1593.08 1105.14ZM1553.53 1105.39L1592.73 1128.02V1105.14L1553.53 1082.51V1105.39ZM1533.36 1093.95V1116.83L1553.17 1105.39V1082.51L1533.36 1093.95Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1549.52 545.679V500.007L1530.14 488.815V465.32L1570.66 441.931L1610.2 464.767L1629.58 453.576L1649.93 465.327V512.12L1610.37 534.956V557.338L1590.03 569.082L1549.52 545.679ZM1570.22 557.224L1590.03 568.664L1609.84 557.224L1590.03 545.785L1570.22 557.224ZM1550.05 545.579L1569.86 557.019L1589.67 545.579L1569.86 534.14L1550.05 545.579ZM1590.21 545.473L1610.03 556.912V534.941L1590.21 523.502V545.473ZM1549.87 500.007V545.274L1569.68 533.835V488.567L1549.87 500.007ZM1570.04 533.828L1589.85 545.267V500.007L1570.04 488.567V533.828ZM1590.39 523.197L1610.2 534.636L1649.4 512.006L1629.58 500.567L1590.39 523.197ZM1590.21 500.007V522.885L1629.41 500.255V477.376L1590.21 500.007ZM1629.76 500.255L1649.57 511.694V488.815L1629.76 477.376V500.255ZM1550.84 477.064L1590.03 499.694L1609.84 488.255L1570.65 465.625L1550.84 477.064ZM1530.67 488.709L1549.69 499.694L1569.51 488.255L1550.48 477.27L1530.67 488.709ZM1530.49 465.526V488.404L1550.3 476.965V454.086L1530.49 465.526ZM1629.76 476.965L1649.57 488.404V465.526L1629.76 454.086V476.965ZM1610.38 465.065V487.943L1629.41 476.958V454.079L1610.38 465.065ZM1570.83 465.313L1610.03 487.943V465.065L1570.83 442.434V465.313ZM1550.66 453.874V476.752L1570.47 465.313V442.434L1550.66 453.874Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1657.08 505.228V459.556L1637.7 448.365V424.87L1678.22 401.48L1717.77 424.317L1737.15 413.125L1757.49 424.877V471.669L1717.94 494.505V516.888L1697.59 528.632L1657.08 505.228ZM1677.78 516.774L1697.59 528.213L1717.41 516.774L1697.59 505.335L1677.78 516.774ZM1657.61 505.129L1677.42 516.568L1697.24 505.129L1677.42 493.69L1657.61 505.129ZM1697.78 505.023L1717.59 516.462V494.491L1697.78 483.052V505.023ZM1657.43 459.556V504.824L1677.25 493.385V448.117L1657.43 459.556ZM1677.6 493.378L1697.41 504.817V459.556L1677.6 448.117V493.378ZM1697.95 482.747L1717.77 494.186L1756.96 471.556L1737.15 460.117L1697.95 482.747ZM1697.78 459.556V482.435L1736.97 459.805V436.926L1697.78 459.556ZM1737.32 459.805L1757.14 471.244V448.365L1737.32 436.926V459.805ZM1658.4 436.614L1697.59 459.244L1717.41 447.805L1678.21 425.175L1658.4 436.614ZM1638.23 448.259L1657.26 459.244L1677.07 447.805L1658.04 436.82L1638.23 448.259ZM1638.05 425.075V447.954L1657.87 436.515V413.636L1638.05 425.075ZM1737.32 436.515L1757.14 447.954V425.075L1737.32 413.636V436.515ZM1717.94 424.614V447.493L1736.97 436.508V413.629L1717.94 424.614ZM1678.39 424.863L1717.59 447.493V424.614L1678.39 401.984V424.863ZM1658.22 413.423V436.302L1678.03 424.863V401.984L1658.22 413.423Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1569.22 432.198V386.526L1549.84 375.335V351.839L1590.36 328.45L1629.9 351.286L1649.29 340.095L1669.63 351.847V398.639L1630.07 421.475V443.857L1609.73 455.601L1569.22 432.198ZM1589.92 443.744L1609.73 455.183L1629.54 443.744L1609.73 432.304L1589.92 443.744ZM1569.75 432.099L1589.56 443.538L1609.38 432.099L1589.56 420.66L1569.75 432.099ZM1609.91 431.992L1629.73 443.432V421.461L1609.91 410.022V431.992ZM1569.57 386.526V431.794L1589.39 420.355V375.087L1569.57 386.526ZM1589.74 420.347L1609.55 431.787V386.526L1589.74 375.087V420.347ZM1610.09 409.717L1629.9 421.156L1669.1 398.526L1649.29 387.086L1610.09 409.717ZM1609.91 386.526V409.405L1649.11 386.774V363.896L1609.91 386.526ZM1649.46 386.774L1669.28 398.214V375.335L1649.46 363.896V386.774ZM1570.54 363.584L1609.73 386.214L1629.54 374.775L1590.35 352.144L1570.54 363.584ZM1550.37 375.229L1569.39 386.214L1589.21 374.775L1570.18 363.789L1550.37 375.229ZM1550.19 352.045V374.924L1570 363.484V340.606L1550.19 352.045ZM1649.46 363.484L1669.28 374.924V352.045L1649.46 340.606V363.484ZM1630.08 351.584V374.463L1649.11 363.477V340.599L1630.08 351.584ZM1590.53 351.832L1629.73 374.463V351.584L1590.53 328.954V351.832ZM1570.36 340.393V363.272L1590.17 351.832V328.954L1570.36 340.393Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1676.78 391.748V346.076L1657.4 334.885V311.389L1697.92 288L1737.47 310.836L1756.85 299.645L1777.19 311.396V358.189L1737.64 381.025V403.407L1717.29 415.151L1676.78 391.748ZM1697.48 403.294L1717.29 414.733L1737.11 403.294L1717.29 391.854L1697.48 403.294ZM1677.31 391.649L1697.13 403.088L1716.94 391.649L1697.13 380.209L1677.31 391.649ZM1717.48 391.542L1737.29 402.982V381.011L1717.48 369.571V391.542ZM1677.14 346.076V391.344L1696.95 379.904V334.637L1677.14 346.076ZM1697.3 379.897L1717.12 391.337V346.076L1697.3 334.637V379.897ZM1717.66 369.266L1737.47 380.706L1776.66 358.075L1756.85 346.636L1717.66 369.266ZM1717.48 346.076V368.954L1756.67 346.324V323.445L1717.48 346.076ZM1757.03 346.324L1776.84 357.763V334.885L1757.03 323.445V346.324ZM1678.1 323.133L1717.29 345.764L1737.11 334.325L1697.91 311.694L1678.1 323.133ZM1657.93 334.778L1676.96 345.764L1696.77 334.325L1677.75 323.339L1657.93 334.778ZM1657.76 311.595V334.473L1677.57 323.034V300.156L1657.76 311.595ZM1757.03 323.034L1776.84 334.473V311.595L1757.03 300.156V323.034ZM1737.65 311.134V334.012L1756.67 323.027V300.148L1737.65 311.134ZM1698.09 311.382L1737.29 334.012V311.134L1698.09 288.504V311.382ZM1677.92 299.943V322.821L1697.74 311.382V288.504L1677.92 299.943Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1590.22 318.718V273.046L1570.84 261.855V238.359L1611.36 214.97L1650.91 237.806L1670.29 226.615L1690.63 238.366V285.159L1651.08 307.995V330.377L1630.73 342.121L1590.22 318.718ZM1610.92 330.263L1630.73 341.703L1650.55 330.263L1630.73 318.824L1610.92 330.263ZM1590.75 318.618L1610.57 330.058L1630.38 318.618L1610.57 307.179L1590.75 318.618ZM1630.92 318.512L1650.73 329.951V307.98L1630.92 296.541V318.512ZM1590.58 273.046V318.313L1610.39 306.874V261.606L1590.58 273.046ZM1610.74 306.867L1630.56 318.306V273.046L1610.74 261.606V306.867ZM1631.1 296.236L1650.91 307.675L1690.1 285.045L1670.29 273.606L1631.1 296.236ZM1630.92 273.046V295.924L1670.11 273.294V250.415L1630.92 273.046ZM1670.47 273.294L1690.28 284.733V261.855L1670.47 250.415V273.294ZM1591.54 250.103L1630.73 272.734L1650.55 261.294L1611.35 238.664L1591.54 250.103ZM1571.37 261.748L1590.4 272.734L1610.21 261.294L1591.19 250.309L1571.37 261.748ZM1571.2 238.565V261.443L1591.01 250.004V227.125L1571.2 238.565ZM1670.47 250.004L1690.28 261.443V238.565L1670.47 227.125V250.004ZM1651.09 238.104V260.982L1670.11 249.997V227.118L1651.09 238.104ZM1611.53 238.352L1650.73 260.982V238.104L1611.53 215.473V238.352ZM1591.36 226.913V249.791L1611.18 238.352V215.473L1591.36 226.913Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1610.99 201.401V155.729L1591.61 144.538V121.043L1632.13 97.6533L1671.68 120.489L1691.06 109.298L1711.41 121.05V167.842L1671.85 190.678V213.06L1651.51 224.805L1610.99 201.401ZM1631.69 212.947L1651.51 224.386L1671.32 212.947L1651.51 201.508L1631.69 212.947ZM1611.52 201.302L1631.34 212.741L1651.15 201.302L1631.34 189.863L1611.52 201.302ZM1651.69 201.196L1671.5 212.635V190.664L1651.69 179.225V201.196ZM1611.35 155.729V200.997L1631.16 189.558V144.29L1611.35 155.729ZM1631.51 189.551L1651.33 200.99V155.729L1631.51 144.29V189.551ZM1651.87 178.92L1671.68 190.359L1710.87 167.729L1691.06 156.289L1651.87 178.92ZM1651.69 155.729V178.608L1690.88 155.977V133.099L1651.69 155.729ZM1691.24 155.977L1711.05 167.417V144.538L1691.24 133.099V155.977ZM1612.31 132.787L1651.51 155.417L1671.32 143.978L1632.12 121.347L1612.31 132.787ZM1592.14 144.432L1611.17 155.417L1630.98 143.978L1611.96 132.992L1592.14 144.432ZM1591.97 121.248V144.127L1611.78 132.687V109.809L1591.97 121.248ZM1691.24 132.687L1711.05 144.127V121.248L1691.24 109.809V132.687ZM1671.86 120.787V143.666L1690.88 132.68V109.802L1671.86 120.787ZM1632.3 121.035L1671.5 143.666V120.787L1632.3 98.1569V121.035ZM1612.13 109.596V132.475L1631.95 121.035V98.1569L1612.13 109.596Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1501.96 243.064V197.392L1482.58 186.201V162.706L1523.1 139.316L1562.65 162.152L1582.03 150.961L1602.37 162.713V209.505L1562.82 232.341V254.723L1542.47 266.468L1501.96 243.064ZM1522.66 254.61L1542.47 266.049L1562.29 254.61L1542.47 243.171L1522.66 254.61ZM1502.49 242.965L1522.3 254.404L1542.12 242.965L1522.3 231.526L1502.49 242.965ZM1542.66 242.859L1562.47 254.298V232.327L1542.66 220.888V242.859ZM1502.31 197.392V242.66L1522.13 231.221V185.953L1502.31 197.392ZM1522.48 231.214L1542.29 242.653V197.392L1522.48 185.953V231.214ZM1542.83 220.583L1562.65 232.022L1601.84 209.392L1582.03 197.953L1542.83 220.583ZM1542.66 197.392V220.271L1581.85 197.64V174.762L1542.66 197.392ZM1582.2 197.64L1602.02 209.08V186.201L1582.2 174.762V197.64ZM1503.28 174.45L1542.47 197.08L1562.29 185.641L1523.09 163.011L1503.28 174.45ZM1483.11 186.095L1502.14 197.08L1521.95 185.641L1502.92 174.656L1483.11 186.095ZM1482.93 162.911V185.79L1502.75 174.351V151.472L1482.93 162.911ZM1582.2 174.351L1602.02 185.79V162.911L1582.2 151.472V174.351ZM1562.82 162.45V185.329L1581.85 174.343V151.465L1562.82 162.45ZM1523.27 162.699L1562.47 185.329V162.45L1523.27 139.82V162.699ZM1503.1 151.259V174.138L1522.91 162.699V139.82L1503.1 151.259Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1522.73 125.748V80.0759L1503.35 68.8848V45.3892L1543.87 22L1583.42 44.836L1602.8 33.645L1623.14 45.3963V92.1889L1583.59 115.025V137.407L1563.24 149.151L1522.73 125.748ZM1543.43 137.294L1563.24 148.733L1583.06 137.294L1563.24 125.854L1543.43 137.294ZM1523.26 125.649L1543.07 137.088L1562.89 125.649L1543.07 114.209L1523.26 125.649ZM1563.43 125.542L1583.24 136.982V115.011L1563.43 103.571V125.542ZM1523.08 80.0759V125.344L1542.9 113.904V68.6366L1523.08 80.0759ZM1543.25 113.897L1563.07 125.337V80.0759L1543.25 68.6366V113.897ZM1563.6 103.266L1583.42 114.706L1622.61 92.0754L1602.8 80.6361L1563.6 103.266ZM1563.43 80.0759V102.954L1602.62 80.3241V57.4455L1563.43 80.0759ZM1602.98 80.3241L1622.79 91.7634V68.8848L1602.98 57.4455V80.3241ZM1524.05 57.1334L1563.24 79.7638L1583.06 68.3245L1543.86 45.6942L1524.05 57.1334ZM1503.88 68.7784L1522.91 79.7638L1542.72 68.3245L1523.69 57.3391L1503.88 68.7784ZM1503.7 45.5949V68.4735L1523.52 57.0342V34.1556L1503.7 45.5949ZM1602.98 57.0342L1622.79 68.4735V45.5949L1602.98 34.1556V57.0342ZM1583.59 45.1339V68.0125L1602.62 57.0271V34.1485L1583.59 45.1339ZM1544.04 45.3821L1583.24 68.0125V45.1339L1544.04 22.5035V45.3821ZM1523.87 33.9428V56.8214L1543.68 45.3821V22.5035L1523.87 33.9428Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1485.68 882.967V837.295L1466.3 826.104V802.608L1506.82 779.219L1546.37 802.055L1565.75 790.864L1586.1 802.615V849.408L1546.54 872.244V894.626L1526.2 906.37L1485.68 882.967ZM1506.38 894.512L1526.2 905.952L1546.01 894.512L1526.2 883.073L1506.38 894.512ZM1486.22 882.867L1506.03 894.307L1525.84 882.867L1506.03 871.428L1486.22 882.867ZM1526.38 882.761L1546.19 894.2V872.229L1526.38 860.79V882.761ZM1486.04 837.295V882.562L1505.85 871.123V825.855L1486.04 837.295ZM1506.21 871.116L1526.02 882.555V837.295L1506.21 825.855V871.116ZM1526.56 860.485L1546.37 871.925L1585.56 849.294L1565.75 837.855L1526.56 860.485ZM1526.38 837.295V860.173L1565.57 837.543V814.664L1526.38 837.295ZM1565.93 837.543L1585.74 848.982V826.104L1565.93 814.664V837.543ZM1487 814.352L1526.2 836.983L1546.01 825.543L1506.82 802.913L1487 814.352ZM1466.83 825.997L1485.86 836.983L1505.67 825.543L1486.65 814.558L1466.83 825.997ZM1466.66 802.814V825.692L1486.47 814.253V791.374L1466.66 802.814ZM1565.93 814.253L1585.74 825.692V802.814L1565.93 791.374V814.253ZM1546.55 802.353V825.231L1565.57 814.246V791.367L1546.55 802.353ZM1506.99 802.601L1546.19 825.231V802.353L1506.99 779.722V802.601ZM1486.82 791.162V814.04L1506.64 802.601V779.722L1486.82 791.162Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1680.23 918.768V873.096L1660.85 861.905V838.41L1701.37 815.021L1740.91 837.857L1760.29 826.665L1780.64 838.417V885.209L1741.08 908.045V930.428L1720.74 942.172L1680.23 918.768ZM1700.93 930.314L1720.74 941.753L1740.55 930.314L1720.74 918.875L1700.93 930.314ZM1680.76 918.669L1700.57 930.108L1720.38 918.669L1700.57 907.23L1680.76 918.669ZM1720.92 918.563L1740.74 930.002V908.031L1720.92 896.592V918.563ZM1680.58 873.096V918.364L1700.39 906.925V861.657L1680.58 873.096ZM1700.75 906.918L1720.56 918.357V873.096L1700.75 861.657V906.918ZM1721.1 896.287L1740.91 907.726L1780.11 885.096L1760.29 873.657L1721.1 896.287ZM1720.92 873.096V895.975L1760.12 873.345V850.466L1720.92 873.096ZM1760.47 873.345L1780.28 884.784V861.905L1760.47 850.466V873.345ZM1681.55 850.154L1720.74 872.784L1740.55 861.345L1701.36 838.715L1681.55 850.154ZM1661.38 861.799L1680.4 872.784L1700.22 861.345L1681.19 850.36L1661.38 861.799ZM1661.2 838.615V861.494L1681.01 850.055V827.176L1661.2 838.615ZM1760.47 850.055L1780.28 861.494V838.615L1760.47 827.176V850.055ZM1741.09 838.154V861.033L1760.12 850.048V827.169L1741.09 838.154ZM1701.54 838.403L1740.74 861.033V838.154L1701.54 815.524V838.403ZM1681.37 826.963V849.842L1701.18 838.403V815.524L1681.37 826.963Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1506.17 771.062V725.39L1486.79 714.199V690.704L1527.31 667.314L1566.86 690.15L1586.24 678.959L1606.59 690.711V737.503L1567.03 760.339V782.722L1546.68 794.466L1506.17 771.062ZM1526.87 782.608L1546.68 794.047L1566.5 782.608L1546.68 771.169L1526.87 782.608ZM1506.7 770.963L1526.52 782.402L1546.33 770.963L1526.52 759.524L1506.7 770.963ZM1546.87 770.857L1566.68 782.296V760.325L1546.87 748.886V770.857ZM1506.53 725.39V770.658L1526.34 759.219V713.951L1506.53 725.39ZM1526.69 759.212L1546.51 770.651V725.39L1526.69 713.951V759.212ZM1547.05 748.581L1566.86 760.02L1606.05 737.39L1586.24 725.951L1547.05 748.581ZM1546.87 725.39V748.269L1586.06 725.639V702.76L1546.87 725.39ZM1586.42 725.639L1606.23 737.078V714.199L1586.42 702.76V725.639ZM1507.49 702.448L1546.68 725.078L1566.5 713.639L1527.3 691.009L1507.49 702.448ZM1487.32 714.093L1506.35 725.078L1526.16 713.639L1507.14 702.654L1487.32 714.093ZM1487.15 690.909V713.788L1506.96 702.349V679.47L1487.15 690.909ZM1586.42 702.349L1606.23 713.788V690.909L1586.42 679.47V702.349ZM1567.04 690.448V713.327L1586.06 702.342V679.463L1567.04 690.448ZM1527.48 690.697L1566.68 713.327V690.448L1527.48 667.818V690.697ZM1507.31 679.257V702.136L1527.13 690.697V667.818L1507.31 679.257Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1700.72 806.864V761.192L1681.33 750.001V726.505L1721.85 703.116L1761.4 725.952L1780.78 714.761L1801.13 726.513V773.305L1761.57 796.141V818.523L1741.23 830.268L1700.72 806.864ZM1721.41 818.41L1741.23 829.849L1761.04 818.41L1741.23 806.97L1721.41 818.41ZM1701.25 806.765L1721.06 818.204L1740.87 806.765L1721.06 795.326L1701.25 806.765ZM1741.41 806.658L1761.23 818.098V796.127L1741.41 784.688V806.658ZM1701.07 761.192V806.46L1720.88 795.021V749.753L1701.07 761.192ZM1721.24 795.014L1741.05 806.453V761.192L1721.24 749.753V795.014ZM1741.59 784.383L1761.4 795.822L1800.6 773.192L1780.78 761.752L1741.59 784.383ZM1741.41 761.192V784.071L1780.61 761.44V738.562L1741.41 761.192ZM1780.96 761.44L1800.77 772.88V750.001L1780.96 738.562V761.44ZM1702.03 738.25L1741.23 760.88L1761.04 749.441L1721.85 726.81L1702.03 738.25ZM1681.87 749.895L1700.89 760.88L1720.71 749.441L1701.68 738.455L1681.87 749.895ZM1681.69 726.711V749.59L1701.5 738.15V715.272L1681.69 726.711ZM1780.96 738.15L1800.77 749.59V726.711L1780.96 715.272V738.15ZM1761.58 726.25V749.129L1780.61 738.143V715.265L1761.58 726.25ZM1722.02 726.498L1761.23 749.129V726.25L1722.02 703.62V726.498ZM1701.86 715.059V737.938L1721.67 726.498V703.62L1701.86 715.059Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1527.45 659.947V614.275L1508.07 603.084V579.588L1548.59 556.199L1588.14 579.035L1607.52 567.844L1627.86 579.596V626.388L1588.31 649.224V671.606L1567.96 683.351L1527.45 659.947ZM1548.15 671.493L1567.96 682.932L1587.78 671.493L1567.96 660.054L1548.15 671.493ZM1527.98 659.848L1547.8 671.287L1567.61 659.848L1547.8 648.409L1527.98 659.848ZM1568.15 659.741L1587.96 671.181V649.21L1568.15 637.771V659.741ZM1527.81 614.275V659.543L1547.62 648.104V602.836L1527.81 614.275ZM1547.97 648.097L1567.79 659.536V614.275L1547.97 602.836V648.097ZM1568.32 637.466L1588.14 648.905L1627.33 626.275L1607.52 614.835L1568.32 637.466ZM1568.15 614.275V637.154L1607.34 614.523V591.645L1568.15 614.275ZM1607.7 614.523L1627.51 625.963V603.084L1607.7 591.645V614.523ZM1528.77 591.333L1567.96 613.963L1587.78 602.524L1548.58 579.893L1528.77 591.333ZM1508.6 602.978L1527.63 613.963L1547.44 602.524L1528.42 591.538L1508.6 602.978ZM1508.42 579.794V602.673L1528.24 591.233V568.355L1508.42 579.794ZM1607.7 591.233L1627.51 602.673V579.794L1607.7 568.355V591.233ZM1588.32 579.333V602.212L1607.34 591.226V568.348L1588.32 579.333ZM1548.76 579.581L1587.96 602.212V579.333L1548.76 556.703V579.581ZM1528.59 568.142V591.021L1548.41 579.581V556.703L1528.59 568.142Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1635.01 619.496V573.824L1615.63 562.633V539.137L1656.15 515.748L1695.7 538.584L1715.08 527.393L1735.43 539.144V585.937L1695.87 608.773V631.155L1675.53 642.899L1635.01 619.496ZM1655.71 631.042L1675.53 642.481L1695.34 631.042L1675.53 619.602L1655.71 631.042ZM1635.55 619.397L1655.36 630.836L1675.17 619.397L1655.36 607.957L1635.55 619.397ZM1675.71 619.29L1695.52 630.73V608.759L1675.71 597.319V619.29ZM1635.37 573.824V619.092L1655.18 607.652V562.385L1635.37 573.824ZM1655.54 607.645L1675.35 619.085V573.824L1655.54 562.385V607.645ZM1675.89 597.015L1695.7 608.454L1734.9 585.823L1715.08 574.384L1675.89 597.015ZM1675.71 573.824V596.702L1714.9 574.072V551.194L1675.71 573.824ZM1715.26 574.072L1735.07 585.511V562.633L1715.26 551.194V574.072ZM1636.33 550.881L1675.53 573.512L1695.34 562.073L1656.15 539.442L1636.33 550.881ZM1616.17 562.526L1635.19 573.512L1655 562.073L1635.98 551.087L1616.17 562.526ZM1615.99 539.343V562.222L1635.8 550.782V527.904L1615.99 539.343ZM1715.26 550.782L1735.07 562.222V539.343L1715.26 527.904V550.782ZM1695.88 538.882V561.761L1714.9 550.775V527.897L1695.88 538.882ZM1656.32 539.13L1695.52 561.761V538.882L1656.32 516.252V539.13ZM1636.16 527.691V550.569L1655.97 539.13V516.252L1636.16 527.691Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1721.99 695.748V650.076L1702.61 638.885V615.389L1743.13 592L1782.68 614.836L1802.06 603.645L1822.41 615.396V662.189L1782.85 685.025V707.407L1762.51 719.151L1721.99 695.748ZM1742.69 707.294L1762.51 718.733L1782.32 707.294L1762.51 695.854L1742.69 707.294ZM1722.53 695.649L1742.34 707.088L1762.15 695.649L1742.34 684.209L1722.53 695.649ZM1762.69 695.542L1782.5 706.982V685.011L1762.69 673.571V695.542ZM1722.35 650.076V695.344L1742.16 683.904V638.637L1722.35 650.076ZM1742.52 683.897L1762.33 695.337V650.076L1742.52 638.637V683.897ZM1762.87 673.266L1782.68 684.706L1821.87 662.075L1802.06 650.636L1762.87 673.266ZM1762.69 650.076V672.954L1801.88 650.324V627.445L1762.69 650.076ZM1802.24 650.324L1822.05 661.763V638.885L1802.24 627.445V650.324ZM1723.31 627.133L1762.51 649.764L1782.32 638.325L1743.13 615.694L1723.31 627.133ZM1703.15 638.778L1722.17 649.764L1741.98 638.325L1722.96 627.339L1703.15 638.778ZM1702.97 615.595V638.473L1722.78 627.034V604.156L1702.97 615.595ZM1802.24 627.034L1822.05 638.473V615.595L1802.24 604.156V627.034ZM1782.86 615.134V638.012L1801.88 627.027V604.148L1782.86 615.134ZM1743.3 615.382L1782.5 638.012V615.134L1743.3 592.504V615.382ZM1723.14 603.943V626.821L1742.95 615.382V592.504L1723.14 603.943Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1137.09 590.051V544.372L1117.71 533.181V509.685L1158.23 486.296L1197.78 509.132L1217.16 497.941L1237.5 509.692V556.485L1197.95 579.321V601.703L1177.6 613.447L1137.09 590.051ZM1157.79 601.597L1177.6 613.036L1197.41 601.597L1177.6 590.157L1157.79 601.597ZM1137.62 589.952L1157.43 601.391L1177.25 589.952L1157.43 578.512L1137.62 589.952ZM1177.78 589.845L1197.59 601.285V579.314L1177.78 567.874V589.845ZM1137.44 544.379V589.647L1157.26 578.207V532.947L1137.44 544.379ZM1157.61 578.2L1177.42 589.64V544.379L1157.61 532.94V578.2ZM1177.96 567.562L1197.77 579.002L1236.96 556.371L1217.15 544.932L1177.96 567.562ZM1177.78 544.379V567.257L1216.97 544.627V521.748L1177.78 544.379ZM1217.33 544.627L1237.15 556.066V533.188L1217.33 521.748V544.627ZM1138.41 521.436L1177.6 544.067L1197.41 532.628L1158.22 509.997L1138.41 521.436ZM1118.24 533.081L1137.27 544.067L1157.08 532.628L1138.05 521.642L1118.24 533.081ZM1118.06 509.891V532.769L1137.87 521.33V498.451L1118.06 509.891ZM1217.33 521.337L1237.15 532.776V509.898L1217.33 498.459V521.337ZM1197.95 509.437V532.315L1216.97 521.33V498.451L1197.95 509.437ZM1158.4 509.685L1197.6 532.315V509.437L1158.4 486.807V509.685ZM1138.22 498.246V521.124L1158.04 509.685V486.807L1138.22 498.246Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1115.81 702.509V656.83L1096.43 645.639V622.143L1136.95 598.754L1176.5 621.59L1195.88 610.399L1216.22 622.15V668.943L1176.67 691.779V714.161L1156.32 725.905L1115.81 702.509ZM1136.51 714.055L1156.32 725.494L1176.14 714.055L1156.32 702.615L1136.51 714.055ZM1116.34 702.41L1136.16 713.849L1155.97 702.41L1136.16 690.97L1116.34 702.41ZM1156.5 702.303L1176.31 713.743V691.772L1156.5 680.332V702.303ZM1116.17 656.837V702.105L1135.98 690.665V645.405L1116.17 656.837ZM1136.33 690.658L1156.15 702.098V656.837L1136.33 645.398V690.658ZM1156.68 680.02L1176.49 691.46L1215.68 668.829L1195.87 657.39L1156.68 680.02ZM1156.5 656.837V679.715L1195.69 657.085V634.206L1156.5 656.837ZM1196.06 657.085L1215.87 668.524V645.646L1196.06 634.206V657.085ZM1117.13 633.894L1156.32 656.525L1176.14 645.086L1136.94 622.455L1117.13 633.894ZM1096.96 645.539L1115.99 656.525L1135.8 645.086L1116.78 634.1L1096.96 645.539ZM1096.78 622.349V645.227L1116.59 633.788V610.909L1096.78 622.349ZM1196.06 633.795L1215.87 645.234V622.356L1196.06 610.917V633.795ZM1176.67 621.895V644.773L1195.69 633.788V610.909L1176.67 621.895ZM1137.12 622.143L1176.32 644.773V621.895L1137.12 599.265V622.143ZM1116.95 610.704V633.582L1136.76 622.143V599.265L1116.95 610.704Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1224.49 662.978V617.299L1205.11 606.107V582.612L1245.63 559.223L1285.18 582.059L1304.56 570.868L1324.9 582.612V629.404L1285.35 652.24V674.623L1265 686.367L1224.49 662.978ZM1245.19 674.523L1265.01 685.963L1284.82 674.523L1265.01 663.084L1245.19 674.523ZM1225.03 662.878L1244.84 674.318L1264.65 662.878L1244.84 651.439L1225.03 662.878ZM1265.18 662.772L1285 674.211V652.24L1265.18 640.801V662.772ZM1224.84 617.306V662.566L1244.66 651.127V605.859L1224.84 617.306ZM1245.02 651.127L1264.83 662.566V617.306L1245.02 605.866V651.127ZM1265.36 640.496L1285.18 651.936L1324.37 629.305L1304.56 617.866L1265.36 640.496ZM1265.18 617.306V640.184L1304.38 617.554V594.675L1265.18 617.306ZM1304.74 617.554L1324.55 628.993V606.115L1304.74 594.675V617.554ZM1225.81 594.363L1265.01 616.994L1284.82 605.554L1245.63 582.924L1225.81 594.363ZM1205.64 606.008L1224.67 616.994L1244.48 605.554L1225.45 594.569L1205.64 606.008ZM1205.46 582.817V605.696L1225.28 594.257V571.378L1205.46 582.817ZM1304.74 594.264L1324.55 605.703V582.825L1304.74 571.385V594.264ZM1285.35 582.364V605.242L1304.38 594.257V571.378L1285.35 582.364ZM1245.8 582.612L1285 605.242V582.364L1245.8 559.733V582.612ZM1225.63 571.173V594.051L1245.44 582.612V559.733L1225.63 571.173Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1332.25 623.865V578.186L1312.87 566.995V543.5L1353.39 520.11L1392.95 542.946L1412.33 531.755L1432.67 543.507V590.299L1393.12 613.135V635.517L1372.77 647.262L1332.25 623.865ZM1352.95 635.404L1372.77 646.843L1392.58 635.404L1372.77 623.965L1352.95 635.404ZM1332.79 623.759L1352.6 635.198L1372.41 623.759L1352.6 612.32L1332.79 623.759ZM1372.94 623.66L1392.76 635.099V613.128L1372.94 601.689V623.66ZM1332.61 578.186V623.454L1352.42 612.015V566.747L1332.61 578.186ZM1352.78 612.008L1372.59 623.447V578.186L1352.78 566.747V612.008ZM1373.12 601.377L1392.93 612.816L1432.13 590.186L1412.31 578.746L1373.12 601.377ZM1372.94 578.186V601.065L1412.14 578.434V555.556L1372.94 578.186ZM1412.5 578.434L1432.31 589.874V566.995L1412.5 555.556V578.434ZM1313.4 566.896L1332.43 577.881L1352.24 566.442L1333.22 555.457L1313.4 566.896ZM1333.57 555.251L1372.77 577.881L1392.58 566.442L1353.39 543.812L1333.57 555.251ZM1313.23 543.705V566.584L1333.04 555.145V532.266L1313.23 543.705ZM1412.5 555.145L1432.31 566.584V543.705L1412.5 532.266V555.145ZM1393.12 543.251V566.13L1412.14 555.145V532.266L1393.12 543.251ZM1353.56 543.5L1392.76 566.13V543.251L1353.56 520.621V543.5ZM1333.4 532.06V554.939L1353.21 543.5V520.621L1333.4 532.06Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M1244.8 550.818V505.139L1225.42 493.948V470.445L1265.94 447.056L1305.49 469.892L1324.88 458.701L1345.22 470.452V517.245L1305.67 540.081V562.463L1285.32 574.207L1244.8 550.818ZM1265.5 562.363L1285.32 573.803L1305.13 562.363L1285.32 550.924L1265.5 562.363ZM1245.34 550.718L1265.15 562.158L1284.96 550.718L1265.15 539.279L1245.34 550.718ZM1285.49 550.612L1305.31 562.051V540.081L1285.49 528.641V550.612ZM1245.16 505.146V550.414L1264.97 538.974V493.699L1245.16 505.146ZM1265.33 538.967L1285.14 550.406V505.146L1265.33 493.706V538.967ZM1285.67 528.329L1305.48 539.769L1344.68 517.138L1324.86 505.699L1285.67 528.329ZM1285.49 505.146V528.024L1324.69 505.394V482.515L1285.49 505.146ZM1325.05 505.394L1344.86 516.833V493.955L1325.05 482.515V505.394ZM1246.12 482.203L1285.32 504.834L1305.13 493.394L1265.94 470.764L1246.12 482.203ZM1225.96 493.848L1244.98 504.834L1264.79 493.394L1245.77 482.409L1225.96 493.848ZM1225.78 470.658V493.536L1245.59 482.097V459.218L1225.78 470.658ZM1325.05 482.097L1344.86 493.536V470.658L1325.05 459.218V482.097ZM1305.67 470.204V493.082L1324.69 482.097V459.218L1305.67 470.204ZM1266.11 470.452L1305.31 493.082V470.204L1266.11 447.573V470.452ZM1245.95 459.013V481.891L1265.76 470.452V447.573L1245.95 459.013Z"
              fill="#192530"
            ></path>
          </g>
          <g>
            {" "}
            <path
              d="M839.094 1117.21V1071.72L819.714 1060.57V1037.17L860.233 1013.88L899.782 1036.62L919.162 1025.47L939.507 1037.17V1083.77L899.952 1106.51V1128.81L879.607 1140.51L839.094 1117.21ZM859.794 1128.71L879.614 1140.1L899.434 1128.71L879.614 1117.31L859.794 1128.71ZM839.619 1117.11L859.439 1128.51L879.259 1117.11L859.439 1105.72L839.619 1117.11ZM879.791 1117.01L899.604 1128.41V1106.52L879.791 1095.12V1117.01ZM839.449 1071.72V1116.81L859.262 1105.41V1060.32L839.449 1071.72ZM859.617 1105.41L879.43 1116.81V1071.72L859.617 1060.32V1105.41ZM879.961 1094.82L899.782 1106.21L938.982 1083.67L919.162 1072.27L879.961 1094.82ZM879.791 1071.72V1094.52L918.985 1071.97V1049.17L879.791 1071.72ZM919.339 1071.97L939.152 1083.37V1060.58L919.339 1049.18V1071.97ZM820.239 1060.47L839.272 1071.42L859.092 1060.02L840.059 1049.08L820.239 1060.47ZM840.406 1048.87L879.607 1071.42L899.427 1060.02L860.226 1037.48L840.406 1048.87ZM919.339 1048.77L939.152 1060.17V1037.37L919.339 1025.97V1048.77ZM820.068 1037.37V1060.17L839.881 1048.77V1025.98L820.068 1037.37ZM899.959 1036.92V1059.71L918.985 1048.77V1025.97L899.959 1036.92ZM860.404 1037.17L899.604 1059.71V1036.92L860.404 1014.38V1037.17ZM840.236 1025.77V1048.57L860.049 1037.17V1014.38L840.236 1025.77Z"
              fill="#192530"
            ></path>
          </g>
        </svg>
      </div>
    </div>
    // </div>
  );
}

export default Background;
