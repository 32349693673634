import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiSelect({
  options = [],
  name = null,
  secondName = null,
  label = "",
  placeholder = "",
  value = null,
  onChange = null,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    // If value prop is provided, it should also set the selectedUsers state
    if (value !== null) {
      setSelectedUsers(value);
    }
  }, [value]); // Re-run if the `value` prop changes

  const handleOnChange = (event, newValue) => {
    // Update selected users and notify parent
    setSelectedUsers(newValue);
    onChange(newValue);
  };

  const isUserSelected = (user) => {
    // Check if a user is in the selectedUsers list
    return selectedUsers.some((selected) => selected[name] === user[name]);
  };
  // Filter out already selected users from the options list
  const availableOptions = options.filter((option) => !isUserSelected(option));
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={availableOptions} // Use filtered options
      disableCloseOnSelect
      limitTags={3}
      getOptionLabel={(option) => option[name]}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            disabled={isUserSelected(option)} // Disable if the user is already selected
          />
          {secondName ? `${option[name]} ${option[secondName]}` : option[name]}
        </React.Fragment>
      )}
      style={{
        width: "100%",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          label={label}
          placeholder={placeholder}
          variant="outlined"
          style={{
            width: "100%",
          }}
        />
      )}
      onChange={handleOnChange} // Handle user selection
      value={selectedUsers} // Bind selected users to the Autocomplete component
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
