import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { Typography } from "@material-ui/core";
import { ExamContextConsumer } from "../../context";

function MyTimer({ expiryTimestamp, onExpire }) {
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  useEffect(() => {
    // Restart the timer when the expiryTimestamp changes
    restart(expiryTimestamp);
  }, [expiryTimestamp, restart]);

  return (
    <div
      style={{
        gap: "4px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography noWrap style={{ marginRight: 8, fontWeight: 600 }}>
        Time Left:
      </Typography>
      <div style={{ fontSize: "20px", color: "red" }}>
        <span>{hours < 10 ? `0${hours}` : hours}</span>:
        <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
        <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </div>
    </div>
  );
}

export default function Timer({ start, end }) {
  const expiryTimestamp = new Date(end);

  // Custom handler for when the timer expires
  const handleExpire = () => {
    const { exitExam } = ExamContextConsumer();
    exitExam("Exited", true);
  };

  return <MyTimer expiryTimestamp={expiryTimestamp} onExpire={handleExpire} />;
}
