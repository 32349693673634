import React from "react";
import { editAnswer } from "../../../../../redux/actions/exam";
import { Container, Text } from "../../../../../styled";
import { ExamContextConsumer } from "../../context";
import { useDispatch } from "react-redux";
const Question = ({ question, examid }) => {
  const dispatch = useDispatch();
  const { count, answer, setAnswer } = ExamContextConsumer();
  const { answers } = answer;
  React.useEffect(() => {
    async function func() {
      if (await dispatch(editAnswer({ answers }, examid, answer._id))) {
        return;
      }
    }
    func();
  }, [answers, dispatch]);
  const onAnswerSelect = (optionid) => {
    let ans = answers.find((a) => {
      return String(a.questionid) === String(question._id);
    });
    if (ans) {
      setAnswer({
        ...answer,
        answers: [
          ...answers.map((a) => {
            if (String(a.questionid) === String(question._id))
              return { ...a, optionid: optionid };
            else return { ...a };
          }),
        ],
      });
    } else {
      let ans = { questionid: question._id, optionid: optionid };
      setAnswer({ ...answer, answers: [...answers, ans] });
    }
  };

  const isChecked = (optionid) => {
    let ans = answers.find((a) => {
      return (
        String(a.questionid) === String(question._id) &&
        String(a.optionid) === String(optionid)
      );
    });
    if (ans) return String(ans.optionid) === String(optionid);
    else return false;
  };

  return (
    <Container width="100%" direction="column">
      <Container justify="flex-end">
        <Text width="10%" size="15px">
          Mark: <b style={{ color: "#47926E" }}>{question.mark}</b>
        </Text>
      </Container>
      <Container direction="row" width="850px">
        <Text width="10%" size="15px">
          <b style={{ color: "#47926E" }}>Question {count + 1} </b>
        </Text>
        <Text width="90%" size="15px">
          {question.title}
        </Text>
      </Container>
      <Container direction="row" align="flex-end" justify="space-between">
        <Container width="90%">
          <ol style={{ paddingLeft: 0 }}>
            <li
              style={{
                display: "flex",
                fontSize: "16px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="answer-1"
                name="answer"
                checked={isChecked(question.options[0]._id)}
                onChange={() => onAnswerSelect(question.options[0]._id)}
              />
              <label id="answer" for="answer-1" name="answer">
                {question.options[0].option}
              </label>
            </li>
            <li
              style={{
                marginTop: "16px",
                display: "flex",
                fontSize: "16px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="answer-2"
                name="answer"
                checked={isChecked(question.options[1]._id)}
                onChange={() => onAnswerSelect(question.options[1]._id)}
              />
              <label id="answer" name="answer" for="answer-2">
                {question.options[1].option}
              </label>
            </li>
            <li
              style={{
                marginTop: "16px",
                display: "flex",
                fontSize: "16px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="answer-3"
                name="answer"
                checked={isChecked(question.options[2]._id)}
                onChange={() => onAnswerSelect(question.options[2]._id)}
              />
              <label id="answer" name="answer" for="answer-3">
                {question.options[2].option}
              </label>
            </li>
            <li
              style={{
                marginTop: "16px",
                display: "flex",
                fontSize: "16px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                id="answer-4"
                name="answer"
                checked={isChecked(question.options[3]._id)}
                onChange={() => onAnswerSelect(question.options[3]._id)}
              />
              <label id="answer" name="answer" for="answer-4">
                {question.options[3].option}
              </label>
            </li>
          </ol>
        </Container>
      </Container>
    </Container>
  );
};

export default Question;
