import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Button } from "../../../styled";
import MultiSelect from "../../materialui/MultiSelect";
import ModelForm from "../createexamdialog/ModelForm";
import AddQuestionModel from "./addquestion";
import { useDispatch } from "react-redux";
import {
  editExam,
  getUserList,
  deleteExam,
  updateExamTimings,
  addCandidate,
} from "../../../redux/actions/exam";
import history from "../../../utils/createHistory";
import { Typography } from "@material-ui/core";
import MultipleQuestions from "./multiple_questions";
import { Box, Modal } from "@material-ui/core";
// import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";

const ExamDetails = ({ details, setDetails }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([]);
  const [modalSelectedOption, setModalSelectedOption] = useState([]); // State for modal's selection

  const [nameInfo, setNameInfo] = useState({
    examtitle: "",
    examinfo: "",
    startingtime: "",
    endingtime: "",
  });
  const [options, setOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const isInitialLoad = useRef(true);

  useEffect(() => {
    const getData = async () => {
      let arr = await dispatch(getUserList());
      setOptions([...arr]);

      // Only set selectedOption on the first load if no candidates are provided
      if (isInitialLoad.current) {
        if (details && details.candidates) {
          // If there are candidates in details, select those users
          const added = arr.filter((ele) =>
            details.candidates.find((v) => String(v.id) === String(ele._id))
          );
          setSelectedOption([...added]);
        } else {
          // If no candidates are in details, select all users
          setSelectedOption([...arr]);
        }
        isInitialLoad.current = false; // After the first load, prevent resetting
      }
    };
    getData();
    // Set nameInfo based on details
    if (details) {
      setNameInfo({
        examtitle: details.examtitle,
        examinfo: details.examinfo,
        startingtime: details.startingtime,
        endingtime: details.endingtime,
      });
    }
  }, [dispatch, details]); // Adding selectedOption to dependencies to control updates

  const onSave = async () => {
    await dispatch(
      editExam(
        {
          ...details,
          examtitle: nameInfo.examtitle,
          examinfo: nameInfo.examinfo,
          startingtime: nameInfo.startingtime,
          endingtime: nameInfo.endingtime,
          candidates: selectedOption.map((v) => ({
            id: v._id,
          })),
        },
        details._id
      )
    );
  };

  const onUpdateExamTimings = async () => {
    await dispatch(
      updateExamTimings(
        {
          endingtime: nameInfo.endingtime,
        },
        details._id
      )
    );
  };

  const addNewUser = async () => {
    setModalSelectedOption([]);
    setOpenModal(true);
  };
  const handleClose = async () => {
    setOpenModal(false);
  };

  const updateUser = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const exam_id = queryParams.get("exam_id");
    const reteriveUserId = modalSelectedOption?.map((id) => {
      return id._id;
    });
    await dispatch(addCandidate(reteriveUserId, exam_id));
    setOpenModal(false);
    console.log("user Updated successfully", reteriveUserId, exam_id);
  };
  return (
    <Container direction="column" background="white" width="50%" padding="20px">
      <Typography variant="h5">Edit Exam</Typography>
      <Container direction="column" width="100%" style={{ marginTop: "24px" }}>
        <ModelForm details={nameInfo} setDetails={setNameInfo} />
        <Container style={{ marginTop: "10px" }}>
          <MultiSelect
            options={options}
            name="email"
            secondName="name"
            label="Candidates"
            placeholder="Select Candidates"
            onChange={setSelectedOption}
            value={selectedOption}
          />
        </Container>
      </Container>
      <Container direction="row" style={{ gap: "24px", marginTop: "24px" }}>
        {new Date(details.startingtime).getTime() < new Date().getTime() ? (
          <></>
        ) : (
          <Button
            width="100px"
            height="40px"
            background="#D24C4C"
            onClick={async () => {
              if (await dispatch(deleteExam(details._id)))
                history.push("/host");
            }}
          >
            Delete Exam
          </Button>
        )}
        {new Date(details.startingtime).getTime() < new Date().getTime() ? (
          <></>
        ) : (
          <Button width="100px" height="40px" onClick={onSave}>
            Save Exam
          </Button>
        )}
        <Button width="100px" height="40px" onClick={onUpdateExamTimings}>
          Update Exam Timings
        </Button>
        <AddQuestionModel details={details} setDetails={setDetails} />
        <MultipleQuestions />
        <Button width="130px" height="40px" onClick={addNewUser}>
          Add New User
        </Button>
      </Container>
      {openModal ? (
        <Modal
          open={openModal}
          onClose={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "30px",
                marginBottom: "20px ",
                cursor: "pointer",
              }}
            >
              <ClearSharpIcon onClick={handleClose} />
            </Box>
            <MultiSelect
              options={options}
              name="email"
              secondName="name"
              label="Candidates"
              placeholder="Select Candidates"
              onChange={setModalSelectedOption}
              value={modalSelectedOption}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
                style={{
                  cursor: `${
                    modalSelectedOption.length ? "pointer" : "not-allowed"
                  }`,
                }}
                width="130px"
                height="40px"
                onClick={updateUser}
                disabled={modalSelectedOption.length ? false : true}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      ) : (
        <div></div>
      )}
    </Container>
  );
};

export default ExamDetails;
