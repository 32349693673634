import { Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Container, Text } from "../../../styled";

const ResultHead = ({ answer }) => {
  const { currentExam } = useSelector((state) => state.exam);

  const timeConverter = (date = Date()) => {
    var D = new Date(date);
    let dateString = `${D.toLocaleDateString()} ${D.toLocaleTimeString(
      "en-US",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    )}`;
    return dateString;
  };

  const totalExamMark = () => {
    let questions = currentExam.questions || [];
    let totalMark = 0;
    questions.map((question) => {
      totalMark += question.mark;
    });
    return totalMark;
  };

  return (
    <Paper
      elevation={0}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 30px",
      }}
    >
      <Container direction="row" justify="space-between">
        <Text size="18px">
          Exam Name:
          <b> {currentExam.examtitle}</b>
        </Text>
        {answer.exited === "Malpractice" && (
          <Text size="18px" color="red">
            *Malpractice Warning
          </Text>
        )}
        <Text size="18px">
          Candidate Name:{" "}
          <b>
            {answer && answer.candidateid && answer.candidateid.name
              ? answer.candidateid.name
              : ""}
          </b>
        </Text>
      </Container>
      <Text size="15px" lineHeight="0px">
        {currentExam.examinfo}
      </Text>
      <Container direction="row" justify="space-between">
        <Text>
          Start:
          <b> {timeConverter(currentExam.startingtime)}</b>
        </Text>
        <Text>
          End:
          <b> {timeConverter(currentExam.endingtime)}</b>
        </Text>
        <Text
          size="18"
          color={answer.exited === "Malpractice" ? "red" : "black"}
        >
          Marks:{" "}
          <span
            style={{
              fontWeight: "bold",
              color:
                answer.exited === "Malpractice"
                  ? "red"
                  : answer.totalmark < 10
                  ? "red"
                  : answer.totalmark > 10 && answer.totalmark <= 16
                  ? "yellow"
                  : "green",
            }}
          >
            {answer.exited === "Malpractice" ? "0" : answer.totalmark}
          </span>
          /{totalExamMark()}
        </Text>
      </Container>
    </Paper>
  );
};

export default ResultHead;
