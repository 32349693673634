export const addUser = async (data) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("user", JSON.stringify(data));
    setTimeout(() => {
      return resolve();
    }, 100);
  });
};
export const getUser = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return user;
  } else {
    return false;
  }
};
export const removeUser = () => {
  localStorage.removeItem("user");
};

// Function to add or update the keyPressCount in localStorage
export const addKeyPressCount = async (count) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("keyPressCount", JSON.stringify(count));
    setTimeout(() => {
      return resolve(); 
    }, 100);
  });
};

// Function to retrieve the keyPressCount from localStorage
export const getKeyPressCount = () => {
  const count = JSON.parse(localStorage.getItem("keyPressCount")); 
  if (count !== null) {
    return count; 
  } else {
    return 0; 
  }
};

// Function to remove the keyPressCount from localStorage
export const removeKeyPressCount = () => {
  localStorage.removeItem("keyPressCount"); 
};
