import React from "react";
import { Container, Input } from "../../../styled";
import DateAndTimePicker from "../../materialui/DateAndTimePicker";

const ModelForm = ({ details = null, setDetails = null }) => {
  const classes = {
    textareadiv: {
      height: "70px",
      width: "100vw",
      margin: "10px 0px",
    },
    textarea: {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      fontFamily: "Roboto",
      boxSizing: "border-box",
      borderRadius: "4px",
      padding: "8px",
      width: "100%",
      height: "70px",
    },
  };
  const onchange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  return (
    <Container
      direction="column"
      justify="space-between"
      style={{ gap: "12px" }}
    >
      <Container>
        <Input
          height="40px"
          margin="10px 0px"
          placeholder="Exam Info"
          name="examtitle"
          value={details["examtitle"] || ""}
          onChange={onchange}
        />
      </Container>
      <Container>
        <div style={classes.textareadiv}>
          <textarea
            style={classes.textarea}
            placeholder="Exam Info"
            name="examinfo"
            value={details["examinfo"] || ""}
            onChange={onchange}
            required
          />
        </div>
      </Container>
      <Container margin="10px 0px">
        <DateAndTimePicker
          onChange={(date) => {
            setDetails({ ...details, startingtime: date });
          }}
          title="Starting Time"
          defaultValue={String(details["startingtime"])}
        />
      </Container>
      <Container margin="10px 0px">
        <DateAndTimePicker
          onChange={(date) => {
            setDetails({ ...details, endingtime: date });
          }}
          title="Ending Time"
          defaultValue={details["endingtime"]}
        />
      </Container>
    </Container>
  );
};

export default ModelForm;
