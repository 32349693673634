import React from "react";
import HeadFoot from "../components/headerfooter";
import { Paper, Grid } from "@material-ui/core";
import { Container, Text } from "../styled";
import UserAttainedAccordian from "../components/pages/host/UserAttainedAccordian";
import UserUpcomingAccordian from "../components/pages/host/UserUpcomingAccordian";

import "./styles/exam.css";

const Exam = () => {
  return (
    <HeadFoot>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            variant="outlined"
            className="examination-card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItmes: "center",
              width: "98%",
              height: "40px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
          >
            <Text lineHeight="0px" size="20px">
              Upcoming Examination
            </Text>
          </Paper>
          <UserUpcomingAccordian />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            variant="outlined"
            className="examination-card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItmes: "center",
              width: "98%",
              height: "40px",
              cursor: "pointer",
              marginBottom: "5px",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            <Text lineHeight="0px" size="20px">
              Past Examination
            </Text>
          </Paper>
          <UserAttainedAccordian />
        </Grid>
      </Grid>
    </HeadFoot>
  );
};

export default Exam;
