import React, { useEffect ,useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "../../../../styled";
import Header from "./header";
import LeftPanel from "./leftpanel";
import RightPanel from "./rightpanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { ExamContextConsumer } from "../context";
import { getKeyPressCount , addKeyPressCount } from "../../../../utils/localStorage";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Index = () => {
  const { loader } = useSelector((state) => state);
  const [isBlurred, setIsBlurred] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userName, setUserName] = React.useState("");
  const [keyPressCount, setKeyPressCount] = React.useState(0); // State to track keypress count
  const [password, setPassword] = React.useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState(""); // State to store the error message
  const [userNameErrorMessage, setUserNameErrorMessage] = React.useState(""); // State to store the error message
  const { activateEvents } = ExamContextConsumer();
   const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    type: "Warning",
    duration: null,
  });
  const { vertical, horizontal } = state;


  const handlePasswordSubmit = () => {
    let hasError = false;

    // Check if the username is incorrect
    if (userName !== "admin@york.ie") {
      setUserNameErrorMessage("UserName Incorrect! Try again.");
      hasError = true; // Mark that an error occurred
    } else {
      setUserNameErrorMessage(""); // Clear username error if the username is correct
    }

    // Check if the password is incorrect
    if (password !== "admin") {
      setPasswordErrorMessage("Password Incorrect! Try again.");
      hasError = true; // Mark that an error occurred
    } else {
      setPasswordErrorMessage(""); // Clear password error if the password is correct
    }

    // If there are no errors, proceed
    if (!hasError) {
      setIsBlurred(false);
      setIsModalOpen(false);
      setKeyPressCount(0);
      setUserName("");
      setPassword("");
    }
  };
  
const keypressAlert = async () => {
  if (!isBlurred) {
    const currentCount = getKeyPressCount(); // Get the current count
    const newCount = currentCount + 1;

    if (newCount >= 3) {
      setIsModalOpen(true);
      setIsBlurred(true);
      await addKeyPressCount(0); // Reset the count in localStorage
    } else {
      await addKeyPressCount(newCount); // Update the count in localStorage

      // Trigger the Snackbar with a message
      setAlertMessage(`Don't press the key, otherwise your exam will pause {keyPressCount: ${newCount}}`);
      setAlertOpen(true);

      return newCount;
    }

    setUserName("");
    setPassword("");
    setPasswordErrorMessage("");
    setUserNameErrorMessage("");
  }
};
  useEffect(() => {
    activateEvents();

    const keypressHandler = (e) => {
      if (isBlurred) return; // Avoid triggering modal if already blurred
      e.preventDefault();
      keypressAlert(); // Trigger blur and modal on keypress
    };

    const visibilityChangeHandler = () => {
      if (document.hidden && !isBlurred) {
        keypressAlert(); // Trigger blur and modal when visibility changes
      }
    };

    window.addEventListener("keypress", keypressHandler);
    document.addEventListener("visibilitychange", visibilityChangeHandler);

    return () => {
      window.removeEventListener("keypress", keypressHandler);
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, [isBlurred]);

  return (
    <>
      <Container
        height="100%"
        width="100%"
        style={{
          filter: isBlurred ? "blur(5px)" : "none",
          transition: "filter 0.3s ease",
        }}
        direction="column"
        background="#f8f8f8"
      >
        {loader > 0 && (
          <Container
            position="absolute"
            top="0px"
            background="rgb(0, 0, 0, 0.5)"
            justify="center"
            align="center"
            width="100%"
            height="100%"
            zIndex={3}
          >
            <CircularProgress
              style={{ height: "60px", width: "60px", color: "green" }}
            />
          </Container>
        )}
        <Header />
        <Container direction="row" padding="24px">
          <LeftPanel />
          <RightPanel />
        </Container>
      </Container>

      {/* Modal for password input */}
      <Dialog
        open={isModalOpen}
        onClose={() => {}}
        sx={{
          "& .MuiDialog-paper": {
            width: "80%", // Adjust the width to make it more appropriate
            maxWidth: "400px", // Max width of the modal
            margin: "auto", // Center it horizontally
            top: "30%", // Adjust the vertical position to be centered
            transform: "translateY(-30%)", // Fine-tune the centering
          },
        }}
      >
        <DialogContent>
          <TextField
            type="UserName"
            label="Enter Admin UserName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)} // Ensure password updates on input
            fullWidth
            error={!!userNameErrorMessage} // Apply error styling if error message exists
            helperText={userNameErrorMessage} // Show the error message below the input field
          />
          <TextField
            type="password"
            label="Enter Admin Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Ensure password updates on input
            fullWidth
            error={!!passwordErrorMessage} // Apply error styling if error message exists
            helperText={passwordErrorMessage} // Show the error message below the input field
          />
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => {
              setIsModalOpen(false);
              setIsBlurred(false);
            }}
            color="secondary"
          >
            Cancel
          </Button> */}
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
       anchorOrigin={{ vertical, horizontal }} 
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)} 
      >
        <MuiAlert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: "100%" }}>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Index;
