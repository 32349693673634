import React from "react";
import { Container, Text } from "../../../../styled";

const MultipleQuestionsForm = ({ setFile }) => {
  const classes = {
    textareadiv: {
      margin: "10px 0px",
    },
    textarea: {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      boxSizing: "border-box",
      borderRadius: "4px",
      padding: "8px",
      width: "100%",
      height: "70px",
      fontFamily: "Roboto",
    },
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Pass the selected file to the parent component
    } else {
      console.error("No file selected");
    }
  };

  return (
    <Container direction="column" width="500px">
      <form>
        <Container direction="column">
          <label>
            <Text lineHeight="0px" size="18px">
              Few Imp Notes:
            </Text>
            <div>
              <ul>
                <li>
                  Bulk question will remove existing questions of a particular
                  exam
                </li>
                <li>Questions can't be updated once exam starts.</li>
                <li>Only exam host can update questions</li>
                <li>
                  Csv file required specific format for it to process. You can
                  download it from{" "}
                  <a
                    href={`${process.env.REACT_APP_BACKEND_URL}/public/multiple_questions.csv`}
                    _target="_blank"
                  >
                    here
                  </a>{" "}
                </li>
              </ul>
            </div>
          </label>
        </Container>
        <Container direction="column">
          <label>
            <Text lineHeight="0px" size="18px">
              Upload Csv:
            </Text>
          </label>
        </Container>
        <Container>
          <div style={classes.textareadiv}>
            <input
              type="file"
              id="multiple_questions_file"
              name="multiple_questions_file"
              accept="text/csv"
              onChange={handleFileChange}
              required
            />
          </div>
        </Container>
      </form>
    </Container>
  );
};

export default MultipleQuestionsForm;
