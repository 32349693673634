import React, { useState } from "react";

import HeadFoot from "../components/headerfooter";
import { Container, Text, Input, Button } from "../styled";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { signIn } from "../redux/actions/user";
import history from "../utils/createHistory";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "24px",
    minWidth: "400px",
  },
}));

const SignIn = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const { email, password } = details;
  const handelChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (password.length >= 9) {
      if (await dispatch(signIn(details))) {
        setTimeout(() => {
          if (details.email === "admin@email.com") {
            history.push("/host");
          } else {
            history.push("/exam");
          }
        }, 1000);
        setDetails({
          email: "",
          password: "",
        });
      }
    }
  };

  return (
    <HeadFoot>
      <Container justify="center" align="center" height="100%">
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Welcome to York.IE! 👋🏻
          </Typography>
          <Typography
            variant="caption"
            style={{ display: "block", textAlign: "center" }}
          >
            Please sign-in to your account to start the test.
          </Typography>
          <form onSubmit={onSubmit}>
            <Container
              align="center"
              direction="column"
              style={{ marginTop: "24px" }}
            >
              <Input
                margin="10px 0px"
                height="40px"
                type="email"
                value={email}
                width="80%"
                placeholder="Email"
                name="email"
                onChange={handelChange}
                required
              />

              <Input
                margin="10px 0px"
                height="40px"
                type="password"
                minLength={9}
                value={password}
                width="80%"
                placeholder="Password"
                name="password"
                onChange={handelChange}
                required
              />
              <Container width="80%" justify="flex-end">
                <Button
                  height="35px"
                  width="100px"
                  type="submit"
                  onSubmit={onSubmit}
                  style={{ border: "none" }}
                >
                  Sign In
                </Button>
              </Container>

              <Divider flexItem style={{ margin: "16px 0", height: "1px" }} />

              <Typography style={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <Typography
                  component="span"
                  style={{ color: "#47926E", cursor: "pointer" }}
                  onClick={() => history.push("/signup")}
                >
                  SignUp
                </Typography>
              </Typography>
            </Container>
          </form>
        </Paper>
      </Container>
    </HeadFoot>
  );
};

export default SignIn;
