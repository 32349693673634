import { Grid, Paper } from "@material-ui/core";
import React from "react";
import HeadFoot from "../components/headerfooter";

import { Container, Text } from "../styled";

import HostUpcomigAccordian from "../components/pages/host/HostUpcomigAccordian";
import HostPastAccordian from "../components/pages/host/HostPastAccordian";
import CreateExamDialog from "../components/pages/createexamdialog";

import "./styles/exam.css";

const Host = () => {
  return (
    <HeadFoot>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            variant="outlined"
            className="examination-card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItmes: "center",
              width: "98%",
              height: "40px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
          >
            <Text lineHeight="0px" size="20px">
              Upcoming Examination
            </Text>
            <CreateExamDialog />
          </Paper>
          <HostUpcomigAccordian />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            variant="outlined"
            className="examination-card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItmes: "center",
              width: "98%",
              height: "40px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
          >
            <Text lineHeight="0px" size="20px">
              Past Examination
            </Text>
          </Paper>
          <HostPastAccordian />
        </Grid>
      </Grid>
    </HeadFoot>
  );
};

export default Host;
