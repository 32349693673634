import React, { useState, useEffect } from "react";
import { Container, Text, Button, Tab } from "../../styled";
import { getUser } from "../../utils/localStorage";
import { useSelector, useDispatch } from "react-redux";
import history from "../../utils/createHistory";
import IconButtons from "../materialui/IconButtons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { signOut } from "../../redux/actions/user";
import WelcomeName from "../pages/profile";
import Logo from "../../assets/images/logo.jpeg";

const Header = (props) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const storageData =
    localStorage.getItem("user") != null
      ? JSON.parse(localStorage.getItem("user")).user
      : {};
  const user = storageData;
  const check =
    history.location.pathname === "/host" ||
    history.location.pathname === "/exam";
  useEffect(() => {
    if (getUser()) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [user]);
  console.log(user);

  return (
    <Container
      direction="row"
      align="center"
      width="100%"
      justify="space-between"
      height="60px"
      background="transparent"
    >
      <Container
        width="none"
        flex="0.1"
        direction="row"
        align="center"
        padding="10px"
      >
        <img height="30px" src={Logo} alt="YorkIE" />
      </Container>

      <Container
        direction="row"
        justify="center"
        align="flex-end"
        style={{ gap: "16px", maxWidth: "max-content" }}
      >
        {isLogin && check && (
          <>
            {user?.name === "admin" ? (
              <Tab
                active={history.location.pathname === "/host"}
                onClick={() => history.push("/host")}
                background="transparent"
              >
                Host
              </Tab>
            ) : null}

            <Tab
              onClick={() => history.push("/exam")}
              active={history.location.pathname === "/exam"}
              background="transparent"
            >
              Exam
            </Tab>
          </>
        )}
      </Container>

      <Container
        direction="row"
        justify="flex-end"
        align="center"
        flex="0.2"
        margin="0px 10px 0px 0px"
        style={{ gap: "20px" }}
      >
        {isLogin ? (
          <>
            <WelcomeName />
            <IconButtons
              onClick={() => {
                dispatch(signOut());
              }}
              tooltipTitle="Log Out"
            >
              <ExitToAppIcon style={{ color: "#D24C4C" }} />
            </IconButtons>
          </>
        ) : (
          <></>
        )}
      </Container>
    </Container>
  );
};

export default Header;
