import Styled from "styled-components";

export const Container = Styled.div`
position:${(props) => props.position || "relative"};
top:${(props) => props.top || "none"};
left:${(props) => props.left || "none"};
right:${(props) => props.right || "none"};
bottom:${(props) => props.bottom || "none"};
display:${(props) => props.display || "flex"};
flex:${(props) => props.flex || "none"};
align-items: ${(props) => props.align || "none"};
justify-content: ${(props) => props.justify || "none"};
flex-direction:${(props) => props.direction || "none"};
height:${(props) => props.height || "none"};
width:${(props) => props.width || "100%"};
background-color:${(props) => props.background || "none"};
font-family:${(props) => props.family || "Roboto"};
border:${(props) => props.border || "none"};
border-top:${(props) => props.borderTop || "none"};
border-bottom:${(props) => props.borderBottom || "none"};
border-left:${(props) => props.borderLeft || "none"};
border-right:${(props) => props.borderRight || "none"};
padding:${(props) => props.padding || "none"};
margin:${(props) => props.margin || "none"};
overflow:${(props) => props.overflow || "none"};
overflow-x:${(props) => props.overflowX || "none"};
overflow-y:${(props) => props.overflowY || "none"};
z-index:${(props) => props.zIndex || "unset"};
text-align:${(props) => props.textAlign || "none"};
`;

export const Text = Styled.p`
postion:${(props) => props.postion || "relative"};
top:${(props) => props.top || "none"};
left:${(props) => props.left || "none"};
right:${(props) => props.right || "none"};
bottom:${(props) => props.bottom || "none"};
color:${(props) => props.color || "black"};
text-align:${(props) => props.align || "none"};
vertical-align:${(props) => props.verticalAlign || "none"};
text-transform:${(props) => props.transform || "none"};
font-family:${(props) => props.family || "Roboto"};
font-size:${(props) => props.size || "14px"};
font-weight:${(props) => props.weight || "none"};
padding:${(props) => props.padding || "none"};
margin:${(props) => props.margin || "none"};
background-color:${(props) => props.background || "none"};
line-height:${(props) => props.lineHeight || "15px"};
width:${(props) => props.width || "none"};
`;

export const Input = Styled.input`
width: ${(props) => props.width || "100%"};
height: ${(props) => props.height || "30px"};
margin: ${(props) => props.margin || "none"};
padding: ${(props) => props.padding || "0px 5px"};

border: 1px solid rgba(0, 0, 0, 0.12);
box-sizing: border-box;
border-radius: 4px;
`;

export const Button = Styled.button`
position:${(props) => props.position || "relative"};
top:${(props) => props.top || "none"};
left:${(props) => props.left || "none"};
right:${(props) => props.right || "none"};
bottom:${(props) => props.bottom || "none"};
width: ${(props) => props.width || "100%"};
height: ${(props) => props.height || "40px"};
font-family: Roboto;
font-style: normal;
font-weight: ${(props) => props.weight || "none"};
font-size: ${(props) => props.size || "14px"};
padding:${(props) => props.padding || "none"};
margin:${(props) => props.margin || "none"};
background: ${(props) => props.disabled ? "#A9A9A9"  :  props.background || "#47926E"};
border-radius:${(props) => props.radius || "5px"}; ;
color:${(props) => props.color || "#FFFFFF"} ;
cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
border: 0
`;

export const Tab = Styled.div`
width: max-content;
display:flex;
align-items:center;
justify-content:center;
font-family: Roboto;
font-weight:${(props) => (props.active ? 600 : 400)};
color:${(props) => (props.active ? "#00b48a" : "#2D2D2D")};
cursor:pointer;
background: white;
border-bottom: 2px solid;
border-color:${(props) => (props.active ? "#00b48a" : "transparent")}

`;
