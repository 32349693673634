import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container } from "../../styled";
import Background from "./Background";
const index = ({ children }) => {
  return (
    <div>
      <Background />
      <div>
        <Header />
        <Container
          direction="column"
          height="90vh"
          // background="#f8f8f8"
          style={{
            maxWidth: "100%",
          }}
        >
          <div style={{ padding: "24px" }}>{children}</div>
        </Container>
      </div>
      {/* <Footer /> */}
    </div>
  );
};
export default index;
