import React, { useState } from "react";
import { addBulkQuestions } from "../../../../redux/actions/exam";
import { Button } from "../../../../styled";
import DialogBox from "../../../materialui/DialogBox";
import MultipleQuestionsForm from "./MultipleQuestionsForm";
import { useDispatch } from "react-redux";

const MultipleQuestions = ({ details, setDetails }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [file, setFile] = React.useState(null);

  const [question, setQuestion] = useState({
    title: "",
    options: [
      {
        option: "",
        isanswer: true,
      },
      {
        option: "",
        isanswer: false,
      },
      {
        option: "",
        isanswer: false,
      },
      {
        option: "",
        isanswer: false,
      },
    ],
    mark: 0,
  });

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const exam_id = queryParams.get("exam_id");

    const formData = new FormData();
    formData.append("multiple_questions_file", file);

    try {
      await dispatch(addBulkQuestions(formData, exam_id));
      setOpen(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <DialogBox
      open={open}
      setOpen={setOpen}
      title="Upload csv file."
      buttons={[{ onClick: (e) => handleFileUpload(e), name: "Submit" }]}
      MainButton={(props) => (
        <Button
          width="100px"
          height="40px"
          background="#5a23c8"
          onClick={props.onClick}
        >
          Multiple Questions
        </Button>
      )}
    >
      <MultipleQuestionsForm setFile={setFile} />
    </DialogBox>
  );
};

export default MultipleQuestions;
